import { BaseModal } from "./BaseModal";
import { shareStatus } from '../../lib/share'
import { GameStats } from '../../lib/localStorage'
import {
  SHARE_TEXT,
} from '../../constants/strings'
import { verse, verseText, verseUrl, submissionText, solution } from "../../lib/words";

type Props = {
  isOpen: boolean
  handleClose: () => void
  guesses: string[]
  gameStats: GameStats
  isGameLost: boolean
  isGameWon: boolean
  handleShareToClipboard: () => void
  isHardMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
  numberOfGuessesMade: number
}

export const ScriptureModal = ({
  isOpen,
  handleClose,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShareToClipboard,
  isHardMode,
  isDarkMode,
  isHighContrastMode,
  numberOfGuessesMade,
}: Props) => {
  return (
    <BaseModal title={"Solution: " + solution} isOpen={isOpen} handleClose={handleClose}>
      <div className="mt-5 sm:mt-6 dark:text-white">
        {" "}
        <a
          href={verseUrl}
          target="_blank"
          className="underline font-bold text-blue-700"
          rel="noreferrer"
        >
          {verse}
        </a>{" "}
        - "{verseText}"
      </div>
      <div className="mt-5 sm:mt-6 dark:text-white">
        <b>{submissionText}</b>
      </div>
      <div className="mt-5 sm:mt-6 columns-2 dark:text-white">
      <div>
          <button
            type="button"
            className="mt-2 w-full rounded-md border border-indigo-600 shadow-sm px-4 py-2 bg-transparent text-base font-medium text-indigo-600 hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
            onClick={() => {handleClose()}}
          >
            See Stats
          </button>
        </div>
        <div>
        <button
            type="button"
            className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
            onClick={() => {
              shareStatus(
                guesses,
                isGameLost,
                isHardMode,
                isDarkMode,
                isHighContrastMode,
                handleShareToClipboard
              )
            }}
          >
            {SHARE_TEXT}
          </button>
        </div>
      </div>
    </BaseModal>
  );
};