export const WORDS: any = [
  {
    word: "angel",
    verse: "Matthew 28:5-6",
    verseUrl: "https://www.bible.com/bible/1/MAT.28.5-6.KJV",
    verseText: "But the angel answered and said to the women, “Do not be afraid, for I know that you seek Jesus who was crucified. He is not here; for He is risen, as He said. Come, see the place where the Lord lay.",
    hint: "Matthew 28",
    hintUrl: "https://www.bible.com/bible/1/MAT.28.KJV",
  }, // April 17
  {
    word: "fruit",
    verse: "John 15:5",
    verseUrl: "https://www.bible.com/bible/1/JHN.15.5.KJV",
    verseText: "I am the vine, ye are the branches: He that abideth in me, and I in him, the same bringeth forth much fruit: for without me ye can do nothing.",
    hint: "John 15",
    hintUrl: "https://www.bible.com/bible/1/JHN.15.KJV",
    submissionText: "Thank you Chad Adler for submitting this verse!"
  }, // April 18
  {
    word: "wiles",
    verse: "Ephesians 6:11",
    verseUrl: "https://www.bible.com/bible/1/EPH.6.11.KJV",
    verseText: "Put on the whole armour of God, that ye may be able to stand against the wiles of the devil.",
    hint: "Ephesians 6",
    hintUrl: "https://www.bible.com/bible/1/EPH.6.KJV",
  }, // April 19
  {
    word: "depth",
    verse: "Romans 8:39",
    verseUrl: "https://www.bible.com/bible/1/ROM.8.39.KJV",
    verseText: "Nor height, nor depth, nor any other creature, shall be able to separate us from the love of God, which is in Christ Jesus our Lord.",
    hint: "Romans 8",
    hintUrl: "https://www.bible.com/bible/1/ROM.8.KJV",
  }, // April 20
  {
    word: "steal",
    verse: "Matthew 6:20",
    verseUrl: "https://www.bible.com/bible/1/MAT.6.20.KJV",
    verseText: "But lay up for yourselves treasures in heaven, where neither moth nor rust doth corrupt, and where thieves do not break through nor steal",
    hint: "Matthew 6",
    hintUrl: "https://www.bible.com/bible/1/MAT.6.KJV",
  }, // April 21
  {
    word: "voice",
    verse: "Revelations 3:20",
    verseUrl: "https://www.bible.com/bible/1/REV.3.20.KJV",
    verseText: "Behold, I stand at the door, and knock: if any man hear my voice, and open the door, I will come in to him, and will sup with him, and he with me.",
    hint: "Revelations 3",
    hintUrl: "https://www.bible.com/bible/1/REV.3.KJV",
  }, // April 22
  {
    word: "agony",
    verse: "Luke 22:44",
    verseUrl: "https://www.bible.com/bible/1/LUK.22.44.KJV",
    verseText: "And being in an agony he prayed more earnestly: and his sweat was as it were great drops of blood falling down to the ground.",
    hint: "Luke 22",
    hintUrl: "https://www.bible.com/bible/1/LUK.22.KJV",
  }, // April 23
  {
    word: "exalt",
    verse: "1 Peter 5:6",
    verseUrl: "https://www.bible.com/bible/1/1PE.5.6.KJV",
    verseText: "Humble yourselves therefore under the mighty hand of God, that he may exalt you in due time",
    hint: "1 Peter 5",
    hintUrl: "https://www.bible.com/bible/1/1PE.5.KJV",
  }, // April 24
    {
    word: "giver",
    verse: "2 Corinthians 9:7",
    verseUrl: "https://www.bible.com/bible/59/2CO.9.7.ESV",
    verseText: "Each one must give as he has decided in his heart, not reluctantly or under compulsion, for God loves a cheerful giver.",
    hint: "2 Corinthians 9",
    hintUrl: "https://www.bible.com/bible/59/2CO.9.ESV",
  }, // April 25
    {
    word: "godly",
    verse: "2 Timothy 3:12",
    verseUrl: "https://www.bible.com/bible/59/2ti.3.12.ESV",
    verseText: "Indeed, all who desire to live a godly life in Christ Jesus will be persecuted.",
    hint: "2 Timothy 3",
    hintUrl: "https://www.bible.com/bible/59/2ti.3.ESV",
  }, // April 26
    {
    word: "fully",
    verse: "1 Peter 1:13",
    verseUrl: "https://www.bible.com/bible/59/1pe.1.13.ESV",
    verseText: "Therefore, preparing your minds for action, and being sober-minded, set your hope fully on the grace that will be brought to you at the revelation of Jesus Christ.",
    hint: "1 Peter 1",
    hintUrl: "https://www.bible.com/bible/59/1pe.1.ESV",
  }, // April 27
    {
    word: "Happy",
    verse: "Genesis 30:13",
    verseUrl: "https://www.bible.com/bible/59/gen.30.13.ESV",
    verseText: "And Leah said, “Happy am I! For women have called me happy.” So she called his name Asher.",
    hint: "Genesis 30",
    hintUrl: "https://www.bible.com/bible/59/gen.30.ESV",
  }, // April 28
    {
    word: "hills",
    verse: "Psalm 121:1",
    verseUrl: "https://www.bible.com/bible/59/psa.121.1.ESV",
    verseText: "I lift up my eyes to the hills. From where does my help come?",
    hint: "Psalm 121",
    hintUrl: "https://www.bible.com/bible/59/psa.121.ESV",
  }, // April 29
    {
    word: "learn",
    verse: "Matthew 9:13",
    verseUrl: "https://www.bible.com/bible/59/mat.9.13.ESV",
    verseText: "Go and learn what this means: ‘I desire mercy, and not sacrifice.’ For I came not to call the righteous, but sinners.",
    hint: "Matthew 9",
    hintUrl: "https://www.bible.com/bible/59/mat.9.ESV",
  }, // April 30
    {
    word: "knees",
    verse: "Daniel 6:10",
    verseUrl: "https://www.bible.com/bible/59/dan.6.10.ESV",
    verseText: "When Daniel knew that the document had been signed, he went to his house where he had windows in his upper chamber open toward Jerusalem. He got down on his knees three times a day and prayed and gave thanks before his God, as he had done previously.",
    hint: "Daniel 6",
    hintUrl: "https://www.bible.com/bible/59/dan.6.ESV",
  }, // May 1
  {
    word: "aloud",
    verse: "Psalm 59:16",
    verseUrl: "https://www.bible.com/bible/59/psa.59.16.ESV",
    verseText: "But I will sing of your strength; I will sing aloud of your steadfast love in the morning. For you have been to me a fortress and a refuge in the day of my distress.",
    hint: "Psalm 59",
    hintUrl: "https://www.bible.com/bible/59/PSA.59.ESV",
  }, // May 2
  {
    word: "above",
    verse: "Colossians 3:1",
    verseUrl: "https://www.bible.com/bible/59/col.3.1.ESV",
    verseText: "If then you have been raised with Christ, seek the things that are above, where Christ is, seated at the right hand of God.",
    hint: "Colossians 3",
    hintUrl: "https://www.bible.com/bible/59/COL.3.ESV",
  }, // May 3
  {
    word: "baker",
    verse: "Genesis 40:5",
    verseUrl: "https://www.bible.com/bible/59/gen.40.5.ESV",
    verseText: "And one night they both dreamed—the cupbearer and the baker of the king of Egypt, who were confined in the prison—each his own dream, and each dream with its own interpretation.",
    hint: "Genesis 40",
    hintUrl: "https://www.bible.com/bible/59/GEN.40.ESV",
  }, // May 4
  {
    word: "loved",
    verse: "John 3:16",
    verseUrl: "https://www.bible.com/bible/59/jhn.3.16.ESV",
    verseText: "For God so loved the world, that he gave his only Son, that whoever believes in him should not perish but have eternal life.",
    hint: "John 3",
    hintUrl: "https://www.bible.com/bible/59/JHN.3.ESV",
  }, // May 5
  {
    word: "lands",
    verse: "Matthew 19:29",
    verseUrl: "https://www.bible.com/bible/59/mat.19.29.ESV",
    verseText: "And everyone who has left houses or brothers or sisters or father or mother or children or lands, for my name’s sake, will receive a hundredfold and will inherit eternal life.",
    hint: "Matthew 19",
    hintUrl: "https://www.bible.com/bible/59/MAT.19.ESV",
  }, // May 6
  {
    word: "laden",
    verse: "Matthew 11:28",
    verseUrl: "https://www.bible.com/bible/59/mat.11.28.ESV",
    verseText: "Come to me, all who labor and are heavy laden, and I will give you rest.",
    hint: "Matthew 11",
    hintUrl: "https://www.bible.com/bible/59/MAT.11.ESV",
  }, // May 5
  {
    word: "Jesus",
    verse: "Matthew 1:21",
    verseUrl: "https://www.bible.com/bible/59/mat.1.21.ESV",
    verseText: "She will bear a son, and you shall call his name Jesus, for he will save his people from their sins.",
    hint: "Matthew 1",
    hintUrl: "https://www.bible.com/bible/59/MAT.1.ESV",
  }, // May 8
  {
    word: "honey",
    verse: "Matthew 3:4",
    verseUrl: "https://www.bible.com/bible/59/mat.3.4.ESV",
    verseText: "Now John wore a garment of camel’s hair and a leather belt around his waist, and his food was locusts and wild honey.",
    hint: "Matthew 3",
    hintUrl: "https://www.bible.com/bible/59/MAT.3.ESV",
  }, // May 9
  {
    word: "guest",
    verse: "Luke 19:7",
    verseUrl: "https://www.bible.com/bible/59/luk.19.7.ESV",
    verseText: "And when they saw it, they all grumbled, “He has gone in to be the guest of a man who is a sinner.”",
    hint: "Luke 19",
    hintUrl: "https://www.bible.com/bible/59/LUK.19.ESV",
  }, // May 10
  {
    word: "touch",
    hint: "Matthew 9",
    verse: "Matthew 9:21",
    hintUrl: "https://www.bible.com/bible/59/mat.9.ESV",
    verseUrl: "https://www.bible.com/bible/59/mat.9.21.ESV",
    verseText: "For she said to herself, If I only touch his garment, I will be made well.",
    submissionText: "Thanks to Chuck Schlie for submitting this word!"
  }, // May 11
  {
    word: "whole",
    hint: "1 Thessalonians 5",
    verse: "1 Thessalonians 5:23",
    hintUrl: "https://www.bible.com/bible/59/1TH.5.ESV",
    verseUrl: "https://www.bible.com/bible/59/1TH.5.23.ESV",
    verseText: "Now may the God of peace himself sanctify you completely, and may your whole spirit and soul and body be kept blameless at the coming of our Lord Jesus Christ.",
    submissionText: "Thanks to Nate Platto for submitting this word!"
  }, // May 12
  {
    word: "blind",
    hint: "John 9",
    verse: "John 9:25",
    hintUrl: "https://www.bible.com/bible/59/JHN.9.ESV",
    verseUrl: "https://www.bible.com/bible/59/JHN.9.25.ESV",
    verseText: "He answered, Whether he is a sinner I do not know. One thing I do know, that though I was blind, now I see.",
    submissionText: ""
  }, // May 13
  {
    word: "mourn",
    hint: "Matthew 5",
    verse: "Matthew 5:4",
    hintUrl: "https://www.bible.com/bible/59/MAT.5.ESV",
    verseUrl: "https://www.bible.com/bible/59/MAT.5.4.ESV",
    verseText: "Blessed are those who mourn, for they shall be comforted.",
    submissionText: "Thanks to Kylie M for submitting this word!"
  }, // May 14
  {
    word: "reign",
    hint: "Exodus 15",
    verse: "Exodus 15:18",
    hintUrl: "https://www.bible.com/bible/59/EXO.15.ESV",
    verseUrl: "https://www.bible.com/bible/59/EXO.15.18.ESV",
    verseText: "The LORD will reign forever and ever.",
    submissionText: ""
  }, // May 15
  {
    word: "moses",
    hint: "John 3",
    verse: "John 3:14",
    hintUrl: "https://www.bible.com/bible/59/JHN.3.ESV",
    verseUrl: "https://www.bible.com/bible/59/JHN.3.14.ESV",
    verseText: "And as Moses lifted up the serpent in the wilderness, so must the Son of Man be lifted up",
    submissionText: ""
  }, // May 16
  {
    word: "alpha",
    hint: "Revelation 22",
    verse: "Revelation 22:13",
    hintUrl: "https://www.bible.com/bible/59/REV.22.ESV",
    verseUrl: "https://www.bible.com/bible/59/REV.22.13.ESV",
    verseText: "I am the Alpha and the Omega, the first and the last, the beginning and the end.",
    submissionText: ""
  }, // May 17
  {
    word: "peter",
    hint: "Acts 3",
    verse: "Acts 3:6",
    hintUrl: "https://www.bible.com/bible/59/ACT.3.ESV",
    verseUrl: "https://www.bible.com/bible/59/ACT.3.6.ESV",
    verseText: "But Peter said, I have no silver and gold, but what I do have I give to you. In the name of Jesus Christ of Nazareth, rise up and walk!",
    submissionText: "Thanks to Kim Ragland for submitting this word!"
  }, // May 18
  {
    word: "right",
    hint: "1 Peter 3",
    verse: "1 Peter 3:22",
    hintUrl: "https://www.bible.com/bible/59/1PE.3.ESV",
    verseUrl: "https://www.bible.com/bible/59/1PE.3.22.ESV",
    verseText: "Who has gone into heaven and is at the right hand of God, with angels, authorities, and powers having been subjected to him.",
    submissionText: ""
  }, // May 19
  {
    word: "bless",
    hint: "Romans 12",
    verse: "Romans 12:14",
    hintUrl: "https://www.bible.com/bible/59/ROM.12.ESV",
    verseUrl: "https://www.bible.com/bible/59/ROM.12.14.ESV",
    verseText: "Bless those who persecute you; bless and do not curse them.",
    submissionText: ""
  }, // May 20
  {
    word: "laden",
    hint: "Matthew 11",
    verse: "Matthew 11:28",
    hintUrl: "https://www.bible.com/bible/59/MAT.11.ESV",
    verseUrl: "https://www.bible.com/bible/59/MAT.11.28.ESV",
    verseText: "Come to me, all who labor and are heavy laden, and I will give you rest.",
    submissionText: "Thanks to Lesley Squire for submitting this word!"
  }, // May 21
  {
    word: "voice",
    hint: "Exodus 19",
    verse: "Exodus 19:5",
    hintUrl: "https://www.bible.com/bible/59/EXO.19.ESV",
    verseUrl: "https://www.bible.com/bible/59/EXO.19.5.ESV",
    verseText: "Now therefore, if you will indeed obey my voice and keep my covenant, you shall be my treasured possession among all peoples, for all the earth is mine",
    submissionText: "Thanks to Blytdina for submitting this word!"
  }, // May 22
  {
    word: "dream",
    hint: "Acts 2",
    verse: "Acts 2:17",
    hintUrl: "https://www.bible.com/bible/59/ACT.2.ESV",
    verseUrl: "https://www.bible.com/bible/59/ACT.2.17.ESV",
    verseText: "And in the last days it shall be, God declares, that I will pour out my Spirit on all flesh, and your sons and your daughters shall prophesy, and your young men shall see visions, and your old men shall dream dreams",
    submissionText: ""
  }, // May 23
  {
    word: "sheep",
    hint: "Matthew 18",
    verse: "Matthew 18:12",
    hintUrl: "https://www.bible.com/bible/59/MAT.18.ESV",
    verseUrl: "https://www.bible.com/bible/59/MAT.18.12.ESV",
    verseText: "What do you think? If a man has a hundred sheep, and one of them has gone astray, does he not leave the ninety-nine on the mountains and go in search of the one that went astray?",
    submissionText: ""
  }, // May 24
  {
    word: "power",
    hint: "2 Timothy 1",
    verse: "2 Timothy 1:7",
    hintUrl: "https://www.bible.com/bible/59/2TI.1.ESV",
    verseUrl: "https://www.bible.com/bible/59/2TI.1.7.ESV",
    verseText: "For God gave us a spirit not of fear but of power and love and self-control.",
    submissionText: "Thanks to Leyton Curran for submitting this word!"
  }, // May 25
  {
    word: "begin",
    hint: "Luke 21",
    verse: "Luke 21:28",
    hintUrl: "https://www.bible.com/bible/59/LUK.21.ESV",
    verseUrl: "https://www.bible.com/bible/59/LUK.21.28.ESV",
    verseText: "Now when these things begin to take place, straighten up and raise your heads, because your redemption is drawing near.",
    submissionText: ""
  }, // May 26
  {
    word: "birds",
    hint: "Matthew 6",
    verse: "Matthew 6:26",
    hintUrl: "https://www.bible.com/bible/59/MAT.6.ESV",
    verseUrl: "https://www.bible.com/bible/59/MAT.6.26.ESV",
    verseText: "Look at the birds of the air: they neither sow nor reap nor gather into barns, and yet your heavenly Father feeds them. Are you not of more value than they?",
    submissionText: ""
  }, // May 27
  {
    word: "doubt",
    hint: "Matthew 14",
    verse: "Matthew 14:31",
    hintUrl: "https://www.bible.com/bible/59/MAT.14.ESV",
    verseUrl: "https://www.bible.com/bible/59/MAT.14.31.ESV",
    verseText: "Jesus immediately reached out his hand and took hold of him, saying to him, O you of little faith, why did you doubt?",
    submissionText: ""
  }, // May 28
  {
    word: "fight",
    hint: "2 Timothy 4",
    verse: "2 Timothy 4:7",
    hintUrl: "https://www.bible.com/bible/59/2TI.4.ESV",
    verseUrl: "https://www.bible.com/bible/59/2TI.4.7.ESV",
    verseText: "I have fought the good fight, I have finished the race, I have kept the faith.",
    submissionText: ""
  }, // May 29
  {
    word: "glory",
    hint: "Romans 11",
    verse: "Romans 11:36",
    hintUrl: "https://www.bible.com/bible/59/ROM.11.ESV",
    verseUrl: "https://www.bible.com/bible/59/rom.11.36.ESV",
    verseText: "For from him and through him and to him are all things. To him be glory forever. Amen.",
    submissionText: "Thanks to Nate Platto for submitting this word!"
  }, // May 30
  {
    word: "flock",
    hint: "Psalm 77",
    verse: "Psalm 77:20",
    hintUrl: "https://www.bible.com/bible/59/PSA.77.ESV",
    verseUrl: "https://www.bible.com/bible/59/psa.77.20.ESV",
    verseText: "You led your people like a flock by the hand of Moses and Aaron.",
    submissionText: "Thanks to Chris Ehrhart for submitting this word!"
  }, // May 31
  {
    word: "bless",
    hint: "Numbers 6",
    verse: "Numbers 6:24",
    hintUrl: "https://www.bible.com/bible/59/NUM.6.ESV",
    verseUrl: "https://www.bible.com/bible/59/num.6.24.ESV",
    verseText: "The LORD bless you and keep you",
    submissionText: "Thanks to Tyler Jackson for submitting this word!"
  }, // June 1
  {
    word: "pluck",
    hint: "Matthew 12",
    verse: "Matthew 12:1",
    hintUrl: "https://www.bible.com/bible/59/MAT.12.ESV",
    verseUrl: "https://www.bible.com/bible/59/mat.12.1.ESV",
    verseText: "At that time Jesus went through the grainfields on the Sabbath. His disciples were hungry, and they began to pluck heads of grain and to eat.",
    submissionText: "Thanks to Sheeba Nencil for submitting this word!"
  }, // June 2
  {
    word: "wages",
    hint: "Romans 6",
    verse: "Romans 6:23",
    hintUrl: "https://www.bible.com/bible/59/ROM.6.ESV",
    verseUrl: "https://www.bible.com/bible/59/rom.6.23.ESV",
    verseText: "For the wages of sin is death, but the free gift of God is eternal life in Christ Jesus our Lord.",
    submissionText: ""
  }, // June 3
  {
    word: "stone",
    hint: "Luke 24",
    verse: "Luke 24:2",
    hintUrl: "https://www.bible.com/bible/59/LUK.24.ESV",
    verseUrl: "https://www.bible.com/bible/59/luk.24.2.ESV",
    verseText: "And they found the stone rolled away from the tomb",
    submissionText: "Thanks to FAS Middle School for submitting this word!"
  }, // June 4
  {
    word: "leave",
    hint: "Matthew 18",
    verse: "Matthew 18:12",
    hintUrl: "https://www.bible.com/bible/59/MAT.18.ESV",
    verseUrl: "https://www.bible.com/bible/59/mat.18.12.ESV",
    verseText: "What do you think? If a man has a hundred sheep, and one of them has gone astray, does he not leave the ninety-nine on the mountains and go in search of the one that went astray?",
    submissionText: ""
  }, // June 5
  {
    word: "untie",
    hint: "John 1",
    verse: "John 1:27",
    hintUrl: "https://www.bible.com/bible/59/JHN.1.ESV",
    verseUrl: "https://www.bible.com/bible/59/jhn.1.27.ESV",
    verseText: "“I baptize with water, but among you stands one you do not know, even he who comes after me, the strap of whose sandal I am not worthy to untie.”",
    submissionText: ""
  }, // June 6
  {
    word: "guard",
    hint: "Psalm 91",
    verse: "Psalm 91:11",
    hintUrl: "https://www.bible.com/bible/59/psa.91",
    verseUrl: "https://www.bible.com/bible/59/psa.91.11",
    verseText: "For he will command his angels concerning you to guard you in all your ways.",
    submissionText: ""
  }, // June 7
  {
    word: "saves",
    hint: "Psalm 34",
    verse: "Psalm 34:18",
    hintUrl: "https://www.bible.com/bible/59/psa.34",
    verseUrl: "https://www.bible.com/bible/59/psa.34.18",
    verseText: "The LORD is near to the brokenhearted and saves the crushed in spirit.",
    submissionText: ""
  }, // June 8
  {
    word: "faint",
    hint: "Proverbs 24",
    verse: "Proverbs 24:10",
    hintUrl: "https://www.bible.com/bible/59/pro.24",
    verseUrl: "https://www.bible.com/bible/59/pro.24.10",
    verseText: "If you faint in the day of adversity, your strength is small.",
    submissionText: ""
  }, // June 9
  {
    word: "lowly",
    hint: "Matthew 11",
    verse: "Matthew 11:29",
    hintUrl: "https://www.bible.com/bible/59/mat.11",
    verseUrl: "https://www.bible.com/bible/59/mat.11.29",
    verseText: "Take my yoke upon you, and learn from me, for I am gentle and lowly in heart, and you will find rest for your souls.",
    submissionText: ""
  }, // June 10
  {
    word: "wrong",
    hint: "Job 1",
    verse: "Job 1:22",
    hintUrl: "https://www.bible.com/bible/59/job.1",
    verseUrl: "https://www.bible.com/bible/59/job.1.22",
    verseText: "In all this Job did not sin or charge God with wrong.",
    submissionText: ""
  }, // June 11
  {
    word: "utter",
    hint: "Psalm 106",
    verse: "Psalm 106:2",
    hintUrl: "https://www.bible.com/bible/59/psa.106",
    verseUrl: "https://www.bible.com/bible/59/psa.106.2",
    verseText: "Who can utter the mighty deeds of the LORD, or declare all his praise?",
    submissionText: ""
  }, // June 12
  {
    word: "short",
    hint: "Romans 3",
    verse: "Romans 3:23",
    hintUrl: "https://www.bible.com/bible/59/rom.3",
    verseUrl: "https://www.bible.com/bible/59/rom.3.23",
    verseText: "For all have sinned and fall short of the glory of God.",
    submissionText: ""
  }, // June 13
  {
    word: "renew",
    hint: "Isaiah 40",
    verse: "Isaiah 40:31",
    hintUrl: "https://www.bible.com/bible/59/isa.40",
    verseUrl: "https://www.bible.com/bible/59/isa.40.31",
    verseText: "But they who wait for the LORD shall renew their strength; they shall mount up with wings like eagles; they shall run and not be weary; they shall walk and not faint.",
    submissionText: ""
  }, // June 14
  { // June 15
    "word": "asked",
    "hint": "1 Kings 3",
    "verse": "1 Kings 3:11-12",
    "hintUrl": "https://www.bible.com/bible/59/1KI.3",
    "verseUrl": "https://www.bible.com/bible/59/1KI.3.11-12",
    "verseText": "And God said to him, “Because you have asked this, and have not asked for yourself long life or riches or the life of your enemies, but have asked for yourself understanding to discern what is right, behold, I now do according to your word. Behold, I give you a wise and discerning mind, so that none like you has been before you and none like you shall arise after you.",
    "submissionText": ""
  },
  { // June 16
    "word": "holds",
    "hint": "Proverbs 29",
    "verse": "Proverbs 29:11",
    "hintUrl": "https://www.bible.com/bible/59/PRO.29",
    "verseUrl": "https://www.bible.com/bible/59/PRO.29.11",
    "verseText": "A fool gives full vent to his spirit, but a wise man quietly holds it back.",
    "submissionText": ""
  },
  { // June 17
    "word": "whole",
    "hint": "Mark 8",
    "verse": "Mark 8:36",
    "hintUrl": "https://www.bible.com/bible/59/MRK.8",
    "verseUrl": "https://www.bible.com/bible/59/MRK.8.36",
    "verseText": "For what does it profit a man to gain the whole world and forfeit his soul?",
    "submissionText": ""
  },
  { // June 18
    "word": "folly",
    "hint": "Proverbs 26",
    "verse": "Proverbs 26:4-5",
    "hintUrl": "https://www.bible.com/bible/59/PRO.26",
    "verseUrl": "https://www.bible.com/bible/59/PRO.26.4-5",
    "verseText": "Answer not a fool according to his folly, lest you be like him yourself. Answer a fool according to his folly, lest he be wise in his own eyes.",
    "submissionText": ""
  },
  { // June 19
    "word": "cloud",
    "hint": "Hebrews 12",
    "verse": "Hebrews 12:1",
    "hintUrl": "https://www.bible.com/bible/59/HEB.12",
    "verseUrl": "https://www.bible.com/bible/59/HEB.12.1",
    "verseText": "Therefore, since we are surrounded by so great a cloud of witnesses, let us also lay aside every weight, and sin which clings so closely, and let us run with endurance the race that is set before us,",
    "submissionText": ""
  },
  { // June 20
    "word": "lowly",
    "hint": "Matthew 11",
    "verse": "Matthew 11:29",
    "hintUrl": "https://www.bible.com/bible/59/MAT.11",
    "verseUrl": "https://www.bible.com/bible/59/MAT.11.29",
    "verseText": "Take my yoke upon you, and learn from me, for I am gentle and lowly in heart, and you will find rest for your souls.",
    "submissionText": ""
  },
  { // June 21
    "word": "earth",
    "hint": "Genesis 1",
    "verse": "Genesis 1:1",
    "hintUrl": "https://www.bible.com/bible/59/GEN.1",
    "verseUrl": "https://www.bible.com/bible/59/GEN.1.1",
    "verseText": "In the beginning, God created the heavens and the earth.",
    "submissionText": ""
  },
  { // June 22
    "word": "carry",
    "hint": "Isaiah 46",
    "verse": "Isaiah 46:4",
    "hintUrl": "https://www.bible.com/bible/59/ISA.46",
    "verseUrl": "https://www.bible.com/bible/59/ISA.46.4",
    "verseText": "even to your old age I am he, and to gray hairs I will carry you. I have made, and I will bear; I will carry and will save.",
    "submissionText": ""
  },
  { // June 23
    "word": "laban",
    "hint": "Genesis 28",
    "verse": "Genesis 28:5",
    "hintUrl": "https://www.bible.com/bible/59/GEN.28",
    "verseUrl": "https://www.bible.com/bible/59/GEN.28.5",
    "verseText": "Thus Isaac sent Jacob away. And he went to Paddan-aram, to Laban, the son of Bethuel the Aramean, the brother of Rebekah, Jacob’s and Esau’s mother.",
    "submissionText": ""
  },
  { // June 24
    "word": "arrow",
    "hint": "Psalm 64",
    "verse": "Psalm 64:7",
    "hintUrl": "https://www.bible.com/bible/59/PSA.64",
    "verseUrl": "https://www.bible.com/bible/59/PSA.64.7",
    "verseText": "But God shoots his arrow at them; they are wounded suddenly.",
    "submissionText": ""
  },
  { // June 25
    "word": "utter",
    "hint": "Psalm 106",
    "verse": "Psalm 106:2",
    "hintUrl": "https://www.bible.com/bible/59/PSA.106",
    "verseUrl": "https://www.bible.com/bible/59/PSA.106.2",
    "verseText": "Who can utter the mighty deeds of the LORD, or declare all his praise?",
    "submissionText": ""
  },
  { // June 26
    "word": "borne",
    "hint": "Isaiah 53",
    "verse": "Isaiah 53:4",
    "hintUrl": "https://www.bible.com/bible/59/ISA.53",
    "verseUrl": "https://www.bible.com/bible/59/ISA.53.4",
    "verseText": "Surely he has borne our griefs and carried our sorrows; yet we esteemed him stricken, smitten by God, and afflicted.",
    "submissionText": ""
  },
  { // June 27
    "word": "break",
    "hint": "Matthew 6",
    "verse": "Matthew 6:19-20",
    "hintUrl": "https://www.bible.com/bible/59/MAT.6",
    "verseUrl": "https://www.bible.com/bible/59/MAT.6.19-20",
    "verseText": "“Do not lay up for yourselves treasures on earth, where moth and rust destroy and where thieves break in and steal, but lay up for yourselves treasures in heaven, where neither moth nor rust destroys and where thieves do not break in and steal.",
    "submissionText": ""
  },
  { // June 28
    "word": "unite",
    "hint": "Psalm 86",
    "verse": "Psalm 86:11",
    "hintUrl": "https://www.bible.com/bible/59/PSA.86",
    "verseUrl": "https://www.bible.com/bible/59/PSA.86.11",
    "verseText": "Teach me your way, O LORD, that I may walk in your truth; unite my heart to fear your name.",
    "submissionText": ""
  },
  { // June 29
    "word": "judge",
    "hint": "John 7",
    "verse": "John 7:24",
    "hintUrl": "https://www.bible.com/bible/59/JHN.7",
    "verseUrl": "https://www.bible.com/bible/59/JHN.7.24",
    "verseText": "Do not judge by appearances, but judge with right judgment.”",
    "submissionText": ""
  },
  { // June 30
    "word": "Words",
    "hint": "Psalm 19",
    "verse": "Psalm 19:14",
    "hintUrl": "https://www.bible.com/bible/59/PSA.19",
    "verseUrl": "https://www.bible.com/bible/59/PSA.19.14",
    "verseText": "Let the words of my mouth and the meditation of my heart be acceptable in your sight, O LORD, my rock and my redeemer.",
    "submissionText": ""
  },
  { // July 1
    "word": "green",
    "hint": "Psalm 23",
    "verse": "Psalm 23:2",
    "hintUrl": "https://www.bible.com/bible/59/PSA.23",
    "verseUrl": "https://www.bible.com/bible/59/PSA.23.2",
    "verseText": "He makes me lie down in green pastures. He leads me beside still waters.",
    "submissionText": ""
  },
  { // July 2
    "word": "water",
    "hint": "John 4",
    "verse": "John 4:14",
    "hintUrl": "https://www.bible.com/bible/59/JHN.4",
    "verseUrl": "https://www.bible.com/bible/59/JHN.4.14",
    "verseText": "but whoever drinks of the water that I will give him will never be thirsty again. The water that I will give him will become in him a spring of water welling up to eternal life.”",
    "submissionText": ""
  },
  { // July 3
    "word": "apply",
    "hint": "Proverbs 23",
    "verse": "Proverbs 23:12",
    "hintUrl": "https://www.bible.com/bible/59/PRO.23",
    "verseUrl": "https://www.bible.com/bible/59/PRO.23.12",
    "verseText": "Apply your heart to instruction and your ear to words of knowledge.",
    "submissionText": ""
  },
  { // July 4
    "word": "aloes",
    "hint": "John 19",
    "verse": "John 19:39",
    "hintUrl": "https://www.bible.com/bible/59/JHN.19",
    "verseUrl": "https://www.bible.com/bible/59/JHN.19.39",
    "verseText": "Nicodemus also, who earlier had come to Jesus by night, came bringing a mixture of myrrh and aloes, about seventy-five pounds in weight.",
    "submissionText": ""
  },
  { // July 5
    "word": "image",
    "hint": "Genesis 1",
    "verse": "Genesis 1:27",
    "hintUrl": "https://www.bible.com/bible/59/GEN.1",
    "verseUrl": "https://www.bible.com/bible/59/GEN.1.27",
    "verseText": "So God created man in his own image, in the image of God he created him; male and female he created them.",
    "submissionText": ""
  },
  { // July 6
    "word": "alone",
    "hint": "Genesis 2",
    "verse": "Genesis 2:18",
    "hintUrl": "https://www.bible.com/bible/59/GEN.2",
    "verseUrl": "https://www.bible.com/bible/59/GEN.2.18",
    "verseText": "Then the LORD God said, “It is not good that the man should be alone; I will make him a helper fit for him.”",
    "submissionText": ""
  },
  { // July 7
    "word": "widow",
    "hint": "Psalm 146",
    "verse": "Psalm 146:9",
    "hintUrl": "https://www.bible.com/bible/59/PSA.146",
    "verseUrl": "https://www.bible.com/bible/59/PSA.146.9",
    "verseText": "The LORD watches over the sojourners; he upholds the widow and the fatherless, but the way of the wicked he brings to ruin.",
    "submissionText": ""
  },
  { // July 8
    "word": "judah",
    "hint": "Revelation 5",
    "verse": "Revelation 5:5",
    "hintUrl": "https://www.bible.com/bible/59/REV.5",
    "verseUrl": "https://www.bible.com/bible/59/REV.5.5",
    "verseText": "And one of the elders said to me, “Weep no more; behold, the Lion of the tribe of Judah, the Root of David, has conquered, so that he can open the scroll and its seven seals.”",
    "submissionText": ""
  },
  { // July 9
    "word": "camel",
    "hint": "Mark 10",
    "verse": "Mark 10:25",
    "hintUrl": "https://www.bible.com/bible/59/MRK.10",
    "verseUrl": "https://www.bible.com/bible/59/MRK.10.25",
    "verseText": "It is easier for a camel to go through the eye of a needle than for a rich person to enter the kingdom of God.”",
    "submissionText": ""
  },
  { // July 10
    "word": "gates",
    "hint": "Matthew 16",
    "verse": "Matthew 16:18",
    "hintUrl": "https://www.bible.com/bible/59/MAT.16",
    "verseUrl": "https://www.bible.com/bible/59/MAT.16.18",
    "verseText": "And I tell you, you are Peter, and on this rock I will build my church, and the gates of hell shall not prevail against it.",
    "submissionText": ""
  },
  { // July 11
    "word": "brook",
    "hint": "1 Kings 17",
    "verse": "1 Kings 17:4",
    "hintUrl": "https://www.bible.com/bible/59/1KI.17",
    "verseUrl": "https://www.bible.com/bible/59/1KI.17.4",
    "verseText": "You shall drink from the brook, and I have commanded the ravens to feed you there.”",
    "submissionText": ""
  },
  { // July 12
    "word": "knife",
    "hint": "Genesis 22",
    "verse": "Genesis 22:6",
    "hintUrl": "https://www.bible.com/bible/59/GEN.22",
    "verseUrl": "https://www.bible.com/bible/59/GEN.22.6",
    "verseText": "And Abraham took the wood of the burnt offering and laid it on Isaac his son. And he took in his hand the fire and the knife. So they went both of them together.",
    "submissionText": ""
  },
  { // July 13
    "word": "bound",
    "hint": "Proverbs 22",
    "verse": "Proverbs 22:15",
    "hintUrl": "https://www.bible.com/bible/59/PRO.22",
    "verseUrl": "https://www.bible.com/bible/59/PRO.22.15",
    "verseText": "Folly is bound up in the heart of a child, but the rod of discipline drives it far from him.",
    "submissionText": ""
  },
  { // July 14
    "word": "flies",
    "hint": "Ecclesiastes 10",
    "verse": "Ecclesiastes 10:1",
    "hintUrl": "https://www.bible.com/bible/59/ECC.10",
    "verseUrl": "https://www.bible.com/bible/59/ECC.10.1",
    "verseText": "Dead flies make the perfumer’s ointment give off a stench; so a little folly outweighs wisdom and honor.",
    "submissionText": ""
  },
  { // July 15
    "word": "exalt",
    "hint": "1 Peter 5",
    "verse": "1 Peter 5:6",
    "hintUrl": "https://www.bible.com/bible/59/1PE.5",
    "verseUrl": "https://www.bible.com/bible/59/1PE.5.6",
    "verseText": "Humble yourselves, therefore, under the mighty hand of God so that at the proper time he may exalt you,",
    "submissionText": ""
  },
  { // July 16
    "word": "fever",
    "hint": "Luke 4",
    "verse": "Luke 4:38-39",
    "hintUrl": "https://www.bible.com/bible/59/LUK.4",
    "verseUrl": "https://www.bible.com/bible/59/LUK.4.38-39",
    "verseText": "And he arose and left the synagogue and entered Simon’s house. Now Simon’s mother-in-law was ill with a high fever, and they appealed to him on her behalf. And he stood over her and rebuked the fever, and it left her, and immediately she rose and began to serve them.",
    "submissionText": ""
  },
  { // July 17
    "word": "hairs",
    "hint": "Matthew 10",
    "verse": "Matthew 10:30",
    "hintUrl": "https://www.bible.com/bible/59/MAT.10",
    "verseUrl": "https://www.bible.com/bible/59/MAT.10.30",
    "verseText": "But even the hairs of your head are all numbered.",
    "submissionText": ""
  },
  { // July 18
    "word": "inner",
    "hint": "2 Corinthians 4",
    "verse": "2 Corinthians 4:16",
    "hintUrl": "https://www.bible.com/bible/59/2Co.4",
    "verseUrl": "https://www.bible.com/bible/59/2Co.4.16",
    "verseText": "So we do not lose heart. Though our outer self is wasting away, our inner self is being renewed day by day.",
    "submissionText": ""
  },
  { // July 19
    "word": "quiet",
    "hint": "1 Timothy 2",
    "verse": "1 Timothy 2:1-2",
    "hintUrl": "https://www.bible.com/bible/59/1TI.2",
    "verseUrl": "https://www.bible.com/bible/59/1TI.2.1-2",
    "verseText": "First of all, then, I urge that supplications, prayers, intercessions, and thanksgivings be made for all people, for kings and all who are in high positions, that we may lead a peaceful and quiet life, godly and dignified in every way.",
    "submissionText": ""
  },
  { // July 20
    "word": "bride",
    "hint": "Revelation 21",
    "verse": "Revelation 21:2",
    "hintUrl": "https://www.bible.com/bible/59/REV.21",
    "verseUrl": "https://www.bible.com/bible/59/REV.21.2",
    "verseText": "And I saw the holy city, new Jerusalem, coming down out of heaven from God, prepared as a bride adorned for her husband.",
    "submissionText": ""
  },
  { // July 21
    "word": "frost",
    "hint": "Exodus 16",
    "verse": "Exodus 16:14",
    "hintUrl": "https://www.bible.com/bible/59/EXO.16",
    "verseUrl": "https://www.bible.com/bible/59/EXO.16.14",
    "verseText": "And when the dew had gone up, there was on the face of the wilderness a fine, flake-like thing, fine as frost on the ground.",
    "submissionText": ""
  },
  { // July 22
    "word": "watch",
    "hint": "Proverbs 15",
    "verse": "Proverbs 15:3",
    "hintUrl": "https://www.bible.com/bible/59/PRO.15",
    "verseUrl": "https://www.bible.com/bible/59/PRO.15.3",
    "verseText": "The eyes of the LORD are in every place, keeping watch on the evil and the good.",
    "submissionText": ""
  },
  { // July 23
    "word": "hours",
    "hint": "Acts 19",
    "verse": "Acts 19:34",
    "hintUrl": "https://www.bible.com/bible/59/ACT.19",
    "verseUrl": "https://www.bible.com/bible/59/ACT.19.34",
    "verseText": "But when they recognized that he was a Jew, for about two hours they all cried out with one voice, “Great is Artemis of the Ephesians!”",
    "submissionText": ""
  },
  { // July 24
    "word": "delay",
    "hint": "Psalm 40",
    "verse": "Psalm 40:17",
    "hintUrl": "https://www.bible.com/bible/59/PSA.40",
    "verseUrl": "https://www.bible.com/bible/59/PSA.40.17",
    "verseText": "As for me, I am poor and needy, but the Lord takes thought for me. You are my help and my deliverer; do not delay, O my God!",
    "submissionText": ""
  },
  { // July
    "word": "chaff",
    "hint": "Luke 3",
    "hintUrl": "https://www.bible.com/bible/59/LUK.3",
    "verse": "Luke 3:17",
    "verseUrl": "https://www.bible.com/bible/59/LUK.3.17",
    "verseText": "His winnowing fork is in his hand, to clear his threshing floor and to gather the wheat into his barn, but the chaff he will burn with unquenchable fire.”",
    "submissionText": ""
  },
  { // July
    "word": "yours",
    "hint": "Luke 22",
    "hintUrl": "https://www.bible.com/bible/59/LUK.22",
    "verse": "Luke 22:42",
    "verseUrl": "https://www.bible.com/bible/59/LUK.22.42",
    "verseText": "saying, “Father, if you are willing, remove this cup from me. Nevertheless, not my will, but yours, be done.”",
    "submissionText": ""
  },
  { // July
    "word": "woman",
    "hint": "Genesis 2",
    "hintUrl": "https://www.bible.com/bible/59/GEN.2",
    "verse": "Genesis 2:23",
    "verseUrl": "https://www.bible.com/bible/59/GEN.2.23",
    "verseText": "Then the man said, “This at last is bone of my bones and flesh of my flesh; she shall be called Woman, because she was taken out of Man.”",
    "submissionText": ""
  },
  { // July
    "word": "black",
    "hint": "Matthew 5",
    "hintUrl": "https://www.bible.com/bible/59/MAT.5",
    "verse": "Matthew 5:36",
    "verseUrl": "https://www.bible.com/bible/59/MAT.5.36",
    "verseText": "And do not take an oath by your head, for you cannot make one hair white or black.",
    "submissionText": ""
  },
  { // July
    "word": "judas",
    "hint": "Luke 22",
    "hintUrl": "https://www.bible.com/bible/59/LUK.22",
    "verse": "Luke 22:48",
    "verseUrl": "https://www.bible.com/bible/59/LUK.22.48",
    "verseText": "but Jesus said to him, “Judas, would you betray the Son of Man with a kiss?”",
    "submissionText": ""
  },
  { // July
    "word": "chest",
    "hint": "Revelation 1",
    "hintUrl": "https://www.bible.com/bible/59/REV.1",
    "verse": "Revelation 1:13",
    "verseUrl": "https://www.bible.com/bible/59/REV.1.13",
    "verseText": "and in the midst of the lampstands one like a son of man, clothed with a long robe and with a golden sash around his chest.",
    "submissionText": ""
  },
  { // July
    "word": "Harsh",
    "hint": "Proverbs 15",
    "hintUrl": "https://www.bible.com/bible/59/PRO.15",
    "verse": "Proverbs 15:1",
    "verseUrl": "https://www.bible.com/bible/59/PRO.15.1",
    "verseText": "A soft answer turns away wrath, but a harsh word stirs up anger.",
    "submissionText": ""
  },
  { // August 1
    "word": "avoid",
    "hint": "Titus 3",
    "hintUrl": "https://www.bible.com/bible/59/TIT.3",
    "verse": "Titus 3:9",
    "verseUrl": "https://www.bible.com/bible/59/TIT.3.9",
    "verseText": "But avoid foolish controversies, genealogies, dissensions, and quarrels about the law, for they are unprofitable and worthless.",
    "submissionText": ""
  },
  { // August
    "word": "bribe",
    "hint": "Deuteronomy 10",
    "hintUrl": "https://www.bible.com/bible/59/DEU.10",
    "verse": "Deuteronomy 10:17",
    "verseUrl": "https://www.bible.com/bible/59/DEU.10.17",
    "verseText": "For the LORD your God is God of gods and Lord of lords, the great, the mighty, and the awesome God, who is not partial and takes no bribe.",
    "submissionText": ""
  },
  { // August
    "word": "empty",
    "hint": "Colossians 2",
    "hintUrl": "https://www.bible.com/bible/59/COL.2",
    "verse": "Colossians 2:8",
    "verseUrl": "https://www.bible.com/bible/59/COL.2.8",
    "verseText": "See to it that no one takes you captive by philosophy and empty deceit, according to human tradition, according to the elemental spirits of the world, and not according to Christ.",
    "submissionText": ""
  },
  { // August
    "word": "weary",
    "hint": "Galatians 6",
    "hintUrl": "https://www.bible.com/bible/59/GAL.6",
    "verse": "Galatians 6:9",
    "verseUrl": "https://www.bible.com/bible/59/GAL.6.9",
    "verseText": "And let us not grow weary of doing good, for in due season we will reap, if we do not give up.",
    "submissionText": ""
  },
  { // August
    "word": "giver",
    "hint": "2 Corinthians 9",
    "hintUrl": "https://www.bible.com/bible/59/2CO.9",
    "verse": "2 Corinthians 9:7",
    "verseUrl": "https://www.bible.com/bible/59/2CO.9.7",
    "verseText": "Each one must give as he has decided in his heart, not reluctantly or under compulsion, for God loves a cheerful giver.",
    "submissionText": ""
  },
  { // August
    "word": "worse",
    "hint": "1 Timothy 5",
    "hintUrl": "https://www.bible.com/bible/59/1TI.5",
    "verse": "1 Timothy 5:8",
    "verseUrl": "https://www.bible.com/bible/59/1TI.5.8",
    "verseText": "But if anyone does not provide for his relatives, and especially for members of his household, he has denied the faith and is worse than an unbeliever.",
    "submissionText": ""
  },
  { // August
    "word": "guide",
    "hint": "Psalm 73",
    "hintUrl": "https://www.bible.com/bible/59/PSA.73",
    "verse": "Psalm 73:24",
    "verseUrl": "https://www.bible.com/bible/59/PSA.73.24",
    "verseText": "You guide me with your counsel, and afterward you will receive me to glory.",
    "submissionText": ""
  },
  { // August
    "word": "excel",
    "hint": "1 Corinthians 14",
    "hintUrl": "https://www.bible.com/bible/59/1CO.14",
    "verse": "1 Corinthians 14:12",
    "verseUrl": "https://www.bible.com/bible/59/1CO.14.12",
    "verseText": "So with yourselves, since you are eager for manifestations of the Spirit, strive to excel in building up the church.",
    "submissionText": ""
  },
  { // August
    "word": "david",
    "hint": "Acts 13",
    "hintUrl": "https://www.bible.com/bible/59/ACT.13",
    "verse": "Acts 13:22",
    "verseUrl": "https://www.bible.com/bible/59/ACT.13.22",
    "verseText": "And when he had removed him, he raised up David to be their king, of whom he testified and said, ‘I have found in David the son of Jesse a man after my heart, who will do all my will.’",
    "submissionText": ""
  },
  { // August 10
    "word": "baker",
    "hint": "Genesis 40",
    "hintUrl": "https://www.bible.com/bible/59/GEN.40",
    "verse": "Genesis 40:5",
    "verseUrl": "https://www.bible.com/bible/59/GEN.40.5",
    "verseText": "And one night they both dreamed—the cupbearer and the baker of the king of Egypt, who were confined in the prison—each his own dream, and each dream with its own interpretation.",
    "submissionText": ""
  },
  { // August
    "word": "godly",
    "hint": "2 Timothy 3",
    "hintUrl": "https://www.bible.com/bible/59/2TI.3",
    "verse": "2 Timothy 3:12",
    "verseUrl": "https://www.bible.com/bible/59/2TI.3.12",
    "verseText": "Indeed, all who desire to live a godly life in Christ Jesus will be persecuted,",
    "submissionText": ""
  },
  { // August
    "word": "broad",
    "hint": "Psalm 119",
    "hintUrl": "https://www.bible.com/bible/59/PSA.119",
    "verse": "Psalm 119:96",
    "verseUrl": "https://www.bible.com/bible/59/PSA.119.96",
    "verseText": "I have seen a limit to all perfection, but your commandment is exceedingly broad.",
    "submissionText": ""
  },
  { // August
    "word": "kneel",
    "hint": "Psalm 95",
    "hintUrl": "https://www.bible.com/bible/59/PSA.95",
    "verse": "Psalm 95:6",
    "verseUrl": "https://www.bible.com/bible/59/PSA.95.6",
    "verseText": "Oh come, let us worship and bow down; let us kneel before the LORD, our Maker!",
    "submissionText": ""
  },
  { // August
    "word": "wheat",
    "hint": "Luke 22",
    "hintUrl": "https://www.bible.com/bible/59/LUK.22",
    "verse": "Luke 22:31-32",
    "verseUrl": "https://www.bible.com/bible/59/LUK.22.31-32",
    "verseText": "“Simon, Simon, behold, Satan demanded to have you, that he might sift you like wheat, but I have prayed for you that your faith may not fail. And when you have turned again, strengthen your brothers.”",
    "submissionText": ""
  },
  { // August
    "word": "early",
    "hint": "Psalm 127",
    "hintUrl": "https://www.bible.com/bible/59/PSA.127",
    "verse": "Psalm 127:2",
    "verseUrl": "https://www.bible.com/bible/59/PSA.127.2",
    "verseText": "It is in vain that you rise up early and go late to rest, eating the bread of anxious toil; for he gives to his beloved sleep.",
    "submissionText": ""
  },
  { // August
    "word": "yield",
    "hint": "Habakkuk 3",
    "hintUrl": "https://www.bible.com/bible/59/HAB.3",
    "verse": "Habakkuk 3:17-18",
    "verseUrl": "https://www.bible.com/bible/59/HAB.3.17-18",
    "verseText": "Though the fig tree should not blossom, nor fruit be on the vines, the produce of the olive fail and the fields yield no food, the flock be cut off from the fold and there be no herd in the stalls, yet I will rejoice in the LORD; I will take joy in the God of my salvation.",
    "submissionText": ""
  },
  { // August
    "word": "books",
    "hint": "Revelation 20",
    "hintUrl": "https://www.bible.com/bible/59/REV.20",
    "verse": "Revelation 20:12",
    "verseUrl": "https://www.bible.com/bible/59/REV.20.12",
    "verseText": "And I saw the dead, great and small, standing before the throne, and books were opened. Then another book was opened, which is the book of life. And the dead were judged by what was written in the books, according to what they had done.",
    "submissionText": ""
  },
  { // August
    "word": "haste",
    "hint": "Luke 2",
    "hintUrl": "https://www.bible.com/bible/59/LUK.2",
    "verse": "Luke 2:16",
    "verseUrl": "https://www.bible.com/bible/59/LUK.2.16",
    "verseText": "And they went with haste and found Mary and Joseph, and the baby lying in a manger.",
    "submissionText": ""
  },
  { // August
    "word": "quake",
    "hint": "Nahum 1",
    "hintUrl": "https://www.bible.com/bible/59/NAM.1",
    "verse": "Nahum 1:5",
    "verseUrl": "https://www.bible.com/bible/59/NAM.1.5",
    "verseText": "The mountains quake before him; the hills melt; the earth heaves before him, the world and all who dwell in it.",
    "submissionText": ""
  },
  { // August 20
    "word": "fully",
    "hint": "1 Peter 1",
    "hintUrl": "https://www.bible.com/bible/59/1PE.1",
    "verse": "1 Peter 1:13",
    "verseUrl": "https://www.bible.com/bible/59/1PE.1.13",
    "verseText": "Therefore, preparing your minds for action, and being sober-minded, set your hope fully on the grace that will be brought to you at the revelation of Jesus Christ.",
    "submissionText": ""
  },
  { // August
    "word": "alarm",
    "hint": "Joel 2",
    "hintUrl": "https://www.bible.com/bible/59/JOL.2",
    "verse": "Joel 2:1",
    "verseUrl": "https://www.bible.com/bible/59/JOL.2.1",
    "verseText": "Blow a trumpet in Zion; sound an alarm on my holy mountain! Let all the inhabitants of the land tremble, for the day of the LORD is coming; it is near,",
    "submissionText": ""
  },
  { // August
    "word": "barns",
    "hint": "Luke 12",
    "hintUrl": "https://www.bible.com/bible/59/LUK.12",
    "verse": "Luke 12:18",
    "verseUrl": "https://www.bible.com/bible/59/LUK.12.18",
    "verseText": "And he said, ‘I will do this: I will tear down my barns and build larger ones, and there I will store all my grain and my goods.",
    "submissionText": ""
  },
  { // August
    "word": "false",
    "hint": "Exodus 20",
    "hintUrl": "https://www.bible.com/bible/59/EXO.20",
    "verse": "Exodus 20:16",
    "verseUrl": "https://www.bible.com/bible/59/EXO.20.16",
    "verseText": "“You shall not bear false witness against your neighbor.",
    "submissionText": ""
  },
  { // August
    "word": "creep",
    "hint": "Psalm 104",
    "hintUrl": "https://www.bible.com/bible/59/PSA.104",
    "verse": "Psalm 104:20",
    "verseUrl": "https://www.bible.com/bible/59/PSA.104.20",
    "verseText": "You make darkness, and it is night, when all the beasts of the forest creep about.",
    "submissionText": ""
  },
  { // August
    "word": "blood",
    "hint": "Ephesians 1",
    "hintUrl": "https://www.bible.com/bible/59/EPH.1",
    "verse": "Ephesians 1:7",
    "verseUrl": "https://www.bible.com/bible/59/EPH.1.7",
    "verseText": "In him we have redemption through his blood, the forgiveness of our trespasses, according to the riches of his grace,",
    "submissionText": ""
  },
  { // August
    "word": "chain",
    "hint": "Revelation 20",
    "hintUrl": "https://www.bible.com/bible/59/REV.20",
    "verse": "Revelation 20:1",
    "verseUrl": "https://www.bible.com/bible/59/REV.20.1",
    "verseText": "Then I saw an angel coming down from heaven, holding in his hand the key to the bottomless pit and a great chain.",
    "submissionText": ""
  },
  { // August
    "word": "hairy",
    "hint": "Genesis 25",
    "hintUrl": "https://www.bible.com/bible/59/GEN.25",
    "verse": "Genesis 25:25",
    "verseUrl": "https://www.bible.com/bible/59/GEN.25.25",
    "verseText": "The first came out red, all his body like a hairy cloak, so they called his name Esau.",
    "submissionText": ""
  },
  { // August
    "word": "goats",
    "hint": "Matthew 25",
    "hintUrl": "https://www.bible.com/bible/59/MAT.25",
    "verse": "Matthew 25:32",
    "verseUrl": "https://www.bible.com/bible/59/MAT.25.32",
    "verseText": "Before him will be gathered all the nations, and he will separate people one from another as a shepherd separates the sheep from the goats.",
    "submissionText": ""
  },
  { // August
    "word": "grant",
    "hint": "Ephesians 3",
    "hintUrl": "https://www.bible.com/bible/59/EPH.3",
    "verse": "Ephesians 3:16",
    "verseUrl": "https://www.bible.com/bible/59/EPH.3.16",
    "verseText": "that according to the riches of his glory he may grant you to be strengthened with power through his Spirit in your inner being,",
    "submissionText": ""
  },
  { // August 30
    "word": "bowed",
    "hint": "Romans 11",
    "hintUrl": "https://www.bible.com/bible/59/ROM.11",
    "verse": "Romans 11:4",
    "verseUrl": "https://www.bible.com/bible/59/ROM.11.4",
    "verseText": "But what is God’s reply to him? “I have kept for myself seven thousand men who have not bowed the knee to Baal.”",
    "submissionText": ""
  },
  { // September 1
    "word": "laden",
    "hint": "Matthew 11",
    "hintUrl": "https://www.bible.com/bible/59/MAT.11",
    "verse": "Matthew 11:28",
    "verseUrl": "https://www.bible.com/bible/59/MAT.11.28",
    "verseText": "Come to me, all who labor and are heavy laden, and I will give you rest.",
    "submissionText": ""
  },
  { // September
    "word": "court",
    "hint": "1 Corinthians 4",
    "hintUrl": "https://www.bible.com/bible/59/1Co.4",
    "verse": "1 Corinthians 4:3",
    "verseUrl": "https://www.bible.com/bible/59/1Co.4.3",
    "verseText": "But with me it is a very small thing that I should be judged by you or by any human court. In fact, I do not even judge myself.",
    "submissionText": ""
  },
  { // September
    "word": "grain",
    "hint": "Genesis 41",
    "hintUrl": "https://www.bible.com/bible/59/GEN.41",
    "verse": "Genesis 41:49",
    "verseUrl": "https://www.bible.com/bible/59/GEN.41.49",
    "verseText": "And Joseph stored up grain in great abundance, like the sand of the sea, until he ceased to measure it, for it could not be measured.",
    "submissionText": ""
  },
  { // September
    "word": "known",
    "hint": "John 15",
    "hintUrl": "https://www.bible.com/bible/59/JHN.15",
    "verse": "John 15:15",
    "verseUrl": "https://www.bible.com/bible/59/JHN.15.15",
    "verseText": "No longer do I call you servants, for the servant does not know what his master is doing; but I have called you friends, for all that I have heard from my Father I have made known to you.",
    "submissionText": ""
  },
  { // September
    "word": "daily",
    "hint": "Luke 9",
    "hintUrl": "https://www.bible.com/bible/59/LUK.9",
    "verse": "Luke 9:23",
    "verseUrl": "https://www.bible.com/bible/59/LUK.9.23",
    "verseText": "And he said to all, “If anyone would come after me, let him deny himself and take up his cross daily and follow me.",
    "submissionText": ""
  },
  { // September
    "word": "elect",
    "hint": "Romans 8",
    "hintUrl": "https://www.bible.com/bible/59/ROM.8",
    "verse": "Romans 8:33",
    "verseUrl": "https://www.bible.com/bible/59/ROM.8.33",
    "verseText": "Who shall bring any charge against God’s elect? It is God who justifies.",
    "submissionText": ""
  },
  { // September
    "word": "boils",
    "hint": "Exodus 9",
    "hintUrl": "https://www.bible.com/bible/59/EXO.9",
    "verse": "Exodus 9:11",
    "verseUrl": "https://www.bible.com/bible/59/EXO.9.11",
    "verseText": "And the magicians could not stand before Moses because of the boils, for the boils came upon the magicians and upon all the Egyptians.",
    "submissionText": ""
  },
  { // September
    "word": "boast",
    "hint": "Ephesians 2",
    "hintUrl": "https://www.bible.com/bible/59/EPH.2",
    "verse": "Ephesians 2:8-9",
    "verseUrl": "https://www.bible.com/bible/59/EPH.2.8-9",
    "verseText": "For by grace you have been saved through faith. And this is not your own doing; it is the gift of God, not a result of works, so that no one may boast.",
    "submissionText": ""
  },
  { // September
    "word": "build",
    "hint": "Psalm 127",
    "hintUrl": "https://www.bible.com/bible/59/PSA.127",
    "verse": "Psalm 127:1",
    "verseUrl": "https://www.bible.com/bible/59/PSA.127.1",
    "verseText": "Unless the LORD builds the house, those who build it labor in vain. Unless the LORD watches over the city, the watchman stays awake in vain.",
    "submissionText": ""
  },
  { // September 10
    "word": "grace",
    "hint": "1 Corinthians 15",
    "hintUrl": "https://www.bible.com/bible/59/1CO.15",
    "verse": "1 Corinthians 15:10",
    "verseUrl": "https://www.bible.com/bible/59/1CO.15.10",
    "verseText": "But by the grace of God I am what I am, and his grace toward me was not in vain. On the contrary, I worked harder than any of them, though it was not I, but the grace of God that is with me.",
    "submissionText": ""
  },
  { // September
    "word": "wrath",
    "hint": "John 3",
    "hintUrl": "https://www.bible.com/bible/59/JHN.3",
    "verse": "John 3:36",
    "verseUrl": "https://www.bible.com/bible/59/JHN.3.36",
    "verseText": "Whoever believes in the Son has eternal life; whoever does not obey the Son shall not see life, but the wrath of God remains on him.",
    "submissionText": ""
  },
  { // September
    "word": "drunk",
    "hint": "Ephesians 5",
    "hintUrl": "https://www.bible.com/bible/59/EPH.5",
    "verse": "Ephesians 5:18",
    "verseUrl": "https://www.bible.com/bible/59/EPH.5.18",
    "verseText": "And do not get drunk with wine, for that is debauchery, but be filled with the Spirit,",
    "submissionText": ""
  },
  { // September
    "word": "ghost",
    "hint": "Matthew 14",
    "hintUrl": "https://www.bible.com/bible/59/MAT.14",
    "verse": "Matthew 14:26",
    "verseUrl": "https://www.bible.com/bible/59/MAT.14.26",
    "verseText": "But when the disciples saw him walking on the sea, they were terrified, and said, “It is a ghost!” and they cried out in fear.",
    "submissionText": ""
  },
  { // September
    "word": "doers",
    "hint": "James 1",
    "hintUrl": "https://www.bible.com/bible/59/JAS.1",
    "verse": "James 1:22",
    "verseUrl": "https://www.bible.com/bible/59/JAS.1.22",
    "verseText": "But be doers of the word, and not hearers only, deceiving yourselves.",
    "submissionText": ""
  },
  { // September
    "word": "worms",
    "hint": "Acts 12",
    "hintUrl": "https://www.bible.com/bible/59/ACT.12",
    "verse": "Acts 12:23",
    "verseUrl": "https://www.bible.com/bible/59/ACT.12.23",
    "verseText": "Immediately an angel of the Lord struck him down, because he did not give God the glory, and he was eaten by worms and breathed his last.",
    "submissionText": ""
  },
  { // September
    "word": "gains",
    "hint": "Matthew 16",
    "hintUrl": "https://www.bible.com/bible/59/MAT.16",
    "verse": "Matthew 16:26",
    "verseUrl": "https://www.bible.com/bible/59/MAT.16.26",
    "verseText": "For what will it profit a man if he gains the whole world and forfeits his soul? Or what shall a man give in return for his soul?",
    "submissionText": ""
  },
  { // September
    "word": "angel",
    "hint": "Luke 2",
    "hintUrl": "https://www.bible.com/bible/59/LUK.2",
    "verse": "Luke 2:10",
    "verseUrl": "https://www.bible.com/bible/59/LUK.2.10",
    "verseText": "And the angel said to them, “Fear not, for behold, I bring you good news of great joy that will be for all the people.",
    "submissionText": ""
  },
  { // September
    "word": "enjoy",
    "hint": "1 Timothy 6",
    "hintUrl": "https://www.bible.com/bible/59/1TI.6",
    "verse": "1 Timothy 6:17",
    "verseUrl": "https://www.bible.com/bible/59/1TI.6.17",
    "verseText": "As for the rich in this present age, charge them not to be haughty, nor to set their hopes on the uncertainty of riches, but on God, who richly provides us with everything to enjoy.",
    "submissionText": ""
  },
  { // September
    "word": "walls",
    "hint": "Hebrews 11",
    "hintUrl": "https://www.bible.com/bible/59/HEB.11",
    "verse": "Hebrews 11:30",
    "verseUrl": "https://www.bible.com/bible/59/HEB.11.30",
    "verseText": "By faith the walls of Jericho fell down after they had been encircled for seven days.",
    "submissionText": ""
  },
  { // September 20
    "word": "loves",
    "hint": "1 John 4",
    "hintUrl": "https://www.bible.com/bible/59/1JN.4",
    "verse": "1 John 4:7",
    "verseUrl": "https://www.bible.com/bible/59/1JN.4.7",
    "verseText": "Beloved, let us love one another, for love is from God, and whoever loves has been born of God and knows God.",
    "submissionText": ""
  },
  { // September
    "word": "ashes",
    "hint": "Jonah 3",
    "hintUrl": "https://www.bible.com/bible/59/JON.3",
    "verse": "Jonah 3:6",
    "verseUrl": "https://www.bible.com/bible/59/JON.3.6",
    "verseText": "The word reached the king of Nineveh, and he arose from his throne, removed his robe, covered himself with sackcloth, and sat in ashes.",
    "submissionText": ""
  },
  { // September 22
    "word": "enoch",
    "hint": "Genesis 5",
    "hintUrl": "https://www.bible.com/bible/59/GEN.5",
    "verse": "Genesis 5:24",
    "verseUrl": "https://www.bible.com/bible/59/GEN.5.24",
    "verseText": "Enoch walked with God, and he was not, for God took him.",
    "submissionText": ""
  },
  { //
    "word": "cease",
    "hint": "Genesis 8",
    "hintUrl": "https://www.bible.com/bible/59/GEN.8",
    "verse": "Genesis 8:22",
    "verseUrl": "https://www.bible.com/bible/59/GEN.8.22",
    "verseText": "While the earth remains, seedtime and harvest, cold and heat, summer and winter, day and night, shall not cease.”",
    "submissionText": ""
  },
  { //
    "word": "dealt",
    "hint": "Psalm 13",
    "hintUrl": "https://www.bible.com/bible/59/PSA.13",
    "verse": "Psalm 13:6",
    "verseUrl": "https://www.bible.com/bible/59/PSA.13.6",
    "verseText": "I will sing to the LORD, because he has dealt bountifully with me.",
    "submissionText": ""
  },
  { //
    "word": "foxes",
    "hint": "Luke 9",
    "hintUrl": "https://www.bible.com/bible/59/LUK.9",
    "verse": "Luke 9:58",
    "verseUrl": "https://www.bible.com/bible/59/LUK.9.58",
    "verseText": "And Jesus said to him, “Foxes have holes, and birds of the air have nests, but the Son of Man has nowhere to lay his head.”",
    "submissionText": ""
  },
  { //
    "word": "wound",
    "hint": "Deuteronomy 32",
    "hintUrl": "https://www.bible.com/bible/59/DEU.32",
    "verse": "Deuteronomy 32:39",
    "verseUrl": "https://www.bible.com/bible/59/DEU.32.39",
    "verseText": "“‘See now that I, even I, am he, and there is no god beside me; I kill and I make alive; I wound and I heal; and there is none that can deliver out of my hand.",
    "submissionText": ""
  },
  { //
    "word": "babel",
    "hint": "Genesis 11",
    "hintUrl": "https://www.bible.com/bible/59/GEN.11",
    "verse": "Genesis 11:9",
    "verseUrl": "https://www.bible.com/bible/59/GEN.11.9",
    "verseText": "Therefore its name was called Babel, because there the LORD confused the language of all the earth. And from there the LORD dispersed them over the face of all the earth.",
    "submissionText": ""
  },
  { //
    "word": "jesse",
    "hint": "Romans 15",
    "hintUrl": "https://www.bible.com/bible/59/ROM.15",
    "verse": "Romans 15:12",
    "verseUrl": "https://www.bible.com/bible/59/ROM.15.12",
    "verseText": "And again Isaiah says, “The root of Jesse will come, even he who arises to rule the Gentiles; in him will the Gentiles hope.”",
    "submissionText": ""
  },
  { //
    "word": "jonah",
    "hint": "Jonah 2",
    "hintUrl": "https://www.bible.com/bible/59/JON.2",
    "verse": "Jonah 2:10",
    "verseUrl": "https://www.bible.com/bible/59/JON.2.10",
    "verseText": "And the LORD spoke to the fish, and it vomited Jonah out upon the dry land.",
    "submissionText": ""
  },
  { //
    "word": "harps",
    "hint": "Revelation 14",
    "hintUrl": "https://www.bible.com/bible/59/REV.14",
    "verse": "Revelation 14:2",
    "verseUrl": "https://www.bible.com/bible/59/REV.14.2",
    "verseText": "And I heard a voice from heaven like the roar of many waters and like the sound of loud thunder. The voice I heard was like the sound of harpists playing on their harps,",
    "submissionText": ""
  },
  { //
    "word": "ivory",
    "hint": "1 Kings 10",
    "hintUrl": "https://www.bible.com/bible/59/1KI.10",
    "verse": "1 Kings 10:18",
    "verseUrl": "https://www.bible.com/bible/59/1KI.10.18",
    "verseText": "The king also made a great ivory throne and overlaid it with the finest gold.",
    "submissionText": ""
  },
  { //
    "word": "guard",
    "hint": "Philippians 4",
    "hintUrl": "https://www.bible.com/bible/59/PHP.4",
    "verse": "Philippians 4:7",
    "verseUrl": "https://www.bible.com/bible/59/PHP.4.7",
    "verseText": "And the peace of God, which surpasses all understanding, will guard your hearts and your minds in Christ Jesus.",
    "submissionText": ""
  },
  { //
    "word": "guest",
    "hint": "Luke 19",
    "hintUrl": "https://www.bible.com/bible/59/LUK.19",
    "verse": "Luke 19:7",
    "verseUrl": "https://www.bible.com/bible/59/LUK.19.7",
    "verseText": "And when they saw it, they all grumbled, “He has gone in to be the guest of a man who is a sinner.”",
    "submissionText": ""
  },
  { //
    "word": "world",
    "hint": "Romans 12",
    "hintUrl": "https://www.bible.com/bible/59/ROM.12",
    "verse": "Romans 12:2",
    "verseUrl": "https://www.bible.com/bible/59/ROM.12.2",
    "verseText": "Do not be conformed to this world, but be transformed by the renewal of your mind, that by testing you may discern what is the will of God, what is good and acceptable and perfect.",
    "submissionText": ""
  },
  { //
    "word": "found",
    "hint": "John 1",
    "hintUrl": "https://www.bible.com/bible/59/JHN.1",
    "verse": "John 1:41",
    "verseUrl": "https://www.bible.com/bible/59/JHN.1.41",
    "verseText": "He first found his own brother Simon and said to him, “We have found the Messiah” (which means Christ).",
    "submissionText": ""
  },
  { //
    "word": "blade",
    "hint": "Judges 3",
    "hintUrl": "https://www.bible.com/bible/59/JUD.3",
    "verse": "Judges 3:22",
    "verseUrl": "https://www.bible.com/bible/59/JUD.3.22",
    "verseText": "And the hilt also went in after the blade, and the fat closed over the blade, for he did not pull the sword out of his belly; and the dung came out.",
    "submissionText": ""
  },
  { //
    "word": "idols",
    "hint": "1 John 5",
    "hintUrl": "https://www.bible.com/bible/59/1JO.5",
    "verse": "1 John 5:21",
    "verseUrl": "https://www.bible.com/bible/59/1JO.5.21",
    "verseText": "Little children, keep yourselves from idols.",
    "submissionText": ""
  },
  { //
    "word": "agree",
    "hint": "Mark 14",
    "hintUrl": "https://www.bible.com/bible/59/MRK.14",
    "verse": "Mark 14:56",
    "verseUrl": "https://www.bible.com/bible/59/MRK.14.56",
    "verseText": "For many bore false witness against him, but their testimony did not agree.",
    "submissionText": ""
  },
  { //
    "word": "enter",
    "hint": "Psalm 100",
    "hintUrl": "https://www.bible.com/bible/59/PSA.100",
    "verse": "Psalm 100:4",
    "verseUrl": "https://www.bible.com/bible/59/PSA.100.4",
    "verseText": "Enter his gates with thanksgiving, and his courts with praise! Give thanks to him; bless his name!",
    "submissionText": ""
  },
  { //
    "word": "fixed",
    "hint": "Luke 16",
    "hintUrl": "https://www.bible.com/bible/59/LUK.16",
    "verse": "Luke 16:26",
    "verseUrl": "https://www.bible.com/bible/59/LUK.16.26",
    "verseText": "And besides all this, between us and you a great chasm has been fixed, in order that those who would pass from here to you may not be able, and none may cross from there to us.’",
    "submissionText": ""
  },
  { //
    "word": "alien",
    "hint": "Psalm 69",
    "hintUrl": "https://www.bible.com/bible/59/PSA.69",
    "verse": "Psalm 69:8",
    "verseUrl": "https://www.bible.com/bible/59/PSA.69.8",
    "verseText": "I have become a stranger to my brothers, an alien to my mother’s sons.",
    "submissionText": ""
  },
  { // October 11th
    "word": "fiery",
    "hint": "Daniel 3",
    "hintUrl": "https://www.bible.com/bible/59/DAN.3",
    "verse": "Daniel 3:26",
    "verseUrl": "https://www.bible.com/bible/59/DAN.3.26",
    "verseText": "Then Nebuchadnezzar came near to the door of the burning fiery furnace; he declared, “Shadrach, Meshach, and Abednego, servants of the Most High God, come out, and come here!” Then Shadrach, Meshach, and Abednego came out from the fire.",
    "submissionText": ""
  },
  { //
    "word": "death",
    "hint": "1 Corinthians 15",
    "hintUrl": "https://www.bible.com/bible/59/1CO.15",
    "verse": "1 Corinthians 15:55",
    "verseUrl": "https://www.bible.com/bible/59/1CO.15.55",
    "verseText": "O death, where is your victory? O death, where is your sting?",
    "submissionText": ""
  },
  { //
    "word": "haman",
    "hint": "Esther 7",
    "hintUrl": "https://www.bible.com/bible/59/EST.7",
    "verse": "Esther 7:6",
    "verseUrl": "https://www.bible.com/bible/59/EST.7.6",
    "verseText": "And Esther said, “A foe and enemy! This wicked Haman!” Then Haman was terrified before the king and the queen.",
    "submissionText": ""
  },
  { //
    "word": "hasty",
    "hint": "Proverbs 29",
    "hintUrl": "https://www.bible.com/bible/59/PRO.29",
    "verse": "Proverbs 29:20",
    "verseUrl": "https://www.bible.com/bible/59/PRO.29.29",
    "verseText": "Do you see a man who is hasty in his words? There is more hope for a fool than for him.",
    "submissionText": ""
  },
  { //
    "word": "doing",
    "hint": "Psalm 118",
    "hintUrl": "https://www.bible.com/bible/59/PSA.118",
    "verse": "Psalm 118:22-23",
    "verseUrl": "https://www.bible.com/bible/59/PSA.118.22-23",
    "verseText": "The stone that the builders rejected has become the cornerstone. This is the LORD’s doing; it is marvelous in our eyes.",
    "submissionText": ""
  },
  { //
    "word": "caves",
    "hint": "Isaiah 2",
    "hintUrl": "https://www.bible.com/bible/59/ISA.2",
    "verse": "Isaiah 2:19",
    "verseUrl": "https://www.bible.com/bible/59/ISA.2.19",
    "verseText": "And people shall enter the caves of the rocks and the holes of the ground, from before the terror of the LORD, and from the splendor of his majesty, when he rises to terrify the earth.",
    "submissionText": ""
  },
  { //
    "word": "dream",
    "hint": "Genesis 37",
    "hintUrl": "https://www.bible.com/bible/59/GEN.37",
    "verse": "Genesis 37:9",
    "verseUrl": "https://www.bible.com/bible/59/GEN.37.9",
    "verseText": "Then he dreamed another dream and told it to his brothers and said, “Behold, I have dreamed another dream. Behold, the sun, the moon, and eleven stars were bowing down to me.”",
    "submissionText": ""
  },
  { // October 17th
    "word": "arose",
    "hint": "Exodus 1",
    "hintUrl": "https://www.bible.com/bible/59/EXO.1",
    "verse": "Exodus 1:8",
    "verseUrl": "https://www.bible.com/bible/59/EXO.1.8",
    "verseText": "Now there arose a new king over Egypt, who did not know Joseph.",
    "submissionText": ""
  },
  { //
    "word": "learn",
    "hint": "Matthew 9",
    "hintUrl": "https://www.bible.com/bible/59/MAT.9",
    "verse": "Matthew 9:13",
    "verseUrl": "https://www.bible.com/bible/59/MAT.9.13",
    "verseText": "Go and learn what this means: ‘I desire mercy, and not sacrifice.’ For I came not to call the righteous, but sinners.”",
    "submissionText": ""
  },
  { //
    "word": "cedar",
    "hint": "Psalm 92",
    "hintUrl": "https://www.bible.com/bible/59/PSA.92",
    "verse": "Psalm 92:12",
    "verseUrl": "https://www.bible.com/bible/59/PSA.92.12",
    "verseText": "The righteous flourish like the palm tree and grow like a cedar in Lebanon.",
    "submissionText": ""
  },
  { //
    "word": "cheek",
    "hint": "Matthew 5",
    "hintUrl": "https://www.bible.com/bible/59/MAT.5",
    "verse": "Matthew 5:39",
    "verseUrl": "https://www.bible.com/bible/59/MAT.5.39",
    "verseText": "But I say to you, Do not resist the one who is evil. But if anyone slaps you on the right cheek, turn to him the other also.",
    "submissionText": ""
  },
  { //
    "word": "lying",
    "hint": "Proverbs 12",
    "hintUrl": "https://www.bible.com/bible/59/PRO.12",
    "verse": "Proverbs 12:22",
    "verseUrl": "https://www.bible.com/bible/59/PRO.12.22",
    "verseText": "Lying lips are an abomination to the LORD, but those who act faithfully are his delight.",
    "submissionText": ""
  },
  { //
    "word": "wiser",
    "hint": "1 Corinthians 1",
    "hintUrl": "https://www.bible.com/bible/59/1CO.1",
    "verse": "1 Corinthians 1:25",
    "verseUrl": "https://www.bible.com/bible/59/1CO.1.25",
    "verseText": "For the foolishness of God is wiser than men, and the weakness of God is stronger than men.",
    "submissionText": ""
  },
  { //
    "word": "queen",
    "hint": "Esther 2",
    "hintUrl": "https://www.bible.com/bible/59/EST.2",
    "verse": "Esther 2:17",
    "verseUrl": "https://www.bible.com/bible/59/EST.2.17",
    "verseText": "the king loved Esther more than all the women, and she won grace and favor in his sight more than all the virgins, so that he set the royal crown on her head and made her queen instead of Vashti.",
    "submissionText": ""
  },
  { //
    "word": "dimly",
    "hint": "1 Corinthians 13",
    "hintUrl": "https://www.bible.com/bible/59/1CO.13",
    "verse": "1 Corinthians 13:12",
    "verseUrl": "https://www.bible.com/bible/59/1CO.13.12",
    "verseText": "For now we see in a mirror dimly, but then face to face. Now I know in part; then I shall know fully, even as I have been fully known.",
    "submissionText": ""
  },
  { //
    "word": "boats",
    "hint": "Luke 5",
    "hintUrl": "https://www.bible.com/bible/59/LUK.5",
    "verse": "Luke 5:11",
    "verseUrl": "https://www.bible.com/bible/59/LUK.5.11",
    "verseText": "And when they had brought their boats to land, they left everything and followed him.",
    "submissionText": ""
  },
  { //
    "word": "armor",
    "hint": "Ephesians 6",
    "hintUrl": "https://www.bible.com/bible/59/EPH.6",
    "verse": "Ephesians 6:11",
    "verseUrl": "https://www.bible.com/bible/59/EPH.6.11",
    "verseText": "Put on the whole armor of God, that you may be able to stand against the schemes of the devil.",
    "submissionText": ""
  },
  { //
    "word": "fools",
    "hint": "Proverbs 1",
    "hintUrl": "https://www.bible.com/bible/59/PRO.1",
    "verse": "Proverbs 1:7",
    "verseUrl": "https://www.bible.com/bible/59/PRO.1.7",
    "verseText": "The fear of the LORD is the beginning of knowledge; fools despise wisdom and instruction.",
    "submissionText": ""
  },
  { //
    "word": "deals",
    "hint": "Psalm 112",
    "hintUrl": "https://www.bible.com/bible/59/PSA.112",
    "verse": "Psalm 112:5",
    "verseUrl": "https://www.bible.com/bible/59/PSA.112.5",
    "verseText": "It is well with the man who deals generously and lends; who conducts his affairs with justice.",
    "submissionText": ""
  },
  { //
    "word": "angry",
    "hint": "Ephesians 4",
    "hintUrl": "https://www.bible.com/bible/59/EPH.4",
    "verse": "Ephesians 4:26",
    "verseUrl": "https://www.bible.com/bible/59/EPH.4.26",
    "verseText": "Be angry and do not sin; do not let the sun go down on your anger,",
    "submissionText": ""
  },
  { //
    "word": "herds",
    "hint": "Proverbs 27",
    "hintUrl": "https://www.bible.com/bible/59/PRO.27",
    "verse": "Proverbs 27:23",
    "verseUrl": "https://www.bible.com/bible/59/PRO.27.23",
    "verseText": "Know well the condition of your flocks, and give attention to your herds,",
    "submissionText": ""
  },
  { //
    "word": "jacob",
    "hint": "Genesis 32",
    "hintUrl": "https://www.bible.com/bible/59/GEN.32",
    "verse": "Genesis 32:28",
    "verseUrl": "https://www.bible.com/bible/59/GEN.32.28",
    "verseText": "Then he said, “Your name shall no longer be called Jacob, but Israel, for you have striven with God and with men, and have prevailed.”",
    "submissionText": ""
  },
  { //
    "word": "anger",
    "hint": "Proverbs 19",
    "hintUrl": "https://www.bible.com/bible/59/PRO.19",
    "verse": "Proverbs 19:11",
    "verseUrl": "https://www.bible.com/bible/59/PRO.19.11",
    "verseText": "Good sense makes one slow to anger, and it is his glory to overlook an offense.",
    "submissionText": ""
  },
  { //
    "word": "grass",
    "hint": "Isaiah 40",
    "hintUrl": "https://www.bible.com/bible/59/ISA.40",
    "verse": "Isaiah 40:8",
    "verseUrl": "https://www.bible.com/bible/59/ISA.40.8",
    "verseText": "The grass withers, the flower fades, but the word of our God will stand forever.",
    "submissionText": ""
  },
  { //
    "word": "above",
    "hint": "Colossians 3",
    "hintUrl": "https://www.bible.com/bible/59/COL.3",
    "verse": "Colossians 3:1",
    "verseUrl": "https://www.bible.com/bible/59/COL.3.1",
    "verseText": "If then you have been raised with Christ, seek the things that are above, where Christ is, seated at the right hand of God.",
    "submissionText": ""
  },
  { //
    "word": "flood",
    "hint": "Genesis 9",
    "hintUrl": "https://www.bible.com/bible/59/GEN.9",
    "verse": "Genesis 9:11",
    "verseUrl": "https://www.bible.com/bible/59/GEN.9.11",
    "verseText": "I establish my covenant with you, that never again shall all flesh be cut off by the waters of the flood, and never again shall there be a flood to destroy the earth.”",
    "submissionText": ""
  },
  { //
    "word": "grave",
    "hint": "Isaiah 53",
    "hintUrl": "https://www.bible.com/bible/59/ISA.53",
    "verse": "Isaiah 53:9",
    "verseUrl": "https://www.bible.com/bible/59/ISA.53.9",
    "verseText": "And they made his grave with the wicked and with a rich man in his death, although he had done no violence, and there was no deceit in his mouth.",
    "submissionText": ""
  },
  { //
    "word": "dried",
    "hint": "Joshua 4",
    "hintUrl": "https://www.bible.com/bible/59/JOS.4",
    "verse": "Joshua 4:23",
    "verseUrl": "https://www.bible.com/bible/59/JOS.4.23",
    "verseText": "For the LORD your God dried up the waters of the Jordan for you until you passed over, as the LORD your God did to the Red Sea, which he dried up for us until we passed over,",
    "submissionText": ""
  },
  { //
    "word": "cross",
    "hint": "1 Corinthians 1",
    "hintUrl": "https://www.bible.com/bible/59/1CO.1",
    "verse": "1 Corinthians 1:18",
    "verseUrl": "https://www.bible.com/bible/59/1CO.1.18",
    "verseText": "For the word of the cross is folly to those who are perishing, but to us who are being saved it is the power of God.",
    "submissionText": ""
  },
  { //
    "word": "visit",
    "hint": "James 1",
    "hintUrl": "https://www.bible.com/bible/59/JAS.1",
    "verse": "James 1:27",
    "verseUrl": "https://www.bible.com/bible/59/JAS.1.27",
    "verseText": "Religion that is pure and undefiled before God the Father is this: to visit orphans and widows in their affliction, and to keep oneself unstained from the world.",
    "submissionText": ""
  },
  { //
    "word": "lower",
    "hint": "Hebrews 2",
    "hintUrl": "https://www.bible.com/bible/59/HEB.2",
    "verse": "Hebrews 2:9",
    "verseUrl": "https://www.bible.com/bible/59/HEB.2.9",
    "verseText": "But we see him who for a little while was made lower than the angels, namely Jesus, crowned with glory and honor because of the suffering of death, so that by the grace of God he might taste death for everyone.",
    "submissionText": ""
  },
  { //
    "word": "fault",
    "hint": "Matthew 18",
    "hintUrl": "https://www.bible.com/bible/59/MAT.18",
    "verse": "Matthew 18:15",
    "verseUrl": "https://www.bible.com/bible/59/MAT.18.15",
    "verseText": "“If your brother sins against you, go and tell him his fault, between you and him alone. If he listens to you, you have gained your brother.",
    "submissionText": ""
  },
  { //
    "word": "gifts",
    "hint": "Matthew 7",
    "hintUrl": "https://www.bible.com/bible/59/MAT.7",
    "verse": "Matthew 7:11",
    "verseUrl": "https://www.bible.com/bible/59/MAT.7.11",
    "verseText": "If you then, who are evil, know how to give good gifts to your children, how much more will your Father who is in heaven give good things to those who ask him!",
    "submissionText": ""
  },
  { //
    "word": "baked",
    "hint": "Exodus 12",
    "hintUrl": "https://www.bible.com/bible/59/EXO.12",
    "verse": "Exodus 12:39",
    "verseUrl": "https://www.bible.com/bible/59/EXO.12.39",
    "verseText": "And they baked unleavened cakes of the dough that they had brought out of Egypt, for it was not leavened, because they were thrust out of Egypt and could not wait, nor had they prepared any provisions for themselves.",
    "submissionText": ""
  },
  { //
    "word": "money",
    "hint": "1 Timothy 6",
    "hintUrl": "https://www.bible.com/bible/59/1TI.6",
    "verse": "1 Timothy 6:10",
    "verseUrl": "https://www.bible.com/bible/59/1TI.6.10",
    "verseText": "For the love of money is a root of all kinds of evils. It is through this craving that some have wandered away from the faith and pierced themselves with many pangs.",
    "submissionText": ""
  },
  { //
    "word": "wings",
    "hint": "Psalm 36",
    "hintUrl": "https://www.bible.com/bible/59/PSA.36",
    "verse": "Psalm 36:7",
    "verseUrl": "https://www.bible.com/bible/59/PSA.36.7",
    "verseText": "How precious is your steadfast love, O God! The children of mankind take refuge in the shadow of your wings.",
    "submissionText": ""
  },
  { //
    "word": "greet",
    "hint": "2 Corinthians 13",
    "hintUrl": "https://www.bible.com/bible/59/2CO.13",
    "verse": "2 Corinthians 13:12",
    "verseUrl": "https://www.bible.com/bible/59/2CO.13.12",
    "verseText": "Greet one another with a holy kiss.",
    "submissionText": ""
  },
  { //
    "word": "equal",
    "hint": "John 5",
    "hintUrl": "https://www.bible.com/bible/59/JHN.5",
    "verse": "John 5:18",
    "verseUrl": "https://www.bible.com/bible/59/JHN.5.18",
    "verseText": "This was why the Jews were seeking all the more to kill him, because not only was he breaking the Sabbath, but he was even calling God his own Father, making himself equal with God.",
    "submissionText": ""
  },
  { //
    "word": "awake",
    "hint": "Psalm 17",
    "hintUrl": "https://www.bible.com/bible/59/PSA.17",
    "verse": "Psalm 17:15",
    "verseUrl": "https://www.bible.com/bible/59/PSA.17.15",
    "verseText": "As for me, I shall behold your face in righteousness; when I awake, I shall be satisfied with your likeness.",
    "submissionText": ""
  },
  { //
    "word": "abhor",
    "hint": "Romans 12",
    "hintUrl": "https://www.bible.com/bible/59/ROM.12",
    "verse": "Romans 12:9",
    "verseUrl": "https://www.bible.com/bible/59/ROM.12.9",
    "verseText": "Let love be genuine. Abhor what is evil; hold fast to what is good.",
    "submissionText": ""
  },
  { //
    "word": "wrong",
    "hint": "Job 1",
    "hintUrl": "https://www.bible.com/bible/59/JOB.1",
    "verse": "Job 1:22",
    "verseUrl": "https://www.bible.com/bible/59/JOB.1.22",
    "verseText": "In all this Job did not sin or charge God with wrong.",
    "submissionText": ""
  },
  { //
    "word": "unity",
    "hint": "Psalm 133",
    "hintUrl": "https://www.bible.com/bible/59/PSA.133",
    "verse": "Psalm 133:1",
    "verseUrl": "https://www.bible.com/bible/59/PSA.133.1",
    "verseText": "A Song of Ascents. Of David. Behold, how good and pleasant it is when brothers dwell in unity!",
    "submissionText": ""
  },
  { //
    "word": "count",
    "hint": "James 1",
    "hintUrl": "https://www.bible.com/bible/59/JAS.1",
    "verse": "James 1:2",
    "verseUrl": "https://www.bible.com/bible/59/JAS.1.2",
    "verseText": "Count it all joy, my brothers, when you meet trials of various kinds,",
    "submissionText": ""
  },
  { //
    "word": "purse",
    "hint": "Proverbs 1",
    "hintUrl": "https://www.bible.com/bible/59/PRO.1",
    "verse": "Proverbs 1:14",
    "verseUrl": "https://www.bible.com/bible/59/PRO.1.14",
    "verseText": "throw in your lot among us; we will all have one purse”—",
    "submissionText": ""
  },
  { //
    "word": "hosts",
    "hint": "Psalm 46",
    "hintUrl": "https://www.bible.com/bible/59/PSA.46",
    "verse": "Psalm 46:7",
    "verseUrl": "https://www.bible.com/bible/59/PSA.46.7",
    "verseText": "The LORD of hosts is with us; the God of Jacob is our fortress. Selah",
    "submissionText": ""
  },
  { //
    "word": "yoked",
    "hint": "2 Corinthians 6",
    "hintUrl": "https://www.bible.com/bible/59/2CO.6",
    "verse": "2 Corinthians 6:14",
    "verseUrl": "https://www.bible.com/bible/59/2CO.6.14",
    "verseText": "Do not be unequally yoked with unbelievers. For what partnership has righteousness with lawlessness? Or what fellowship has light with darkness?",
    "submissionText": ""
  },
  { //
    "word": "lamps",
    "hint": "Luke 12",
    "hintUrl": "https://www.bible.com/bible/59/LUK.12",
    "verse": "Luke 12:35",
    "verseUrl": "https://www.bible.com/bible/59/LUK.12.35",
    "verseText": "“Stay dressed for action and keep your lamps burning,",
    "submissionText": ""
  },
  { //
    "word": "flesh",
    "hint": "John 1",
    "hintUrl": "https://www.bible.com/bible/59/JHN.1",
    "verse": "John 1:14",
    "verseUrl": "https://www.bible.com/bible/59/JHN.1.14",
    "verseText": "And the Word became flesh and dwelt among us, and we have seen his glory, glory as of the only Son from the Father, full of grace and truth.",
    "submissionText": ""
  },
  { //
    "word": "crept",
    "hint": "Jude 1",
    "hintUrl": "https://www.bible.com/bible/59/JUD.1",
    "verse": "Jude 1:4",
    "verseUrl": "https://www.bible.com/bible/59/JUD.1.4",
    "verseText": "For certain people have crept in unnoticed who long ago were designated for this condemnation, ungodly people, who pervert the grace of our God into sensuality and deny our only Master and Lord, Jesus Christ.",
    "submissionText": ""
  },
  { //
    "word": "happy",
    "hint": "Genesis 30",
    "hintUrl": "https://www.bible.com/bible/59/GEN.30",
    "verse": "Genesis 30:13",
    "verseUrl": "https://www.bible.com/bible/59/GEN.30.13",
    "verseText": "And Leah said, “Happy am I! For women have called me happy.” So she called his name Asher.",
    "submissionText": ""
  },
  { //
    "word": "great",
    "hint": "Psalm 145",
    "hintUrl": "https://www.bible.com/bible/59/PSA.145",
    "verse": "Psalm 145:3",
    "verseUrl": "https://www.bible.com/bible/59/PSA.145.3",
    "verseText": "Great is the LORD, and greatly to be praised, and his greatness is unsearchable.",
    "submissionText": ""
  },
  { //
    "word": "honey",
    "hint": "Matthew 3",
    "hintUrl": "https://www.bible.com/bible/59/MAT.3",
    "verse": "Matthew 3:4",
    "verseUrl": "https://www.bible.com/bible/59/MAT.3.4",
    "verseText": "Now John wore a garment of camel’s hair and a leather belt around his waist, and his food was locusts and wild honey.",
    "submissionText": ""
  },
  { //
    "word": "fresh",
    "hint": "Judges 15",
    "hintUrl": "https://www.bible.com/bible/59/JDG.15",
    "verse": "Judges 15:15",
    "verseUrl": "https://www.bible.com/bible/59/JDG.15.15",
    "verseText": "And he found a fresh jawbone of a donkey, and put out his hand and took it, and with it he struck 1,000 men.",
    "submissionText": ""
  },
  { //
    "word": "charm",
    "hint": "Proverbs 31",
    "hintUrl": "https://www.bible.com/bible/59/PRO.31",
    "verse": "Proverbs 31:30",
    "verseUrl": "https://www.bible.com/bible/59/PRO.31.30",
    "verseText": "Charm is deceitful, and beauty is vain, but a woman who fears the LORD is to be praised.",
    "submissionText": ""
  },
  { //
    "word": "wages",
    "hint": "Romans 6",
    "hintUrl": "https://www.bible.com/bible/59/ROM.6",
    "verse": "Romans 6:23",
    "verseUrl": "https://www.bible.com/bible/59/ROM.6.23",
    "verseText": "For the wages of sin is death, but the free gift of God is eternal life in Christ Jesus our Lord.",
    "submissionText": ""
  },
  { //
    "word": "isaac",
    "hint": "Genesis 17",
    "hintUrl": "https://www.bible.com/bible/59/GEN.17",
    "verse": "Genesis 17:19",
    "verseUrl": "https://www.bible.com/bible/59/GEN.17.19",
    "verseText": "God said, “No, but Sarah your wife shall bear you a son, and you shall call his name Isaac. I will establish my covenant with him as an everlasting covenant for his offspring after him.",
    "submissionText": ""
  },
  { //
    "word": "evils",
    "hint": "Jeremiah 2",
    "hintUrl": "https://www.bible.com/bible/59/JER.2",
    "verse": "Jeremiah 2:13",
    "verseUrl": "https://www.bible.com/bible/59/JER.2.13",
    "verseText": "for my people have committed two evils: they have forsaken me, the fountain of living waters, and hewed out cisterns for themselves, broken cisterns that can hold no water.",
    "submissionText": ""
  },
  { //
    "word": "flash",
    "hint": "Job 41",
    "hintUrl": "https://www.bible.com/bible/59/JOB.41",
    "verse": "Job 41:18",
    "verseUrl": "https://www.bible.com/bible/59/JOB.41.18",
    "verseText": "His sneezings flash forth light, and his eyes are like the eyelids of the dawn.",
    "submissionText": ""
  },
  { // 
    "word": "lambs",
    "hint": "Luke 10",
    "hintUrl": "https://www.bible.com/bible/59/LUK.10",
    "verse": "Luke 10:3",
    "verseUrl": "https://www.bible.com/bible/59/LUK.10.3",
    "verseText": "Go your way; behold, I am sending you out as lambs in the midst of wolves.",
    "submissionText": ""
  },
  { // 
    "word": "large",
    "hint": "Mark 16",
    "hintUrl": "https://www.bible.com/bible/59/MRK.16",
    "verse": "Mark 16:4",
    "verseUrl": "https://www.bible.com/bible/59/MRK.16.4",
    "verseText": "And looking up, they saw that the stone had been rolled back—it was very large.",
    "submissionText": ""
  },
  { // 
    "word": "awoke",
    "hint": "Luke 8",
    "hintUrl": "https://www.bible.com/bible/59/LUK.8",
    "verse": "Luke 8:24",
    "verseUrl": "https://www.bible.com/bible/59/LUK.8.24",
    "verseText": "And they went and woke him, saying, “Master, Master, we are perishing!” And he awoke and rebuked the wind and the raging waves, and they ceased, and there was a calm.",
    "submissionText": ""
  },
  { // 
    "word": "force",
    "hint": "John 6",
    "hintUrl": "https://www.bible.com/bible/59/JHN.6",
    "verse": "John 6:15",
    "verseUrl": "https://www.bible.com/bible/59/JHN.6.15",
    "verseText": "Perceiving then that they were about to come and take him by force to make him king, Jesus withdrew again to the mountain by himself.",
    "submissionText": ""
  },
  { // 
    "word": "fitly",
    "hint": "Proverbs 25",
    "hintUrl": "https://www.bible.com/bible/59/PRO.25",
    "verse": "Proverbs 25:11",
    "verseUrl": "https://www.bible.com/bible/59/PRO.25.11",
    "verseText": "A word fitly spoken is like apples of gold in a setting of silver.",
    "submissionText": ""
  },
  { // 
    "word": "arise",
    "hint": "Mark 5",
    "hintUrl": "https://www.bible.com/bible/59/MRK.4",
    "verse": "Mark 5:41",
    "verseUrl": "https://www.bible.com/bible/59/MRK.4.41",
    "verseText": "Taking her by the hand he said to her, “Talitha cumi,” which means, “Little girl, I say to you, arise.”",
    "submissionText": ""
  },
  { // 
    "word": "cords",
    "hint": "Proverbs 5",
    "hintUrl": "https://www.bible.com/bible/59/PRO.5",
    "verse": "Proverbs 5:22",
    "verseUrl": "https://www.bible.com/bible/59/PRO.5.22",
    "verseText": "The iniquities of the wicked ensnare him, and he is held fast in the cords of his sin.",
    "submissionText": ""
  },
  { // 
    "word": "alter",
    "hint": "Psalm 89",
    "hintUrl": "https://www.bible.com/bible/59/PSA.89",
    "verse": "Psalm 89:34",
    "verseUrl": "https://www.bible.com/bible/59/PSA.89.34",
    "verseText": "I will not violate my covenant or alter the word that went forth from my lips.",
    "submissionText": ""
  },
  { // 
    "word": "fears",
    "hint": "Psalm 34",
    "hintUrl": "https://www.bible.com/bible/59/PSA.34",
    "verse": "Psalm 34:4",
    "verseUrl": "https://www.bible.com/bible/59/PSA.34.4",
    "verseText": "I sought the LORD, and he answered me and delivered me from all my fears.",
    "submissionText": ""
  },
  { // 
    "word": "herbs",
    "hint": "Proverbs 15",
    "hintUrl": "https://www.bible.com/bible/59/PRO.15",
    "verse": "Proverbs 15:17",
    "verseUrl": "https://www.bible.com/bible/59/PRO.15.17",
    "verseText": "Better is a dinner of herbs where love is than a fattened ox and hatred with it.",
    "submissionText": ""
  },
  { // 
    "word": "being",
    "hint": "Hebrews 10",
    "hintUrl": "https://www.bible.com/bible/59/HEB.10",
    "verse": "Hebrews 10:14",
    "verseUrl": "https://www.bible.com/bible/59/HEB.10.14",
    "verseText": "For by a single offering he has perfected for all time those who are being sanctified.",
    "submissionText": ""
  },
  { // 
    "word": "belly",
    "hint": "Jonah 1",
    "hintUrl": "https://www.bible.com/bible/59/JON.1",
    "verse": "Jonah 1:17",
    "verseUrl": "https://www.bible.com/bible/59/JON.1.17",
    "verseText": "And the LORD appointed a great fish to swallow up Jonah. And Jonah was in the belly of the fish three days and three nights.",
    "submissionText": ""
  },
  { // 
    "word": "glean",
    "hint": "Ruth 2",
    "hintUrl": "https://www.bible.com/bible/59/RTH.2",
    "verse": "Ruth 2:2",
    "verseUrl": "https://www.bible.com/bible/59/RTH.2.2",
    "verseText": "And Ruth the Moabite said to Naomi, “Let me go to the field and glean among the ears of grain after him in whose sight I shall find favor.” And she said to her, “Go, my daughter.”",
    "submissionText": ""
  },
  { // 
    "word": "tails",
    "hint": "Judges 15",
    "hintUrl": "https://www.bible.com/bible/59/JDG.15",
    "verse": "Judges 15:4",
    "verseUrl": "https://www.bible.com/bible/59/JDG.15.4",
    "verseText": "So Samson went and caught 300 foxes and took torches. And he turned them tail to tail and put a torch between each pair of tails.",
    "submissionText": ""
  },
  { // 
    "word": "clear",
    "hint": "Revelation 21",
    "hintUrl": "https://www.bible.com/bible/59/REV.21",
    "verse": "Revelation 21:18",
    "verseUrl": "https://www.bible.com/bible/59/REV.21.18",
    "verseText": "The wall was built of jasper, while the city was pure gold, like clear glass.",
    "submissionText": ""
  },
  { // 
    "word": "jewel",
    "hint": "Proverbs 20",
    "hintUrl": "https://www.bible.com/bible/59/PRO.20",
    "verse": "Proverbs 20:15",
    "verseUrl": "https://www.bible.com/bible/59/PRO.20.15",
    "verseText": "There is gold and abundance of costly stones, but the lips of knowledge are a precious jewel.",
    "submissionText": ""
  },
  { // 
    "word": "hills",
    "hint": "Psalm 121",
    "hintUrl": "https://www.bible.com/bible/59/PSA.121",
    "verse": "Psalm 121:1-2",
    "verseUrl": "https://www.bible.com/bible/59/PSA.121.1-2",
    "verseText": "I lift up my eyes to the hills. From where does my help come? My help comes from the LORD, who made heaven and earth.",
    "submissionText": ""
  },
  { // 
    "word": "hymns",
    "hint": "Colossians 3",
    "hintUrl": "https://www.bible.com/bible/59/COL.3",
    "verse": "Colossians 3:16",
    "verseUrl": "https://www.bible.com/bible/59/COL.3.16",
    "verseText": "Let the word of Christ dwell in you richly, teaching and admonishing one another in all wisdom, singing psalms and hymns and spiritual songs, with thankfulness in your hearts to God.",
    "submissionText": ""
  },
  { // 
    "word": "wiped",
    "hint": "John 12",
    "hintUrl": "https://www.bible.com/bible/59/JHN.12",
    "verse": "John 12:3",
    "verseUrl": "https://www.bible.com/bible/59/JHN.12.3",
    "verseText": "Mary therefore took a pound of expensive ointment made from pure nard, and anointed the feet of Jesus and wiped his feet with her hair. The house was filled with the fragrance of the perfume.",
    "submissionText": ""
  },
  { // 
    "word": "caleb",
    "hint": "Numbers 13",
    "hintUrl": "https://www.bible.com/bible/59/NUM.13",
    "verse": "Numbers 13:30",
    "verseUrl": "https://www.bible.com/bible/59/NUM.13.30",
    "verseText": "But Caleb quieted the people before Moses and said, “Let us go up at once and occupy it, for we are well able to overcome it.”",
    "submissionText": ""
  },
  { // 
    "word": "apart",
    "hint": "Psalm 16",
    "hintUrl": "https://www.bible.com/bible/59/PSA.16",
    "verse": "Psalm 16:2",
    "verseUrl": "https://www.bible.com/bible/59/PSA.16.2",
    "verseText": "I say to the LORD, “You are my Lord; I have no good apart from you.”",
    "submissionText": ""
  },
  { // 
    "word": "chose",
    "hint": "Ephesians 1",
    "hintUrl": "https://www.bible.com/bible/59/EPH.1",
    "verse": "Ephesians 1:4",
    "verseUrl": "https://www.bible.com/bible/59/EPH.1.4",
    "verseText": "even as he chose us in him before the foundation of the world, that we should be holy and blameless before him. In love",
    "submissionText": ""
  },
  { // 
    "word": "brood",
    "hint": "Luke 3",
    "hintUrl": "https://www.bible.com/bible/59/LUK.3",
    "verse": "Luke 3:7",
    "verseUrl": "https://www.bible.com/bible/59/LUK.3.7",
    "verseText": "He said therefore to the crowds that came out to be baptized by him, “You brood of vipers! Who warned you to flee from the wrath to come?",
    "submissionText": ""
  },
  { // 
    "word": "waves",
    "hint": "Psalm 89",
    "hintUrl": "https://www.bible.com/bible/59/PSA.89",
    "verse": "Psalm 89:9",
    "verseUrl": "https://www.bible.com/bible/59/PSA.89.9",
    "verseText": "You rule the raging of the sea; when its waves rise, you still them.",
    "submissionText": ""
  },
  { // 
    "word": "faith",
    "hint": "Hebrews 11",
    "hintUrl": "https://www.bible.com/bible/59/HEB.11",
    "verse": "Hebrews 11:1",
    "verseUrl": "https://www.bible.com/bible/59/HEB.11.1",
    "verseText": "Now faith is the assurance of things hoped for, the conviction of things not seen.",
    "submissionText": ""
  },
  { // 
    "word": "cared",
    "hint": "John 12",
    "hintUrl": "https://www.bible.com/bible/59/JHN.12",
    "verse": "John 12:6",
    "verseUrl": "https://www.bible.com/bible/59/JHN.12.6",
    "verseText": "He said this, not because he cared about the poor, but because he was a thief, and having charge of the moneybag he used to help himself to what was put into it.",
    "submissionText": ""
  },
  { // 
    "word": "hired",
    "hint": "Luke 15",
    "hintUrl": "https://www.bible.com/bible/59/LUK.15",
    "verse": "Luke 15:17",
    "verseUrl": "https://www.bible.com/bible/59/LUK.15.17",
    "verseText": "“But when he came to himself, he said, ‘How many of my father’s hired servants have more than enough bread, but I perish here with hunger!",
    "submissionText": ""
  },
  { // 
    "word": "cleft",
    "hint": "Exodus 33",
    "hintUrl": "https://www.bible.com/bible/59/EXO.33",
    "verse": "Exodus 33:22",
    "verseUrl": "https://www.bible.com/bible/59/EXO.33.22",
    "verseText": "and while my glory passes by I will put you in a cleft of the rock, and I will cover you with my hand until I have passed by.",
    "submissionText": ""
  },
  { // 
    "word": "event",
    "hint": "Ecclesiastes 2",
    "hintUrl": "https://www.bible.com/bible/59/ECC.2",
    "verse": "Ecclesiastes 2:14",
    "verseUrl": "https://www.bible.com/bible/59/ECC.2.14",
    "verseText": "The wise person has his eyes in his head, but the fool walks in darkness. And yet I perceived that the same event happens to all of them.",
    "submissionText": ""
  },
  { // 
    "word": "jubal",
    "hint": "Genesis 4",
    "hintUrl": "https://www.bible.com/bible/59/GEN.4",
    "verse": "Genesis 4:21",
    "verseUrl": "https://www.bible.com/bible/59/GEN.4.21",
    "verseText": "His brother’s name was Jubal; he was the father of all those who play the lyre and pipe.",
    "submissionText": ""
  },
  { // 
    "word": "flame",
    "hint": "Exodus 3",
    "hintUrl": "https://www.bible.com/bible/59/EXO.3",
    "verse": "Exodus 3:2",
    "verseUrl": "https://www.bible.com/bible/59/EXO.3.2",
    "verseText": "And the angel of the LORD appeared to him in a flame of fire out of the midst of a bush. He looked, and behold, the bush was burning, yet it was not consumed.",
    "submissionText": ""
  },
  {
    word: "fruit",
    verse: "John 15:5",
    verseUrl: "https://www.bible.com/bible/1/JHN.15.5.KJV",
    verseText: "I am the vine, ye are the branches: He that abideth in me, and I in him, the same bringeth forth much fruit: for without me ye can do nothing.",
    hint: "John 15",
    hintUrl: "https://www.bible.com/bible/1/JHN.15.KJV",
    submissionText: "Thank you Chad Adler for submitting this verse!"
  }, // April 18
  {
    word: "wiles",
    verse: "Ephesians 6:11",
    verseUrl: "https://www.bible.com/bible/1/EPH.6.11.KJV",
    verseText: "Put on the whole armour of God, that ye may be able to stand against the wiles of the devil.",
    hint: "Ephesians 6",
    hintUrl: "https://www.bible.com/bible/1/EPH.6.KJV",
  }, // April 19
  {
    word: "depth",
    verse: "Romans 8:39",
    verseUrl: "https://www.bible.com/bible/1/ROM.8.39.KJV",
    verseText: "Nor height, nor depth, nor any other creature, shall be able to separate us from the love of God, which is in Christ Jesus our Lord.",
    hint: "Romans 8",
    hintUrl: "https://www.bible.com/bible/1/ROM.8.KJV",
  }, // April 20
  {
    word: "steal",
    verse: "Matthew 6:20",
    verseUrl: "https://www.bible.com/bible/1/MAT.6.20.KJV",
    verseText: "But lay up for yourselves treasures in heaven, where neither moth nor rust doth corrupt, and where thieves do not break through nor steal",
    hint: "Matthew 6",
    hintUrl: "https://www.bible.com/bible/1/MAT.6.KJV",
  }, // April 21
  {
    word: "voice",
    verse: "Revelations 3:20",
    verseUrl: "https://www.bible.com/bible/1/REV.3.20.KJV",
    verseText: "Behold, I stand at the door, and knock: if any man hear my voice, and open the door, I will come in to him, and will sup with him, and he with me.",
    hint: "Revelations 3",
    hintUrl: "https://www.bible.com/bible/1/REV.3.KJV",
  }, // April 22
  {
    word: "agony",
    verse: "Luke 22:44",
    verseUrl: "https://www.bible.com/bible/1/LUK.22.44.KJV",
    verseText: "And being in an agony he prayed more earnestly: and his sweat was as it were great drops of blood falling down to the ground.",
    hint: "Luke 22",
    hintUrl: "https://www.bible.com/bible/1/LUK.22.KJV",
  }, // April 23
  {
    word: "exalt",
    verse: "1 Peter 5:6",
    verseUrl: "https://www.bible.com/bible/1/1PE.5.6.KJV",
    verseText: "Humble yourselves therefore under the mighty hand of God, that he may exalt you in due time",
    hint: "1 Peter 5",
    hintUrl: "https://www.bible.com/bible/1/1PE.5.KJV",
  }, // April 24
    {
    word: "giver",
    verse: "2 Corinthians 9:7",
    verseUrl: "https://www.bible.com/bible/59/2CO.9.7.ESV",
    verseText: "Each one must give as he has decided in his heart, not reluctantly or under compulsion, for God loves a cheerful giver.",
    hint: "2 Corinthians 9",
    hintUrl: "https://www.bible.com/bible/59/2CO.9.ESV",
  }, // April 25
    {
    word: "godly",
    verse: "2 Timothy 3:12",
    verseUrl: "https://www.bible.com/bible/59/2ti.3.12.ESV",
    verseText: "Indeed, all who desire to live a godly life in Christ Jesus will be persecuted.",
    hint: "2 Timothy 3",
    hintUrl: "https://www.bible.com/bible/59/2ti.3.ESV",
  }, // April 26
    {
    word: "fully",
    verse: "1 Peter 1:13",
    verseUrl: "https://www.bible.com/bible/59/1pe.1.13.ESV",
    verseText: "Therefore, preparing your minds for action, and being sober-minded, set your hope fully on the grace that will be brought to you at the revelation of Jesus Christ.",
    hint: "1 Peter 1",
    hintUrl: "https://www.bible.com/bible/59/1pe.1.ESV",
  }, // April 27
  {
    word: "happy",
    verse: "Genesis 30:13",
    verseUrl: "https://www.bible.com/bible/59/gen.30.13.ESV",
    verseText: "And Leah said, “Happy am I! For women have called me happy.” So she called his name Asher.",
    hint: "Genesis 30",
    hintUrl: "https://www.bible.com/bible/59/gen.30.ESV",
  }, // April 28
    {
    word: "hills",
    verse: "Psalm 121:1",
    verseUrl: "https://www.bible.com/bible/59/psa.121.1.ESV",
    verseText: "I lift up my eyes to the hills. From where does my help come?",
    hint: "Psalm 121",
    hintUrl: "https://www.bible.com/bible/59/psa.121.ESV",
  }, // April 29
    {
    word: "learn",
    verse: "Matthew 9:13",
    verseUrl: "https://www.bible.com/bible/59/mat.9.13.ESV",
    verseText: "Go and learn what this means: ‘I desire mercy, and not sacrifice.’ For I came not to call the righteous, but sinners.",
    hint: "Matthew 9",
    hintUrl: "https://www.bible.com/bible/59/mat.9.ESV",
  }, // April 30
    {
    word: "knees",
    verse: "Daniel 6:10",
    verseUrl: "https://www.bible.com/bible/59/dan.6.10.ESV",
    verseText: "When Daniel knew that the document had been signed, he went to his house where he had windows in his upper chamber open toward Jerusalem. He got down on his knees three times a day and prayed and gave thanks before his God, as he had done previously.",
    hint: "Daniel 6",
    hintUrl: "https://www.bible.com/bible/59/dan.6.ESV",
  }, // May 1
  {
    word: "aloud",
    verse: "Psalm 59:16",
    verseUrl: "https://www.bible.com/bible/59/psa.59.16.ESV",
    verseText: "But I will sing of your strength; I will sing aloud of your steadfast love in the morning. For you have been to me a fortress and a refuge in the day of my distress.",
    hint: "Psalm 59",
    hintUrl: "https://www.bible.com/bible/59/PSA.59.ESV",
  }, // May 2
  {
    word: "above",
    verse: "Colossians 3:1",
    verseUrl: "https://www.bible.com/bible/59/col.3.1.ESV",
    verseText: "If then you have been raised with Christ, seek the things that are above, where Christ is, seated at the right hand of God.",
    hint: "Colossians 3",
    hintUrl: "https://www.bible.com/bible/59/COL.3.ESV",
  }, // May 3
  {
    word: "baker",
    verse: "Genesis 40:5",
    verseUrl: "https://www.bible.com/bible/59/gen.40.5.ESV",
    verseText: "And one night they both dreamed—the cupbearer and the baker of the king of Egypt, who were confined in the prison—each his own dream, and each dream with its own interpretation.",
    hint: "Genesis 40",
    hintUrl: "https://www.bible.com/bible/59/GEN.40.ESV",
  }, // May 4
  {
    word: "loved",
    verse: "John 3:16",
    verseUrl: "https://www.bible.com/bible/59/jhn.3.16.ESV",
    verseText: "For God so loved the world, that he gave his only Son, that whoever believes in him should not perish but have eternal life.",
    hint: "John 3",
    hintUrl: "https://www.bible.com/bible/59/JHN.3.ESV",
  }, // May 5
  {
    word: "lands",
    verse: "Matthew 19:29",
    verseUrl: "https://www.bible.com/bible/59/mat.19.29.ESV",
    verseText: "And everyone who has left houses or brothers or sisters or father or mother or children or lands, for my name’s sake, will receive a hundredfold and will inherit eternal life.",
    hint: "Matthew 19",
    hintUrl: "https://www.bible.com/bible/59/MAT.19.ESV",
  }, // May 6
  {
    word: "laden",
    verse: "Matthew 11:28",
    verseUrl: "https://www.bible.com/bible/59/mat.11.28.ESV",
    verseText: "Come to me, all who labor and are heavy laden, and I will give you rest.",
    hint: "Matthew 11",
    hintUrl: "https://www.bible.com/bible/59/MAT.11.ESV",
  }, // May 5
  {
    word: "Jesus",
    verse: "Matthew 1:21",
    verseUrl: "https://www.bible.com/bible/59/mat.1.21.ESV",
    verseText: "She will bear a son, and you shall call his name Jesus, for he will save his people from their sins.",
    hint: "Matthew 1",
    hintUrl: "https://www.bible.com/bible/59/MAT.1.ESV",
  }, // May 8
  {
    word: "honey",
    verse: "Matthew 3:4",
    verseUrl: "https://www.bible.com/bible/59/mat.3.4.ESV",
    verseText: "Now John wore a garment of camel’s hair and a leather belt around his waist, and his food was locusts and wild honey.",
    hint: "Matthew 3",
    hintUrl: "https://www.bible.com/bible/59/MAT.3.ESV",
  }, // May 9
  {
    word: "guest",
    verse: "Luke 19:7",
    verseUrl: "https://www.bible.com/bible/59/luk.19.7.ESV",
    verseText: "And when they saw it, they all grumbled, “He has gone in to be the guest of a man who is a sinner.”",
    hint: "Luke 19",
    hintUrl: "https://www.bible.com/bible/59/LUK.19.ESV",
  }, // May 10
  {
    word: "touch",
    hint: "Matthew 9",
    verse: "Matthew 9:21",
    hintUrl: "https://www.bible.com/bible/59/mat.9.ESV",
    verseUrl: "https://www.bible.com/bible/59/mat.9.21.ESV",
    verseText: "For she said to herself, If I only touch his garment, I will be made well.",
    submissionText: "Thanks to Chuck Schlie for submitting this word!"
  }, // May 11
  {
    word: "whole",
    hint: "1 Thessalonians 5",
    verse: "1 Thessalonians 5:23",
    hintUrl: "https://www.bible.com/bible/59/1TH.5.ESV",
    verseUrl: "https://www.bible.com/bible/59/1TH.5.23.ESV",
    verseText: "Now may the God of peace himself sanctify you completely, and may your whole spirit and soul and body be kept blameless at the coming of our Lord Jesus Christ.",
    submissionText: "Thanks to Nate Platto for submitting this word!"
  }, // May 12
  {
    word: "blind",
    hint: "John 9",
    verse: "John 9:25",
    hintUrl: "https://www.bible.com/bible/59/JHN.9.ESV",
    verseUrl: "https://www.bible.com/bible/59/JHN.9.25.ESV",
    verseText: "He answered, Whether he is a sinner I do not know. One thing I do know, that though I was blind, now I see.",
    submissionText: ""
  }, // May 13
  {
    word: "mourn",
    hint: "Matthew 5",
    verse: "Matthew 5:4",
    hintUrl: "https://www.bible.com/bible/59/MAT.5.ESV",
    verseUrl: "https://www.bible.com/bible/59/MAT.5.4.ESV",
    verseText: "Blessed are those who mourn, for they shall be comforted.",
    submissionText: "Thanks to Kylie M for submitting this word!"
  }, // May 14
  {
    word: "reign",
    hint: "Exodus 15",
    verse: "Exodus 15:18",
    hintUrl: "https://www.bible.com/bible/59/EXO.15.ESV",
    verseUrl: "https://www.bible.com/bible/59/EXO.15.18.ESV",
    verseText: "The LORD will reign forever and ever.",
    submissionText: ""
  }, // May 15
  {
    word: "moses",
    hint: "John 3",
    verse: "John 3:14",
    hintUrl: "https://www.bible.com/bible/59/JHN.3.ESV",
    verseUrl: "https://www.bible.com/bible/59/JHN.3.14.ESV",
    verseText: "And as Moses lifted up the serpent in the wilderness, so must the Son of Man be lifted up",
    submissionText: ""
  }, // May 16
  {
    word: "alpha",
    hint: "Revelation 22",
    verse: "Revelation 22:13",
    hintUrl: "https://www.bible.com/bible/59/REV.22.ESV",
    verseUrl: "https://www.bible.com/bible/59/REV.22.13.ESV",
    verseText: "I am the Alpha and the Omega, the first and the last, the beginning and the end.",
    submissionText: ""
  }, // May 17
  {
    word: "peter",
    hint: "Acts 3",
    verse: "Acts 3:6",
    hintUrl: "https://www.bible.com/bible/59/ACT.3.ESV",
    verseUrl: "https://www.bible.com/bible/59/ACT.3.6.ESV",
    verseText: "But Peter said, I have no silver and gold, but what I do have I give to you. In the name of Jesus Christ of Nazareth, rise up and walk!",
    submissionText: "Thanks to Kim Ragland for submitting this word!"
  }, // May 18
  {
    word: "right",
    hint: "1 Peter 3",
    verse: "1 Peter 3:22",
    hintUrl: "https://www.bible.com/bible/59/1PE.3.ESV",
    verseUrl: "https://www.bible.com/bible/59/1PE.3.22.ESV",
    verseText: "Who has gone into heaven and is at the right hand of God, with angels, authorities, and powers having been subjected to him.",
    submissionText: ""
  }, // May 19
  {
    word: "bless",
    hint: "Romans 12",
    verse: "Romans 12:14",
    hintUrl: "https://www.bible.com/bible/59/ROM.12.ESV",
    verseUrl: "https://www.bible.com/bible/59/ROM.12.14.ESV",
    verseText: "Bless those who persecute you; bless and do not curse them.",
    submissionText: ""
  }, // May 20
  {
    word: "laden",
    hint: "Matthew 11",
    verse: "Matthew 11:28",
    hintUrl: "https://www.bible.com/bible/59/MAT.11.ESV",
    verseUrl: "https://www.bible.com/bible/59/MAT.11.28.ESV",
    verseText: "Come to me, all who labor and are heavy laden, and I will give you rest.",
    submissionText: "Thanks to Lesley Squire for submitting this word!"
  }, // May 21
  {
    word: "voice",
    hint: "Exodus 19",
    verse: "Exodus 19:5",
    hintUrl: "https://www.bible.com/bible/59/EXO.19.ESV",
    verseUrl: "https://www.bible.com/bible/59/EXO.19.5.ESV",
    verseText: "Now therefore, if you will indeed obey my voice and keep my covenant, you shall be my treasured possession among all peoples, for all the earth is mine",
    submissionText: "Thanks to Blytdina for submitting this word!"
  }, // May 22
  {
    word: "dream",
    hint: "Acts 2",
    verse: "Acts 2:17",
    hintUrl: "https://www.bible.com/bible/59/ACT.2.ESV",
    verseUrl: "https://www.bible.com/bible/59/ACT.2.17.ESV",
    verseText: "And in the last days it shall be, God declares, that I will pour out my Spirit on all flesh, and your sons and your daughters shall prophesy, and your young men shall see visions, and your old men shall dream dreams",
    submissionText: ""
  }, // May 23
  {
    word: "sheep",
    hint: "Matthew 18",
    verse: "Matthew 18:12",
    hintUrl: "https://www.bible.com/bible/59/MAT.18.ESV",
    verseUrl: "https://www.bible.com/bible/59/MAT.18.12.ESV",
    verseText: "What do you think? If a man has a hundred sheep, and one of them has gone astray, does he not leave the ninety-nine on the mountains and go in search of the one that went astray?",
    submissionText: ""
  }, // May 24
  {
    word: "power",
    hint: "2 Timothy 1",
    verse: "2 Timothy 1:7",
    hintUrl: "https://www.bible.com/bible/59/2TI.1.ESV",
    verseUrl: "https://www.bible.com/bible/59/2TI.1.7.ESV",
    verseText: "For God gave us a spirit not of fear but of power and love and self-control.",
    submissionText: "Thanks to Leyton Curran for submitting this word!"
  }, // May 25
  {
    word: "begin",
    hint: "Luke 21",
    verse: "Luke 21:28",
    hintUrl: "https://www.bible.com/bible/59/LUK.21.ESV",
    verseUrl: "https://www.bible.com/bible/59/LUK.21.28.ESV",
    verseText: "Now when these things begin to take place, straighten up and raise your heads, because your redemption is drawing near.",
    submissionText: ""
  }, // May 26
  {
    word: "birds",
    hint: "Matthew 6",
    verse: "Matthew 6:26",
    hintUrl: "https://www.bible.com/bible/59/MAT.6.ESV",
    verseUrl: "https://www.bible.com/bible/59/MAT.6.26.ESV",
    verseText: "Look at the birds of the air: they neither sow nor reap nor gather into barns, and yet your heavenly Father feeds them. Are you not of more value than they?",
    submissionText: ""
  }, // May 27
  {
    word: "doubt",
    hint: "Matthew 14",
    verse: "Matthew 14:31",
    hintUrl: "https://www.bible.com/bible/59/MAT.14.ESV",
    verseUrl: "https://www.bible.com/bible/59/MAT.14.31.ESV",
    verseText: "Jesus immediately reached out his hand and took hold of him, saying to him, O you of little faith, why did you doubt?",
    submissionText: ""
  }, // May 28
  {
    word: "fight",
    hint: "2 Timothy 4",
    verse: "2 Timothy 4:7",
    hintUrl: "https://www.bible.com/bible/59/2TI.4.ESV",
    verseUrl: "https://www.bible.com/bible/59/2TI.4.7.ESV",
    verseText: "I have fought the good fight, I have finished the race, I have kept the faith.",
    submissionText: ""
  }, // May 29
  {
    word: "glory",
    hint: "Romans 11",
    verse: "Romans 11:36",
    hintUrl: "https://www.bible.com/bible/59/ROM.11.ESV",
    verseUrl: "https://www.bible.com/bible/59/rom.11.36.ESV",
    verseText: "For from him and through him and to him are all things. To him be glory forever. Amen.",
    submissionText: "Thanks to Nate Platto for submitting this word!"
  }, // May 30
  {
    word: "flock",
    hint: "Psalm 77",
    verse: "Psalm 77:20",
    hintUrl: "https://www.bible.com/bible/59/PSA.77.ESV",
    verseUrl: "https://www.bible.com/bible/59/psa.77.20.ESV",
    verseText: "You led your people like a flock by the hand of Moses and Aaron.",
    submissionText: "Thanks to Chris Ehrhart for submitting this word!"
  }, // May 31
  {
    word: "bless",
    hint: "Numbers 6",
    verse: "Numbers 6:24",
    hintUrl: "https://www.bible.com/bible/59/NUM.6.ESV",
    verseUrl: "https://www.bible.com/bible/59/num.6.24.ESV",
    verseText: "The LORD bless you and keep you",
    submissionText: "Thanks to Tyler Jackson for submitting this word!"
  }, // June 1
  {
    word: "pluck",
    hint: "Matthew 12",
    verse: "Matthew 12:1",
    hintUrl: "https://www.bible.com/bible/59/MAT.12.ESV",
    verseUrl: "https://www.bible.com/bible/59/mat.12.1.ESV",
    verseText: "At that time Jesus went through the grainfields on the Sabbath. His disciples were hungry, and they began to pluck heads of grain and to eat.",
    submissionText: "Thanks to Sheeba Nencil for submitting this word!"
  }, // June 2
  {
    word: "wages",
    hint: "Romans 6",
    verse: "Romans 6:23",
    hintUrl: "https://www.bible.com/bible/59/ROM.6.ESV",
    verseUrl: "https://www.bible.com/bible/59/rom.6.23.ESV",
    verseText: "For the wages of sin is death, but the free gift of God is eternal life in Christ Jesus our Lord.",
    submissionText: ""
  }, // June 3
  {
    word: "stone",
    hint: "Luke 24",
    verse: "Luke 24:2",
    hintUrl: "https://www.bible.com/bible/59/LUK.24.ESV",
    verseUrl: "https://www.bible.com/bible/59/luk.24.2.ESV",
    verseText: "And they found the stone rolled away from the tomb",
    submissionText: "Thanks to FAS Middle School for submitting this word!"
  }, // June 4
  {
    word: "leave",
    hint: "Matthew 18",
    verse: "Matthew 18:12",
    hintUrl: "https://www.bible.com/bible/59/MAT.18.ESV",
    verseUrl: "https://www.bible.com/bible/59/mat.18.12.ESV",
    verseText: "What do you think? If a man has a hundred sheep, and one of them has gone astray, does he not leave the ninety-nine on the mountains and go in search of the one that went astray?",
    submissionText: ""
  }, // June 5
  {
    word: "untie",
    hint: "John 1",
    verse: "John 1:27",
    hintUrl: "https://www.bible.com/bible/59/JHN.1.ESV",
    verseUrl: "https://www.bible.com/bible/59/jhn.1.27.ESV",
    verseText: "“I baptize with water, but among you stands one you do not know, even he who comes after me, the strap of whose sandal I am not worthy to untie.”",
    submissionText: ""
  }, // June 6
  {
    word: "guard",
    hint: "Psalm 91",
    verse: "Psalm 91:11",
    hintUrl: "https://www.bible.com/bible/59/psa.91",
    verseUrl: "https://www.bible.com/bible/59/psa.91.11",
    verseText: "For he will command his angels concerning you to guard you in all your ways.",
    submissionText: ""
  }, // June 7
  {
    word: "saves",
    hint: "Psalm 34",
    verse: "Psalm 34:18",
    hintUrl: "https://www.bible.com/bible/59/psa.34",
    verseUrl: "https://www.bible.com/bible/59/psa.34.18",
    verseText: "The LORD is near to the brokenhearted and saves the crushed in spirit.",
    submissionText: ""
  }, // June 8
  {
    word: "faint",
    hint: "Proverbs 24",
    verse: "Proverbs 24:10",
    hintUrl: "https://www.bible.com/bible/59/pro.24",
    verseUrl: "https://www.bible.com/bible/59/pro.24.10",
    verseText: "If you faint in the day of adversity, your strength is small.",
    submissionText: ""
  }, // June 9
  {
    word: "lowly",
    hint: "Matthew 11",
    verse: "Matthew 11:29",
    hintUrl: "https://www.bible.com/bible/59/mat.11",
    verseUrl: "https://www.bible.com/bible/59/mat.11.29",
    verseText: "Take my yoke upon you, and learn from me, for I am gentle and lowly in heart, and you will find rest for your souls.",
    submissionText: ""
  }, // June 10
  {
    word: "wrong",
    hint: "Job 1",
    verse: "Job 1:22",
    hintUrl: "https://www.bible.com/bible/59/job.1",
    verseUrl: "https://www.bible.com/bible/59/job.1.22",
    verseText: "In all this Job did not sin or charge God with wrong.",
    submissionText: ""
  }, // June 11
  {
    word: "utter",
    hint: "Psalm 106",
    verse: "Psalm 106:2",
    hintUrl: "https://www.bible.com/bible/59/psa.106",
    verseUrl: "https://www.bible.com/bible/59/psa.106.2",
    verseText: "Who can utter the mighty deeds of the LORD, or declare all his praise?",
    submissionText: ""
  }, // June 12
  {
    word: "short",
    hint: "Romans 3",
    verse: "Romans 3:23",
    hintUrl: "https://www.bible.com/bible/59/rom.3",
    verseUrl: "https://www.bible.com/bible/59/rom.3.23",
    verseText: "For all have sinned and fall short of the glory of God.",
    submissionText: ""
  }, // June 13
  {
    word: "renew",
    hint: "Isaiah 40",
    verse: "Isaiah 40:31",
    hintUrl: "https://www.bible.com/bible/59/isa.40",
    verseUrl: "https://www.bible.com/bible/59/isa.40.31",
    verseText: "But they who wait for the LORD shall renew their strength; they shall mount up with wings like eagles; they shall run and not be weary; they shall walk and not faint.",
    submissionText: ""
  }, // June 14
  { // June 15
    "word": "asked",
    "hint": "1 Kings 3",
    "verse": "1 Kings 3:11-12",
    "hintUrl": "https://www.bible.com/bible/59/1KI.3",
    "verseUrl": "https://www.bible.com/bible/59/1KI.3.11-12",
    "verseText": "And God said to him, “Because you have asked this, and have not asked for yourself long life or riches or the life of your enemies, but have asked for yourself understanding to discern what is right, behold, I now do according to your word. Behold, I give you a wise and discerning mind, so that none like you has been before you and none like you shall arise after you.",
    "submissionText": ""
  },
  { // June 16
    "word": "holds",
    "hint": "Proverbs 29",
    "verse": "Proverbs 29:11",
    "hintUrl": "https://www.bible.com/bible/59/PRO.29",
    "verseUrl": "https://www.bible.com/bible/59/PRO.29.11",
    "verseText": "A fool gives full vent to his spirit, but a wise man quietly holds it back.",
    "submissionText": ""
  },
  { // June 17
    "word": "whole",
    "hint": "Mark 8",
    "verse": "Mark 8:36",
    "hintUrl": "https://www.bible.com/bible/59/MRK.8",
    "verseUrl": "https://www.bible.com/bible/59/MRK.8.36",
    "verseText": "For what does it profit a man to gain the whole world and forfeit his soul?",
    "submissionText": ""
  },
  { // June 18
    "word": "folly",
    "hint": "Proverbs 26",
    "verse": "Proverbs 26:4-5",
    "hintUrl": "https://www.bible.com/bible/59/PRO.26",
    "verseUrl": "https://www.bible.com/bible/59/PRO.26.4-5",
    "verseText": "Answer not a fool according to his folly, lest you be like him yourself. Answer a fool according to his folly, lest he be wise in his own eyes.",
    "submissionText": ""
  },
  { // June 19
    "word": "cloud",
    "hint": "Hebrews 12",
    "verse": "Hebrews 12:1",
    "hintUrl": "https://www.bible.com/bible/59/HEB.12",
    "verseUrl": "https://www.bible.com/bible/59/HEB.12.1",
    "verseText": "Therefore, since we are surrounded by so great a cloud of witnesses, let us also lay aside every weight, and sin which clings so closely, and let us run with endurance the race that is set before us,",
    "submissionText": ""
  },
  { // June 20
    "word": "lowly",
    "hint": "Matthew 11",
    "verse": "Matthew 11:29",
    "hintUrl": "https://www.bible.com/bible/59/MAT.11",
    "verseUrl": "https://www.bible.com/bible/59/MAT.11.29",
    "verseText": "Take my yoke upon you, and learn from me, for I am gentle and lowly in heart, and you will find rest for your souls.",
    "submissionText": ""
  },
  { // June 21
    "word": "earth",
    "hint": "Genesis 1",
    "verse": "Genesis 1:1",
    "hintUrl": "https://www.bible.com/bible/59/GEN.1",
    "verseUrl": "https://www.bible.com/bible/59/GEN.1.1",
    "verseText": "In the beginning, God created the heavens and the earth.",
    "submissionText": ""
  },
  { // June 22
    "word": "carry",
    "hint": "Isaiah 46",
    "verse": "Isaiah 46:4",
    "hintUrl": "https://www.bible.com/bible/59/ISA.46",
    "verseUrl": "https://www.bible.com/bible/59/ISA.46.4",
    "verseText": "even to your old age I am he, and to gray hairs I will carry you. I have made, and I will bear; I will carry and will save.",
    "submissionText": ""
  },
  { // June 23
    "word": "laban",
    "hint": "Genesis 28",
    "verse": "Genesis 28:5",
    "hintUrl": "https://www.bible.com/bible/59/GEN.28",
    "verseUrl": "https://www.bible.com/bible/59/GEN.28.5",
    "verseText": "Thus Isaac sent Jacob away. And he went to Paddan-aram, to Laban, the son of Bethuel the Aramean, the brother of Rebekah, Jacob’s and Esau’s mother.",
    "submissionText": ""
  },
  { // June 24
    "word": "arrow",
    "hint": "Psalm 64",
    "verse": "Psalm 64:7",
    "hintUrl": "https://www.bible.com/bible/59/PSA.64",
    "verseUrl": "https://www.bible.com/bible/59/PSA.64.7",
    "verseText": "But God shoots his arrow at them; they are wounded suddenly.",
    "submissionText": ""
  },
  { // June 25
    "word": "utter",
    "hint": "Psalm 106",
    "verse": "Psalm 106:2",
    "hintUrl": "https://www.bible.com/bible/59/PSA.106",
    "verseUrl": "https://www.bible.com/bible/59/PSA.106.2",
    "verseText": "Who can utter the mighty deeds of the LORD, or declare all his praise?",
    "submissionText": ""
  },
  { // June 26
    "word": "borne",
    "hint": "Isaiah 53",
    "verse": "Isaiah 53:4",
    "hintUrl": "https://www.bible.com/bible/59/ISA.53",
    "verseUrl": "https://www.bible.com/bible/59/ISA.53.4",
    "verseText": "Surely he has borne our griefs and carried our sorrows; yet we esteemed him stricken, smitten by God, and afflicted.",
    "submissionText": ""
  },
  { // June 27
    "word": "break",
    "hint": "Matthew 6",
    "verse": "Matthew 6:19-20",
    "hintUrl": "https://www.bible.com/bible/59/MAT.6",
    "verseUrl": "https://www.bible.com/bible/59/MAT.6.19-20",
    "verseText": "“Do not lay up for yourselves treasures on earth, where moth and rust destroy and where thieves break in and steal, but lay up for yourselves treasures in heaven, where neither moth nor rust destroys and where thieves do not break in and steal.",
    "submissionText": ""
  },
  { // June 28
    "word": "unite",
    "hint": "Psalm 86",
    "verse": "Psalm 86:11",
    "hintUrl": "https://www.bible.com/bible/59/PSA.86",
    "verseUrl": "https://www.bible.com/bible/59/PSA.86.11",
    "verseText": "Teach me your way, O LORD, that I may walk in your truth; unite my heart to fear your name.",
    "submissionText": ""
  },
  { // June 29
    "word": "judge",
    "hint": "John 7",
    "verse": "John 7:24",
    "hintUrl": "https://www.bible.com/bible/59/JHN.7",
    "verseUrl": "https://www.bible.com/bible/59/JHN.7.24",
    "verseText": "Do not judge by appearances, but judge with right judgment.”",
    "submissionText": ""
  },
  { // June 30
    "word": "Words",
    "hint": "Psalm 19",
    "verse": "Psalm 19:14",
    "hintUrl": "https://www.bible.com/bible/59/PSA.19",
    "verseUrl": "https://www.bible.com/bible/59/PSA.19.14",
    "verseText": "Let the words of my mouth and the meditation of my heart be acceptable in your sight, O LORD, my rock and my redeemer.",
    "submissionText": ""
  },
  { // July 1
    "word": "green",
    "hint": "Psalm 23",
    "verse": "Psalm 23:2",
    "hintUrl": "https://www.bible.com/bible/59/PSA.23",
    "verseUrl": "https://www.bible.com/bible/59/PSA.23.2",
    "verseText": "He makes me lie down in green pastures. He leads me beside still waters.",
    "submissionText": ""
  },
  { // July 2
    "word": "water",
    "hint": "John 4",
    "verse": "John 4:14",
    "hintUrl": "https://www.bible.com/bible/59/JHN.4",
    "verseUrl": "https://www.bible.com/bible/59/JHN.4.14",
    "verseText": "but whoever drinks of the water that I will give him will never be thirsty again. The water that I will give him will become in him a spring of water welling up to eternal life.”",
    "submissionText": ""
  },
  { // July 3
    "word": "apply",
    "hint": "Proverbs 23",
    "verse": "Proverbs 23:12",
    "hintUrl": "https://www.bible.com/bible/59/PRO.23",
    "verseUrl": "https://www.bible.com/bible/59/PRO.23.12",
    "verseText": "Apply your heart to instruction and your ear to words of knowledge.",
    "submissionText": ""
  },
  { // July 4
    "word": "aloes",
    "hint": "John 19",
    "verse": "John 19:39",
    "hintUrl": "https://www.bible.com/bible/59/JHN.19",
    "verseUrl": "https://www.bible.com/bible/59/JHN.19.39",
    "verseText": "Nicodemus also, who earlier had come to Jesus by night, came bringing a mixture of myrrh and aloes, about seventy-five pounds in weight.",
    "submissionText": ""
  },
  { // July 5
    "word": "image",
    "hint": "Genesis 1",
    "verse": "Genesis 1:27",
    "hintUrl": "https://www.bible.com/bible/59/GEN.1",
    "verseUrl": "https://www.bible.com/bible/59/GEN.1.27",
    "verseText": "So God created man in his own image, in the image of God he created him; male and female he created them.",
    "submissionText": ""
  },
  { // July 6
    "word": "alone",
    "hint": "Genesis 2",
    "verse": "Genesis 2:18",
    "hintUrl": "https://www.bible.com/bible/59/GEN.2",
    "verseUrl": "https://www.bible.com/bible/59/GEN.2.18",
    "verseText": "Then the LORD God said, “It is not good that the man should be alone; I will make him a helper fit for him.”",
    "submissionText": ""
  },
  { // July 7
    "word": "widow",
    "hint": "Psalm 146",
    "verse": "Psalm 146:9",
    "hintUrl": "https://www.bible.com/bible/59/PSA.146",
    "verseUrl": "https://www.bible.com/bible/59/PSA.146.9",
    "verseText": "The LORD watches over the sojourners; he upholds the widow and the fatherless, but the way of the wicked he brings to ruin.",
    "submissionText": ""
  },
  { // July 8
    "word": "judah",
    "hint": "Revelation 5",
    "verse": "Revelation 5:5",
    "hintUrl": "https://www.bible.com/bible/59/REV.5",
    "verseUrl": "https://www.bible.com/bible/59/REV.5.5",
    "verseText": "And one of the elders said to me, “Weep no more; behold, the Lion of the tribe of Judah, the Root of David, has conquered, so that he can open the scroll and its seven seals.”",
    "submissionText": ""
  },
  { // July 9
    "word": "camel",
    "hint": "Mark 10",
    "verse": "Mark 10:25",
    "hintUrl": "https://www.bible.com/bible/59/MRK.10",
    "verseUrl": "https://www.bible.com/bible/59/MRK.10.25",
    "verseText": "It is easier for a camel to go through the eye of a needle than for a rich person to enter the kingdom of God.”",
    "submissionText": ""
  },
  { // July 10
    "word": "gates",
    "hint": "Matthew 16",
    "verse": "Matthew 16:18",
    "hintUrl": "https://www.bible.com/bible/59/MAT.16",
    "verseUrl": "https://www.bible.com/bible/59/MAT.16.18",
    "verseText": "And I tell you, you are Peter, and on this rock I will build my church, and the gates of hell shall not prevail against it.",
    "submissionText": ""
  },
  { // July 11
    "word": "brook",
    "hint": "1 Kings 17",
    "verse": "1 Kings 17:4",
    "hintUrl": "https://www.bible.com/bible/59/1KI.17",
    "verseUrl": "https://www.bible.com/bible/59/1KI.17.4",
    "verseText": "You shall drink from the brook, and I have commanded the ravens to feed you there.”",
    "submissionText": ""
  },
  { // July 12
    "word": "knife",
    "hint": "Genesis 22",
    "verse": "Genesis 22:6",
    "hintUrl": "https://www.bible.com/bible/59/GEN.22",
    "verseUrl": "https://www.bible.com/bible/59/GEN.22.6",
    "verseText": "And Abraham took the wood of the burnt offering and laid it on Isaac his son. And he took in his hand the fire and the knife. So they went both of them together.",
    "submissionText": ""
  },
  { // July 13
    "word": "bound",
    "hint": "Proverbs 22",
    "verse": "Proverbs 22:15",
    "hintUrl": "https://www.bible.com/bible/59/PRO.22",
    "verseUrl": "https://www.bible.com/bible/59/PRO.22.15",
    "verseText": "Folly is bound up in the heart of a child, but the rod of discipline drives it far from him.",
    "submissionText": ""
  },
  { // July 14
    "word": "flies",
    "hint": "Ecclesiastes 10",
    "verse": "Ecclesiastes 10:1",
    "hintUrl": "https://www.bible.com/bible/59/ECC.10",
    "verseUrl": "https://www.bible.com/bible/59/ECC.10.1",
    "verseText": "Dead flies make the perfumer’s ointment give off a stench; so a little folly outweighs wisdom and honor.",
    "submissionText": ""
  },
  { // July 15
    "word": "exalt",
    "hint": "1 Peter 5",
    "verse": "1 Peter 5:6",
    "hintUrl": "https://www.bible.com/bible/59/1PE.5",
    "verseUrl": "https://www.bible.com/bible/59/1PE.5.6",
    "verseText": "Humble yourselves, therefore, under the mighty hand of God so that at the proper time he may exalt you,",
    "submissionText": ""
  },
  { // July 16
    "word": "fever",
    "hint": "Luke 4",
    "verse": "Luke 4:38-39",
    "hintUrl": "https://www.bible.com/bible/59/LUK.4",
    "verseUrl": "https://www.bible.com/bible/59/LUK.4.38-39",
    "verseText": "And he arose and left the synagogue and entered Simon’s house. Now Simon’s mother-in-law was ill with a high fever, and they appealed to him on her behalf. And he stood over her and rebuked the fever, and it left her, and immediately she rose and began to serve them.",
    "submissionText": ""
  },
  { // July 17
    "word": "hairs",
    "hint": "Matthew 10",
    "verse": "Matthew 10:30",
    "hintUrl": "https://www.bible.com/bible/59/MAT.10",
    "verseUrl": "https://www.bible.com/bible/59/MAT.10.30",
    "verseText": "But even the hairs of your head are all numbered.",
    "submissionText": ""
  },
  { // July 18
    "word": "inner",
    "hint": "2 Corinthians 4",
    "verse": "2 Corinthians 4:16",
    "hintUrl": "https://www.bible.com/bible/59/2Co.4",
    "verseUrl": "https://www.bible.com/bible/59/2Co.4.16",
    "verseText": "So we do not lose heart. Though our outer self is wasting away, our inner self is being renewed day by day.",
    "submissionText": ""
  },
  { // July 19
    "word": "quiet",
    "hint": "1 Timothy 2",
    "verse": "1 Timothy 2:1-2",
    "hintUrl": "https://www.bible.com/bible/59/1TI.2",
    "verseUrl": "https://www.bible.com/bible/59/1TI.2.1-2",
    "verseText": "First of all, then, I urge that supplications, prayers, intercessions, and thanksgivings be made for all people, for kings and all who are in high positions, that we may lead a peaceful and quiet life, godly and dignified in every way.",
    "submissionText": ""
  },
  { // July 20
    "word": "bride",
    "hint": "Revelation 21",
    "verse": "Revelation 21:2",
    "hintUrl": "https://www.bible.com/bible/59/REV.21",
    "verseUrl": "https://www.bible.com/bible/59/REV.21.2",
    "verseText": "And I saw the holy city, new Jerusalem, coming down out of heaven from God, prepared as a bride adorned for her husband.",
    "submissionText": ""
  },
  { // July 21
    "word": "frost",
    "hint": "Exodus 16",
    "verse": "Exodus 16:14",
    "hintUrl": "https://www.bible.com/bible/59/EXO.16",
    "verseUrl": "https://www.bible.com/bible/59/EXO.16.14",
    "verseText": "And when the dew had gone up, there was on the face of the wilderness a fine, flake-like thing, fine as frost on the ground.",
    "submissionText": ""
  },
  { // July 22
    "word": "watch",
    "hint": "Proverbs 15",
    "verse": "Proverbs 15:3",
    "hintUrl": "https://www.bible.com/bible/59/PRO.15",
    "verseUrl": "https://www.bible.com/bible/59/PRO.15.3",
    "verseText": "The eyes of the LORD are in every place, keeping watch on the evil and the good.",
    "submissionText": ""
  },
  { // July 23
    "word": "hours",
    "hint": "Acts 19",
    "verse": "Acts 19:34",
    "hintUrl": "https://www.bible.com/bible/59/ACT.19",
    "verseUrl": "https://www.bible.com/bible/59/ACT.19.34",
    "verseText": "But when they recognized that he was a Jew, for about two hours they all cried out with one voice, “Great is Artemis of the Ephesians!”",
    "submissionText": ""
  },
  { // July 23
    "word": "hours",
    "hint": "Acts 19",
    "verse": "Acts 19:34",
    "hintUrl": "https://www.bible.com/bible/59/ACT.19",
    "verseUrl": "https://www.bible.com/bible/59/ACT.19.34",
    "verseText": "But when they recognized that he was a Jew, for about two hours they all cried out with one voice, “Great is Artemis of the Ephesians!”",
    "submissionText": ""
  },
  { // July 24
    "word": "delay",
    "hint": "Psalm 40",
    "verse": "Psalm 40:17",
    "hintUrl": "https://www.bible.com/bible/59/PSA.40",
    "verseUrl": "https://www.bible.com/bible/59/PSA.40.17",
    "verseText": "As for me, I am poor and needy, but the Lord takes thought for me. You are my help and my deliverer; do not delay, O my God!",
    "submissionText": ""
  },
  { // July
    "word": "chaff",
    "hint": "Luke 3",
    "hintUrl": "https://www.bible.com/bible/59/LUK.3",
    "verse": "Luke 3:17",
    "verseUrl": "https://www.bible.com/bible/59/LUK.3.17",
    "verseText": "His winnowing fork is in his hand, to clear his threshing floor and to gather the wheat into his barn, but the chaff he will burn with unquenchable fire.”",
    "submissionText": ""
  },
  { // July
    "word": "yours",
    "hint": "Luke 22",
    "hintUrl": "https://www.bible.com/bible/59/LUK.22",
    "verse": "Luke 22:42",
    "verseUrl": "https://www.bible.com/bible/59/LUK.22.42",
    "verseText": "saying, “Father, if you are willing, remove this cup from me. Nevertheless, not my will, but yours, be done.”",
    "submissionText": ""
  },
  { // July
    "word": "woman",
    "hint": "Genesis 2",
    "hintUrl": "https://www.bible.com/bible/59/GEN.2",
    "verse": "Genesis 2:23",
    "verseUrl": "https://www.bible.com/bible/59/GEN.2.23",
    "verseText": "Then the man said, “This at last is bone of my bones and flesh of my flesh; she shall be called Woman, because she was taken out of Man.”",
    "submissionText": ""
  },
  { // July
    "word": "black",
    "hint": "Matthew 5",
    "hintUrl": "https://www.bible.com/bible/59/MAT.5",
    "verse": "Matthew 5:36",
    "verseUrl": "https://www.bible.com/bible/59/MAT.5.36",
    "verseText": "And do not take an oath by your head, for you cannot make one hair white or black.",
    "submissionText": ""
  },
  { // July
    "word": "judas",
    "hint": "Luke 22",
    "hintUrl": "https://www.bible.com/bible/59/LUK.22",
    "verse": "Luke 22:48",
    "verseUrl": "https://www.bible.com/bible/59/LUK.22.48",
    "verseText": "but Jesus said to him, “Judas, would you betray the Son of Man with a kiss?”",
    "submissionText": ""
  },
  { // July
    "word": "chest",
    "hint": "Revelation 1",
    "hintUrl": "https://www.bible.com/bible/59/REV.1",
    "verse": "Revelation 1:13",
    "verseUrl": "https://www.bible.com/bible/59/REV.1.13",
    "verseText": "and in the midst of the lampstands one like a son of man, clothed with a long robe and with a golden sash around his chest.",
    "submissionText": ""
  },
  { // July
    "word": "Harsh",
    "hint": "Proverbs 15",
    "hintUrl": "https://www.bible.com/bible/59/PRO.15",
    "verse": "Proverbs 15:1",
    "verseUrl": "https://www.bible.com/bible/59/PRO.15.1",
    "verseText": "A soft answer turns away wrath, but a harsh word stirs up anger.",
    "submissionText": ""
  },
  { // August 1
    "word": "avoid",
    "hint": "Titus 3",
    "hintUrl": "https://www.bible.com/bible/59/TIT.3",
    "verse": "Titus 3:9",
    "verseUrl": "https://www.bible.com/bible/59/TIT.3.9",
    "verseText": "But avoid foolish controversies, genealogies, dissensions, and quarrels about the law, for they are unprofitable and worthless.",
    "submissionText": ""
  },
  { // August
    "word": "bribe",
    "hint": "Deuteronomy 10",
    "hintUrl": "https://www.bible.com/bible/59/DEU.10",
    "verse": "Deuteronomy 10:17",
    "verseUrl": "https://www.bible.com/bible/59/DEU.10.17",
    "verseText": "For the LORD your God is God of gods and Lord of lords, the great, the mighty, and the awesome God, who is not partial and takes no bribe.",
    "submissionText": ""
  },
  { // August
    "word": "empty",
    "hint": "Colossians 2",
    "hintUrl": "https://www.bible.com/bible/59/COL.2",
    "verse": "Colossians 2:8",
    "verseUrl": "https://www.bible.com/bible/59/COL.2.8",
    "verseText": "See to it that no one takes you captive by philosophy and empty deceit, according to human tradition, according to the elemental spirits of the world, and not according to Christ.",
    "submissionText": ""
  },
  { // August
    "word": "weary",
    "hint": "Galatians 6",
    "hintUrl": "https://www.bible.com/bible/59/GAL.6",
    "verse": "Galatians 6:9",
    "verseUrl": "https://www.bible.com/bible/59/GAL.6.9",
    "verseText": "And let us not grow weary of doing good, for in due season we will reap, if we do not give up.",
    "submissionText": ""
  },
  { // August
    "word": "giver",
    "hint": "2 Corinthians 9",
    "hintUrl": "https://www.bible.com/bible/59/2CO.9",
    "verse": "2 Corinthians 9:7",
    "verseUrl": "https://www.bible.com/bible/59/2CO.9.7",
    "verseText": "Each one must give as he has decided in his heart, not reluctantly or under compulsion, for God loves a cheerful giver.",
    "submissionText": ""
  },
  { // August
    "word": "worse",
    "hint": "1 Timothy 5",
    "hintUrl": "https://www.bible.com/bible/59/1TI.5",
    "verse": "1 Timothy 5:8",
    "verseUrl": "https://www.bible.com/bible/59/1TI.5.8",
    "verseText": "But if anyone does not provide for his relatives, and especially for members of his household, he has denied the faith and is worse than an unbeliever.",
    "submissionText": ""
  },
  { // August
    "word": "guide",
    "hint": "Psalm 73",
    "hintUrl": "https://www.bible.com/bible/59/PSA.73",
    "verse": "Psalm 73:24",
    "verseUrl": "https://www.bible.com/bible/59/PSA.73.24",
    "verseText": "You guide me with your counsel, and afterward you will receive me to glory.",
    "submissionText": ""
  },
  { // August
    "word": "excel",
    "hint": "1 Corinthians 14",
    "hintUrl": "https://www.bible.com/bible/59/1CO.14",
    "verse": "1 Corinthians 14:12",
    "verseUrl": "https://www.bible.com/bible/59/1CO.14.12",
    "verseText": "So with yourselves, since you are eager for manifestations of the Spirit, strive to excel in building up the church.",
    "submissionText": ""
  },
  { // August
    "word": "david",
    "hint": "Acts 13",
    "hintUrl": "https://www.bible.com/bible/59/ACT.13",
    "verse": "Acts 13:22",
    "verseUrl": "https://www.bible.com/bible/59/ACT.13.22",
    "verseText": "And when he had removed him, he raised up David to be their king, of whom he testified and said, ‘I have found in David the son of Jesse a man after my heart, who will do all my will.’",
    "submissionText": ""
  },
  { // August 10
    "word": "baker",
    "hint": "Genesis 40",
    "hintUrl": "https://www.bible.com/bible/59/GEN.40",
    "verse": "Genesis 40:5",
    "verseUrl": "https://www.bible.com/bible/59/GEN.40.5",
    "verseText": "And one night they both dreamed—the cupbearer and the baker of the king of Egypt, who were confined in the prison—each his own dream, and each dream with its own interpretation.",
    "submissionText": ""
  },
  { // August
    "word": "godly",
    "hint": "2 Timothy 3",
    "hintUrl": "https://www.bible.com/bible/59/2TI.3",
    "verse": "2 Timothy 3:12",
    "verseUrl": "https://www.bible.com/bible/59/2TI.3.12",
    "verseText": "Indeed, all who desire to live a godly life in Christ Jesus will be persecuted,",
    "submissionText": ""
  },
  { // August
    "word": "broad",
    "hint": "Psalm 119",
    "hintUrl": "https://www.bible.com/bible/59/PSA.119",
    "verse": "Psalm 119:96",
    "verseUrl": "https://www.bible.com/bible/59/PSA.119.96",
    "verseText": "I have seen a limit to all perfection, but your commandment is exceedingly broad.",
    "submissionText": ""
  },
  { // August
    "word": "kneel",
    "hint": "Psalm 95",
    "hintUrl": "https://www.bible.com/bible/59/PSA.95",
    "verse": "Psalm 95:6",
    "verseUrl": "https://www.bible.com/bible/59/PSA.95.6",
    "verseText": "Oh come, let us worship and bow down; let us kneel before the LORD, our Maker!",
    "submissionText": ""
  },
  { // August
    "word": "wheat",
    "hint": "Luke 22",
    "hintUrl": "https://www.bible.com/bible/59/LUK.22",
    "verse": "Luke 22:31-32",
    "verseUrl": "https://www.bible.com/bible/59/LUK.22.31-32",
    "verseText": "“Simon, Simon, behold, Satan demanded to have you, that he might sift you like wheat, but I have prayed for you that your faith may not fail. And when you have turned again, strengthen your brothers.”",
    "submissionText": ""
  },
  { // August
    "word": "early",
    "hint": "Psalm 127",
    "hintUrl": "https://www.bible.com/bible/59/PSA.127",
    "verse": "Psalm 127:2",
    "verseUrl": "https://www.bible.com/bible/59/PSA.127.2",
    "verseText": "It is in vain that you rise up early and go late to rest, eating the bread of anxious toil; for he gives to his beloved sleep.",
    "submissionText": ""
  },
  { // August
    "word": "yield",
    "hint": "Habakkuk 3",
    "hintUrl": "https://www.bible.com/bible/59/HAB.3",
    "verse": "Habakkuk 3:17-18",
    "verseUrl": "https://www.bible.com/bible/59/HAB.3.17-18",
    "verseText": "Though the fig tree should not blossom, nor fruit be on the vines, the produce of the olive fail and the fields yield no food, the flock be cut off from the fold and there be no herd in the stalls, yet I will rejoice in the LORD; I will take joy in the God of my salvation.",
    "submissionText": ""
  },
  { // August
    "word": "books",
    "hint": "Revelation 20",
    "hintUrl": "https://www.bible.com/bible/59/REV.20",
    "verse": "Revelation 20:12",
    "verseUrl": "https://www.bible.com/bible/59/REV.20.12",
    "verseText": "And I saw the dead, great and small, standing before the throne, and books were opened. Then another book was opened, which is the book of life. And the dead were judged by what was written in the books, according to what they had done.",
    "submissionText": ""
  },
  { // August
    "word": "haste",
    "hint": "Luke 2",
    "hintUrl": "https://www.bible.com/bible/59/LUK.2",
    "verse": "Luke 2:16",
    "verseUrl": "https://www.bible.com/bible/59/LUK.2.16",
    "verseText": "And they went with haste and found Mary and Joseph, and the baby lying in a manger.",
    "submissionText": ""
  },
  { // August
    "word": "quake",
    "hint": "Nahum 1",
    "hintUrl": "https://www.bible.com/bible/59/NAM.1",
    "verse": "Nahum 1:5",
    "verseUrl": "https://www.bible.com/bible/59/NAM.1.5",
    "verseText": "The mountains quake before him; the hills melt; the earth heaves before him, the world and all who dwell in it.",
    "submissionText": ""
  },
  { // August 20
    "word": "fully",
    "hint": "1 Peter 1",
    "hintUrl": "https://www.bible.com/bible/59/1PE.1",
    "verse": "1 Peter 1:13",
    "verseUrl": "https://www.bible.com/bible/59/1PE.1.13",
    "verseText": "Therefore, preparing your minds for action, and being sober-minded, set your hope fully on the grace that will be brought to you at the revelation of Jesus Christ.",
    "submissionText": ""
  },
  { // August
    "word": "alarm",
    "hint": "Joel 2",
    "hintUrl": "https://www.bible.com/bible/59/JOL.2",
    "verse": "Joel 2:1",
    "verseUrl": "https://www.bible.com/bible/59/JOL.2.1",
    "verseText": "Blow a trumpet in Zion; sound an alarm on my holy mountain! Let all the inhabitants of the land tremble, for the day of the LORD is coming; it is near,",
    "submissionText": ""
  },
  { // August
    "word": "barns",
    "hint": "Luke 12",
    "hintUrl": "https://www.bible.com/bible/59/LUK.12",
    "verse": "Luke 12:18",
    "verseUrl": "https://www.bible.com/bible/59/LUK.12.18",
    "verseText": "And he said, ‘I will do this: I will tear down my barns and build larger ones, and there I will store all my grain and my goods.",
    "submissionText": ""
  },
  { // August
    "word": "false",
    "hint": "Exodus 20",
    "hintUrl": "https://www.bible.com/bible/59/EXO.20",
    "verse": "Exodus 20:16",
    "verseUrl": "https://www.bible.com/bible/59/EXO.20.16",
    "verseText": "“You shall not bear false witness against your neighbor.",
    "submissionText": ""
  },
  { // August
    "word": "creep",
    "hint": "Psalm 104",
    "hintUrl": "https://www.bible.com/bible/59/PSA.104",
    "verse": "Psalm 104:20",
    "verseUrl": "https://www.bible.com/bible/59/PSA.104.20",
    "verseText": "You make darkness, and it is night, when all the beasts of the forest creep about.",
    "submissionText": ""
  },
  { // August
    "word": "blood",
    "hint": "Ephesians 1",
    "hintUrl": "https://www.bible.com/bible/59/EPH.1",
    "verse": "Ephesians 1:7",
    "verseUrl": "https://www.bible.com/bible/59/EPH.1.7",
    "verseText": "In him we have redemption through his blood, the forgiveness of our trespasses, according to the riches of his grace,",
    "submissionText": ""
  },
  { // August
    "word": "chain",
    "hint": "Revelation 20",
    "hintUrl": "https://www.bible.com/bible/59/REV.20",
    "verse": "Revelation 20:1",
    "verseUrl": "https://www.bible.com/bible/59/REV.20.1",
    "verseText": "Then I saw an angel coming down from heaven, holding in his hand the key to the bottomless pit and a great chain.",
    "submissionText": ""
  },
  { // August
    "word": "hairy",
    "hint": "Genesis 25",
    "hintUrl": "https://www.bible.com/bible/59/GEN.25",
    "verse": "Genesis 25:25",
    "verseUrl": "https://www.bible.com/bible/59/GEN.25.25",
    "verseText": "The first came out red, all his body like a hairy cloak, so they called his name Esau.",
    "submissionText": ""
  },
  { // August
    "word": "goats",
    "hint": "Matthew 25",
    "hintUrl": "https://www.bible.com/bible/59/MAT.25",
    "verse": "Matthew 25:32",
    "verseUrl": "https://www.bible.com/bible/59/MAT.25.32",
    "verseText": "Before him will be gathered all the nations, and he will separate people one from another as a shepherd separates the sheep from the goats.",
    "submissionText": ""
  },
  { // August
    "word": "grant",
    "hint": "Ephesians 3",
    "hintUrl": "https://www.bible.com/bible/59/EPH.3",
    "verse": "Ephesians 3:16",
    "verseUrl": "https://www.bible.com/bible/59/EPH.3.16",
    "verseText": "that according to the riches of his glory he may grant you to be strengthened with power through his Spirit in your inner being,",
    "submissionText": ""
  },
  { // August 30
    "word": "bowed",
    "hint": "Romans 11",
    "hintUrl": "https://www.bible.com/bible/59/ROM.11",
    "verse": "Romans 11:4",
    "verseUrl": "https://www.bible.com/bible/59/ROM.11.4",
    "verseText": "But what is God’s reply to him? “I have kept for myself seven thousand men who have not bowed the knee to Baal.”",
    "submissionText": ""
  },
  { // September 1
    "word": "laden",
    "hint": "Matthew 11",
    "hintUrl": "https://www.bible.com/bible/59/MAT.11",
    "verse": "Matthew 11:28",
    "verseUrl": "https://www.bible.com/bible/59/MAT.11.28",
    "verseText": "Come to me, all who labor and are heavy laden, and I will give you rest.",
    "submissionText": ""
  },
  { // September
    "word": "court",
    "hint": "1 Corinthians 4",
    "hintUrl": "https://www.bible.com/bible/59/1Co.4",
    "verse": "1 Corinthians 4:3",
    "verseUrl": "https://www.bible.com/bible/59/1Co.4.3",
    "verseText": "But with me it is a very small thing that I should be judged by you or by any human court. In fact, I do not even judge myself.",
    "submissionText": ""
  },
  { // September
    "word": "grain",
    "hint": "Genesis 41",
    "hintUrl": "https://www.bible.com/bible/59/GEN.41",
    "verse": "Genesis 41:49",
    "verseUrl": "https://www.bible.com/bible/59/GEN.41.49",
    "verseText": "And Joseph stored up grain in great abundance, like the sand of the sea, until he ceased to measure it, for it could not be measured.",
    "submissionText": ""
  },
  { // September
    "word": "known",
    "hint": "John 15",
    "hintUrl": "https://www.bible.com/bible/59/JHN.15",
    "verse": "John 15:15",
    "verseUrl": "https://www.bible.com/bible/59/JHN.15.15",
    "verseText": "No longer do I call you servants, for the servant does not know what his master is doing; but I have called you friends, for all that I have heard from my Father I have made known to you.",
    "submissionText": ""
  },
  { // September
    "word": "daily",
    "hint": "Luke 9",
    "hintUrl": "https://www.bible.com/bible/59/LUK.9",
    "verse": "Luke 9:23",
    "verseUrl": "https://www.bible.com/bible/59/LUK.9.23",
    "verseText": "And he said to all, “If anyone would come after me, let him deny himself and take up his cross daily and follow me.",
    "submissionText": ""
  },
  { // September
    "word": "elect",
    "hint": "Romans 8",
    "hintUrl": "https://www.bible.com/bible/59/ROM.8",
    "verse": "Romans 8:33",
    "verseUrl": "https://www.bible.com/bible/59/ROM.8.33",
    "verseText": "Who shall bring any charge against God’s elect? It is God who justifies.",
    "submissionText": ""
  },
  { // September
    "word": "boils",
    "hint": "Exodus 9",
    "hintUrl": "https://www.bible.com/bible/59/EXO.9",
    "verse": "Exodus 9:11",
    "verseUrl": "https://www.bible.com/bible/59/EXO.9.11",
    "verseText": "And the magicians could not stand before Moses because of the boils, for the boils came upon the magicians and upon all the Egyptians.",
    "submissionText": ""
  },
  { // September
    "word": "boast",
    "hint": "Ephesians 2",
    "hintUrl": "https://www.bible.com/bible/59/EPH.2",
    "verse": "Ephesians 2:8-9",
    "verseUrl": "https://www.bible.com/bible/59/EPH.2.8-9",
    "verseText": "For by grace you have been saved through faith. And this is not your own doing; it is the gift of God, not a result of works, so that no one may boast.",
    "submissionText": ""
  },
  { // September
    "word": "build",
    "hint": "Psalm 127",
    "hintUrl": "https://www.bible.com/bible/59/PSA.127",
    "verse": "Psalm 127:1",
    "verseUrl": "https://www.bible.com/bible/59/PSA.127.1",
    "verseText": "Unless the LORD builds the house, those who build it labor in vain. Unless the LORD watches over the city, the watchman stays awake in vain.",
    "submissionText": ""
  },
  { // September 10
    "word": "grace",
    "hint": "1 Corinthians 15",
    "hintUrl": "https://www.bible.com/bible/59/1CO.15",
    "verse": "1 Corinthians 15:10",
    "verseUrl": "https://www.bible.com/bible/59/1CO.15.10",
    "verseText": "But by the grace of God I am what I am, and his grace toward me was not in vain. On the contrary, I worked harder than any of them, though it was not I, but the grace of God that is with me.",
    "submissionText": ""
  },
  { // September
    "word": "wrath",
    "hint": "John 3",
    "hintUrl": "https://www.bible.com/bible/59/JHN.3",
    "verse": "John 3:36",
    "verseUrl": "https://www.bible.com/bible/59/JHN.3.36",
    "verseText": "Whoever believes in the Son has eternal life; whoever does not obey the Son shall not see life, but the wrath of God remains on him.",
    "submissionText": ""
  },
  { // September
    "word": "drunk",
    "hint": "Ephesians 5",
    "hintUrl": "https://www.bible.com/bible/59/EPH.5",
    "verse": "Ephesians 5:18",
    "verseUrl": "https://www.bible.com/bible/59/EPH.5.18",
    "verseText": "And do not get drunk with wine, for that is debauchery, but be filled with the Spirit,",
    "submissionText": ""
  },
  { // September
    "word": "ghost",
    "hint": "Matthew 14",
    "hintUrl": "https://www.bible.com/bible/59/MAT.14",
    "verse": "Matthew 14:26",
    "verseUrl": "https://www.bible.com/bible/59/MAT.14.26",
    "verseText": "But when the disciples saw him walking on the sea, they were terrified, and said, “It is a ghost!” and they cried out in fear.",
    "submissionText": ""
  },
  { // September
    "word": "doers",
    "hint": "James 1",
    "hintUrl": "https://www.bible.com/bible/59/JAS.1",
    "verse": "James 1:22",
    "verseUrl": "https://www.bible.com/bible/59/JAS.1.22",
    "verseText": "But be doers of the word, and not hearers only, deceiving yourselves.",
    "submissionText": ""
  },
  { // September
    "word": "worms",
    "hint": "Acts 12",
    "hintUrl": "https://www.bible.com/bible/59/ACT.12",
    "verse": "Acts 12:23",
    "verseUrl": "https://www.bible.com/bible/59/ACT.12.23",
    "verseText": "Immediately an angel of the Lord struck him down, because he did not give God the glory, and he was eaten by worms and breathed his last.",
    "submissionText": ""
  },
  { // September
    "word": "gains",
    "hint": "Matthew 16",
    "hintUrl": "https://www.bible.com/bible/59/MAT.16",
    "verse": "Matthew 16:26",
    "verseUrl": "https://www.bible.com/bible/59/MAT.16.26",
    "verseText": "For what will it profit a man if he gains the whole world and forfeits his soul? Or what shall a man give in return for his soul?",
    "submissionText": ""
  },
  { // September
    "word": "angel",
    "hint": "Luke 2",
    "hintUrl": "https://www.bible.com/bible/59/LUK.2",
    "verse": "Luke 2:10",
    "verseUrl": "https://www.bible.com/bible/59/LUK.2.10",
    "verseText": "And the angel said to them, “Fear not, for behold, I bring you good news of great joy that will be for all the people.",
    "submissionText": ""
  },
  { // September
    "word": "enjoy",
    "hint": "1 Timothy 6",
    "hintUrl": "https://www.bible.com/bible/59/1TI.6",
    "verse": "1 Timothy 6:17",
    "verseUrl": "https://www.bible.com/bible/59/1TI.6.17",
    "verseText": "As for the rich in this present age, charge them not to be haughty, nor to set their hopes on the uncertainty of riches, but on God, who richly provides us with everything to enjoy.",
    "submissionText": ""
  },
  { // September
    "word": "walls",
    "hint": "Hebrews 11",
    "hintUrl": "https://www.bible.com/bible/59/HEB.11",
    "verse": "Hebrews 11:30",
    "verseUrl": "https://www.bible.com/bible/59/HEB.11.30",
    "verseText": "By faith the walls of Jericho fell down after they had been encircled for seven days.",
    "submissionText": ""
  },
  { // September 20
    "word": "loves",
    "hint": "1 John 4",
    "hintUrl": "https://www.bible.com/bible/59/1JN.4",
    "verse": "1 John 4:7",
    "verseUrl": "https://www.bible.com/bible/59/1JN.4.7",
    "verseText": "Beloved, let us love one another, for love is from God, and whoever loves has been born of God and knows God.",
    "submissionText": ""
  },
  { // September
    "word": "ashes",
    "hint": "Jonah 3",
    "hintUrl": "https://www.bible.com/bible/59/JON.3",
    "verse": "Jonah 3:6",
    "verseUrl": "https://www.bible.com/bible/59/JON.3.6",
    "verseText": "The word reached the king of Nineveh, and he arose from his throne, removed his robe, covered himself with sackcloth, and sat in ashes.",
    "submissionText": ""
  },
  { // September 22
    "word": "enoch",
    "hint": "Genesis 5",
    "hintUrl": "https://www.bible.com/bible/59/GEN.5",
    "verse": "Genesis 5:24",
    "verseUrl": "https://www.bible.com/bible/59/GEN.5.24",
    "verseText": "Enoch walked with God, and he was not, for God took him.",
    "submissionText": ""
  },
  { //
    "word": "cease",
    "hint": "Genesis 8",
    "hintUrl": "https://www.bible.com/bible/59/GEN.8",
    "verse": "Genesis 8:22",
    "verseUrl": "https://www.bible.com/bible/59/GEN.8.22",
    "verseText": "While the earth remains, seedtime and harvest, cold and heat, summer and winter, day and night, shall not cease.”",
    "submissionText": ""
  },
  { //
    "word": "dealt",
    "hint": "Psalm 13",
    "hintUrl": "https://www.bible.com/bible/59/PSA.13",
    "verse": "Psalm 13:6",
    "verseUrl": "https://www.bible.com/bible/59/PSA.13.6",
    "verseText": "I will sing to the LORD, because he has dealt bountifully with me.",
    "submissionText": ""
  },
  { //
    "word": "foxes",
    "hint": "Luke 9",
    "hintUrl": "https://www.bible.com/bible/59/LUK.9",
    "verse": "Luke 9:58",
    "verseUrl": "https://www.bible.com/bible/59/LUK.9.58",
    "verseText": "And Jesus said to him, “Foxes have holes, and birds of the air have nests, but the Son of Man has nowhere to lay his head.”",
    "submissionText": ""
  },
  { //
    "word": "wound",
    "hint": "Deuteronomy 32",
    "hintUrl": "https://www.bible.com/bible/59/DEU.32",
    "verse": "Deuteronomy 32:39",
    "verseUrl": "https://www.bible.com/bible/59/DEU.32.39",
    "verseText": "“‘See now that I, even I, am he, and there is no god beside me; I kill and I make alive; I wound and I heal; and there is none that can deliver out of my hand.",
    "submissionText": ""
  },
  { //
    "word": "babel",
    "hint": "Genesis 11",
    "hintUrl": "https://www.bible.com/bible/59/GEN.11",
    "verse": "Genesis 11:9",
    "verseUrl": "https://www.bible.com/bible/59/GEN.11.9",
    "verseText": "Therefore its name was called Babel, because there the LORD confused the language of all the earth. And from there the LORD dispersed them over the face of all the earth.",
    "submissionText": ""
  },
  { //
    "word": "jesse",
    "hint": "Romans 15",
    "hintUrl": "https://www.bible.com/bible/59/ROM.15",
    "verse": "Romans 15:12",
    "verseUrl": "https://www.bible.com/bible/59/ROM.15.12",
    "verseText": "And again Isaiah says, “The root of Jesse will come, even he who arises to rule the Gentiles; in him will the Gentiles hope.”",
    "submissionText": ""
  },
  { //
    "word": "jonah",
    "hint": "Jonah 2",
    "hintUrl": "https://www.bible.com/bible/59/JON.2",
    "verse": "Jonah 2:10",
    "verseUrl": "https://www.bible.com/bible/59/JON.2.10",
    "verseText": "And the LORD spoke to the fish, and it vomited Jonah out upon the dry land.",
    "submissionText": ""
  },
  { //
    "word": "harps",
    "hint": "Revelation 14",
    "hintUrl": "https://www.bible.com/bible/59/REV.14",
    "verse": "Revelation 14:2",
    "verseUrl": "https://www.bible.com/bible/59/REV.14.2",
    "verseText": "And I heard a voice from heaven like the roar of many waters and like the sound of loud thunder. The voice I heard was like the sound of harpists playing on their harps,",
    "submissionText": ""
  },
  { //
    "word": "ivory",
    "hint": "1 Kings 10",
    "hintUrl": "https://www.bible.com/bible/59/1KI.10",
    "verse": "1 Kings 10:18",
    "verseUrl": "https://www.bible.com/bible/59/1KI.10.18",
    "verseText": "The king also made a great ivory throne and overlaid it with the finest gold.",
    "submissionText": ""
  },
  { //
    "word": "guard",
    "hint": "Philippians 4",
    "hintUrl": "https://www.bible.com/bible/59/PHP.4",
    "verse": "Philippians 4:7",
    "verseUrl": "https://www.bible.com/bible/59/PHP.4.7",
    "verseText": "And the peace of God, which surpasses all understanding, will guard your hearts and your minds in Christ Jesus.",
    "submissionText": ""
  },
  { //
    "word": "guest",
    "hint": "Luke 19",
    "hintUrl": "https://www.bible.com/bible/59/LUK.19",
    "verse": "Luke 19:7",
    "verseUrl": "https://www.bible.com/bible/59/LUK.19.7",
    "verseText": "And when they saw it, they all grumbled, “He has gone in to be the guest of a man who is a sinner.”",
    "submissionText": ""
  },
  { //
    "word": "world",
    "hint": "Romans 12",
    "hintUrl": "https://www.bible.com/bible/59/ROM.12",
    "verse": "Romans 12:2",
    "verseUrl": "https://www.bible.com/bible/59/ROM.12.2",
    "verseText": "Do not be conformed to this world, but be transformed by the renewal of your mind, that by testing you may discern what is the will of God, what is good and acceptable and perfect.",
    "submissionText": ""
  },
  { //
    "word": "found",
    "hint": "John 1",
    "hintUrl": "https://www.bible.com/bible/59/JHN.1",
    "verse": "John 1:41",
    "verseUrl": "https://www.bible.com/bible/59/JHN.1.41",
    "verseText": "He first found his own brother Simon and said to him, “We have found the Messiah” (which means Christ).",
    "submissionText": ""
  },
  { //
    "word": "blade",
    "hint": "Judges 3",
    "hintUrl": "https://www.bible.com/bible/59/JUD.3",
    "verse": "Judges 3:22",
    "verseUrl": "https://www.bible.com/bible/59/JUD.3.22",
    "verseText": "And the hilt also went in after the blade, and the fat closed over the blade, for he did not pull the sword out of his belly; and the dung came out.",
    "submissionText": ""
  },
  { //
    "word": "idols",
    "hint": "1 John 5",
    "hintUrl": "https://www.bible.com/bible/59/1JO.5",
    "verse": "1 John 5:21",
    "verseUrl": "https://www.bible.com/bible/59/1JO.5.21",
    "verseText": "Little children, keep yourselves from idols.",
    "submissionText": ""
  },
  { //
    "word": "agree",
    "hint": "Mark 14",
    "hintUrl": "https://www.bible.com/bible/59/MRK.14",
    "verse": "Mark 14:56",
    "verseUrl": "https://www.bible.com/bible/59/MRK.14.56",
    "verseText": "For many bore false witness against him, but their testimony did not agree.",
    "submissionText": ""
  },
  { //
    "word": "enter",
    "hint": "Psalm 100",
    "hintUrl": "https://www.bible.com/bible/59/PSA.100",
    "verse": "Psalm 100:4",
    "verseUrl": "https://www.bible.com/bible/59/PSA.100.4",
    "verseText": "Enter his gates with thanksgiving, and his courts with praise! Give thanks to him; bless his name!",
    "submissionText": ""
  },
  { //
    "word": "fixed",
    "hint": "Luke 16",
    "hintUrl": "https://www.bible.com/bible/59/LUK.16",
    "verse": "Luke 16:26",
    "verseUrl": "https://www.bible.com/bible/59/LUK.16.26",
    "verseText": "And besides all this, between us and you a great chasm has been fixed, in order that those who would pass from here to you may not be able, and none may cross from there to us.’",
    "submissionText": ""
  },
  { //
    "word": "alien",
    "hint": "Psalm 69",
    "hintUrl": "https://www.bible.com/bible/59/PSA.69",
    "verse": "Psalm 69:8",
    "verseUrl": "https://www.bible.com/bible/59/PSA.69.8",
    "verseText": "I have become a stranger to my brothers, an alien to my mother’s sons.",
    "submissionText": ""
  },
  { // October 11th
    "word": "fiery",
    "hint": "Daniel 3",
    "hintUrl": "https://www.bible.com/bible/59/DAN.3",
    "verse": "Daniel 3:26",
    "verseUrl": "https://www.bible.com/bible/59/DAN.3.26",
    "verseText": "Then Nebuchadnezzar came near to the door of the burning fiery furnace; he declared, “Shadrach, Meshach, and Abednego, servants of the Most High God, come out, and come here!” Then Shadrach, Meshach, and Abednego came out from the fire.",
    "submissionText": ""
  },
  { //
    "word": "death",
    "hint": "1 Corinthians 15",
    "hintUrl": "https://www.bible.com/bible/59/1CO.15",
    "verse": "1 Corinthians 15:55",
    "verseUrl": "https://www.bible.com/bible/59/1CO.15.55",
    "verseText": "O death, where is your victory? O death, where is your sting?",
    "submissionText": ""
  },
  { //
    "word": "haman",
    "hint": "Esther 7",
    "hintUrl": "https://www.bible.com/bible/59/EST.7",
    "verse": "Esther 7:6",
    "verseUrl": "https://www.bible.com/bible/59/EST.7.6",
    "verseText": "And Esther said, “A foe and enemy! This wicked Haman!” Then Haman was terrified before the king and the queen.",
    "submissionText": ""
  },
  { //
    "word": "hasty",
    "hint": "Proverbs 29",
    "hintUrl": "https://www.bible.com/bible/59/PRO.29",
    "verse": "Proverbs 29:20",
    "verseUrl": "https://www.bible.com/bible/59/PRO.29.29",
    "verseText": "Do you see a man who is hasty in his words? There is more hope for a fool than for him.",
    "submissionText": ""
  },
  { //
    "word": "doing",
    "hint": "Psalm 118",
    "hintUrl": "https://www.bible.com/bible/59/PSA.118",
    "verse": "Psalm 118:22-23",
    "verseUrl": "https://www.bible.com/bible/59/PSA.118.22-23",
    "verseText": "The stone that the builders rejected has become the cornerstone. This is the LORD’s doing; it is marvelous in our eyes.",
    "submissionText": ""
  },
  { //
    "word": "caves",
    "hint": "Isaiah 2",
    "hintUrl": "https://www.bible.com/bible/59/ISA.2",
    "verse": "Isaiah 2:19",
    "verseUrl": "https://www.bible.com/bible/59/ISA.2.19",
    "verseText": "And people shall enter the caves of the rocks and the holes of the ground, from before the terror of the LORD, and from the splendor of his majesty, when he rises to terrify the earth.",
    "submissionText": ""
  },
  { //
    "word": "dream",
    "hint": "Genesis 37",
    "hintUrl": "https://www.bible.com/bible/59/GEN.37",
    "verse": "Genesis 37:9",
    "verseUrl": "https://www.bible.com/bible/59/GEN.37.9",
    "verseText": "Then he dreamed another dream and told it to his brothers and said, “Behold, I have dreamed another dream. Behold, the sun, the moon, and eleven stars were bowing down to me.”",
    "submissionText": ""
  },
  { // October 17th
    "word": "arose",
    "hint": "Exodus 1",
    "hintUrl": "https://www.bible.com/bible/59/EXO.1",
    "verse": "Exodus 1:8",
    "verseUrl": "https://www.bible.com/bible/59/EXO.1.8",
    "verseText": "Now there arose a new king over Egypt, who did not know Joseph.",
    "submissionText": ""
  },
  { //
    "word": "learn",
    "hint": "Matthew 9",
    "hintUrl": "https://www.bible.com/bible/59/MAT.9",
    "verse": "Matthew 9:13",
    "verseUrl": "https://www.bible.com/bible/59/MAT.9.13",
    "verseText": "Go and learn what this means: ‘I desire mercy, and not sacrifice.’ For I came not to call the righteous, but sinners.”",
    "submissionText": ""
  },
  { //
    "word": "cedar",
    "hint": "Psalm 92",
    "hintUrl": "https://www.bible.com/bible/59/PSA.92",
    "verse": "Psalm 92:12",
    "verseUrl": "https://www.bible.com/bible/59/PSA.92.12",
    "verseText": "The righteous flourish like the palm tree and grow like a cedar in Lebanon.",
    "submissionText": ""
  },
  { //
    "word": "cheek",
    "hint": "Matthew 5",
    "hintUrl": "https://www.bible.com/bible/59/MAT.5",
    "verse": "Matthew 5:39",
    "verseUrl": "https://www.bible.com/bible/59/MAT.5.39",
    "verseText": "But I say to you, Do not resist the one who is evil. But if anyone slaps you on the right cheek, turn to him the other also.",
    "submissionText": ""
  },
  { //
    "word": "lying",
    "hint": "Proverbs 12",
    "hintUrl": "https://www.bible.com/bible/59/PRO.12",
    "verse": "Proverbs 12:22",
    "verseUrl": "https://www.bible.com/bible/59/PRO.12.22",
    "verseText": "Lying lips are an abomination to the LORD, but those who act faithfully are his delight.",
    "submissionText": ""
  },
  { //
    "word": "wiser",
    "hint": "1 Corinthians 1",
    "hintUrl": "https://www.bible.com/bible/59/1CO.1",
    "verse": "1 Corinthians 1:25",
    "verseUrl": "https://www.bible.com/bible/59/1CO.1.25",
    "verseText": "For the foolishness of God is wiser than men, and the weakness of God is stronger than men.",
    "submissionText": ""
  },
  { //
    "word": "queen",
    "hint": "Esther 2",
    "hintUrl": "https://www.bible.com/bible/59/EST.2",
    "verse": "Esther 2:17",
    "verseUrl": "https://www.bible.com/bible/59/EST.2.17",
    "verseText": "the king loved Esther more than all the women, and she won grace and favor in his sight more than all the virgins, so that he set the royal crown on her head and made her queen instead of Vashti.",
    "submissionText": ""
  },
  { //
    "word": "dimly",
    "hint": "1 Corinthians 13",
    "hintUrl": "https://www.bible.com/bible/59/1CO.13",
    "verse": "1 Corinthians 13:12",
    "verseUrl": "https://www.bible.com/bible/59/1CO.13.12",
    "verseText": "For now we see in a mirror dimly, but then face to face. Now I know in part; then I shall know fully, even as I have been fully known.",
    "submissionText": ""
  },
  { //
    "word": "boats",
    "hint": "Luke 5",
    "hintUrl": "https://www.bible.com/bible/59/LUK.5",
    "verse": "Luke 5:11",
    "verseUrl": "https://www.bible.com/bible/59/LUK.5.11",
    "verseText": "And when they had brought their boats to land, they left everything and followed him.",
    "submissionText": ""
  },
  { //
    "word": "armor",
    "hint": "Ephesians 6",
    "hintUrl": "https://www.bible.com/bible/59/EPH.6",
    "verse": "Ephesians 6:11",
    "verseUrl": "https://www.bible.com/bible/59/EPH.6.11",
    "verseText": "Put on the whole armor of God, that you may be able to stand against the schemes of the devil.",
    "submissionText": ""
  },
  { //
    "word": "fools",
    "hint": "Proverbs 1",
    "hintUrl": "https://www.bible.com/bible/59/PRO.1",
    "verse": "Proverbs 1:7",
    "verseUrl": "https://www.bible.com/bible/59/PRO.1.7",
    "verseText": "The fear of the LORD is the beginning of knowledge; fools despise wisdom and instruction.",
    "submissionText": ""
  },
  { //
    "word": "deals",
    "hint": "Psalm 112",
    "hintUrl": "https://www.bible.com/bible/59/PSA.112",
    "verse": "Psalm 112:5",
    "verseUrl": "https://www.bible.com/bible/59/PSA.112.5",
    "verseText": "It is well with the man who deals generously and lends; who conducts his affairs with justice.",
    "submissionText": ""
  },
  { //
    "word": "angry",
    "hint": "Ephesians 4",
    "hintUrl": "https://www.bible.com/bible/59/EPH.4",
    "verse": "Ephesians 4:26",
    "verseUrl": "https://www.bible.com/bible/59/EPH.4.26",
    "verseText": "Be angry and do not sin; do not let the sun go down on your anger,",
    "submissionText": ""
  },
  { //
    "word": "herds",
    "hint": "Proverbs 27",
    "hintUrl": "https://www.bible.com/bible/59/PRO.27",
    "verse": "Proverbs 27:23",
    "verseUrl": "https://www.bible.com/bible/59/PRO.27.23",
    "verseText": "Know well the condition of your flocks, and give attention to your herds,",
    "submissionText": ""
  },
  { //
    "word": "jacob",
    "hint": "Genesis 32",
    "hintUrl": "https://www.bible.com/bible/59/GEN.32",
    "verse": "Genesis 32:28",
    "verseUrl": "https://www.bible.com/bible/59/GEN.32.28",
    "verseText": "Then he said, “Your name shall no longer be called Jacob, but Israel, for you have striven with God and with men, and have prevailed.”",
    "submissionText": ""
  },
  { //
    "word": "anger",
    "hint": "Proverbs 19",
    "hintUrl": "https://www.bible.com/bible/59/PRO.19",
    "verse": "Proverbs 19:11",
    "verseUrl": "https://www.bible.com/bible/59/PRO.19.11",
    "verseText": "Good sense makes one slow to anger, and it is his glory to overlook an offense.",
    "submissionText": ""
  },
  { //
    "word": "grass",
    "hint": "Isaiah 40",
    "hintUrl": "https://www.bible.com/bible/59/ISA.40",
    "verse": "Isaiah 40:8",
    "verseUrl": "https://www.bible.com/bible/59/ISA.40.8",
    "verseText": "The grass withers, the flower fades, but the word of our God will stand forever.",
    "submissionText": ""
  },
  { //
    "word": "above",
    "hint": "Colossians 3",
    "hintUrl": "https://www.bible.com/bible/59/COL.3",
    "verse": "Colossians 3:1",
    "verseUrl": "https://www.bible.com/bible/59/COL.3.1",
    "verseText": "If then you have been raised with Christ, seek the things that are above, where Christ is, seated at the right hand of God.",
    "submissionText": ""
  },
  { //
    "word": "flood",
    "hint": "Genesis 9",
    "hintUrl": "https://www.bible.com/bible/59/GEN.9",
    "verse": "Genesis 9:11",
    "verseUrl": "https://www.bible.com/bible/59/GEN.9.11",
    "verseText": "I establish my covenant with you, that never again shall all flesh be cut off by the waters of the flood, and never again shall there be a flood to destroy the earth.”",
    "submissionText": ""
  },
  { //
    "word": "grave",
    "hint": "Isaiah 53",
    "hintUrl": "https://www.bible.com/bible/59/ISA.53",
    "verse": "Isaiah 53:9",
    "verseUrl": "https://www.bible.com/bible/59/ISA.53.9",
    "verseText": "And they made his grave with the wicked and with a rich man in his death, although he had done no violence, and there was no deceit in his mouth.",
    "submissionText": ""
  },
  { //
    "word": "dried",
    "hint": "Joshua 4",
    "hintUrl": "https://www.bible.com/bible/59/JOS.4",
    "verse": "Joshua 4:23",
    "verseUrl": "https://www.bible.com/bible/59/JOS.4.23",
    "verseText": "For the LORD your God dried up the waters of the Jordan for you until you passed over, as the LORD your God did to the Red Sea, which he dried up for us until we passed over,",
    "submissionText": ""
  },
  { //
    "word": "cross",
    "hint": "1 Corinthians 1",
    "hintUrl": "https://www.bible.com/bible/59/1CO.1",
    "verse": "1 Corinthians 1:18",
    "verseUrl": "https://www.bible.com/bible/59/1CO.1.18",
    "verseText": "For the word of the cross is folly to those who are perishing, but to us who are being saved it is the power of God.",
    "submissionText": ""
  },
  { //
    "word": "visit",
    "hint": "James 1",
    "hintUrl": "https://www.bible.com/bible/59/JAS.1",
    "verse": "James 1:27",
    "verseUrl": "https://www.bible.com/bible/59/JAS.1.27",
    "verseText": "Religion that is pure and undefiled before God the Father is this: to visit orphans and widows in their affliction, and to keep oneself unstained from the world.",
    "submissionText": ""
  },
  { //
    "word": "lower",
    "hint": "Hebrews 2",
    "hintUrl": "https://www.bible.com/bible/59/HEB.2",
    "verse": "Hebrews 2:9",
    "verseUrl": "https://www.bible.com/bible/59/HEB.2.9",
    "verseText": "But we see him who for a little while was made lower than the angels, namely Jesus, crowned with glory and honor because of the suffering of death, so that by the grace of God he might taste death for everyone.",
    "submissionText": ""
  },
  { //
    "word": "fault",
    "hint": "Matthew 18",
    "hintUrl": "https://www.bible.com/bible/59/MAT.18",
    "verse": "Matthew 18:15",
    "verseUrl": "https://www.bible.com/bible/59/MAT.18.15",
    "verseText": "“If your brother sins against you, go and tell him his fault, between you and him alone. If he listens to you, you have gained your brother.",
    "submissionText": ""
  },
  { //
    "word": "gifts",
    "hint": "Matthew 7",
    "hintUrl": "https://www.bible.com/bible/59/MAT.7",
    "verse": "Matthew 7:11",
    "verseUrl": "https://www.bible.com/bible/59/MAT.7.11",
    "verseText": "If you then, who are evil, know how to give good gifts to your children, how much more will your Father who is in heaven give good things to those who ask him!",
    "submissionText": ""
  },
  { //
    "word": "baked",
    "hint": "Exodus 12",
    "hintUrl": "https://www.bible.com/bible/59/EXO.12",
    "verse": "Exodus 12:39",
    "verseUrl": "https://www.bible.com/bible/59/EXO.12.39",
    "verseText": "And they baked unleavened cakes of the dough that they had brought out of Egypt, for it was not leavened, because they were thrust out of Egypt and could not wait, nor had they prepared any provisions for themselves.",
    "submissionText": ""
  },
  { //
    "word": "money",
    "hint": "1 Timothy 6",
    "hintUrl": "https://www.bible.com/bible/59/1TI.6",
    "verse": "1 Timothy 6:10",
    "verseUrl": "https://www.bible.com/bible/59/1TI.6.10",
    "verseText": "For the love of money is a root of all kinds of evils. It is through this craving that some have wandered away from the faith and pierced themselves with many pangs.",
    "submissionText": ""
  },
  { //
    "word": "wings",
    "hint": "Psalm 36",
    "hintUrl": "https://www.bible.com/bible/59/PSA.36",
    "verse": "Psalm 36:7",
    "verseUrl": "https://www.bible.com/bible/59/PSA.36.7",
    "verseText": "How precious is your steadfast love, O God! The children of mankind take refuge in the shadow of your wings.",
    "submissionText": ""
  },
  { //
    "word": "greet",
    "hint": "2 Corinthians 13",
    "hintUrl": "https://www.bible.com/bible/59/2CO.13",
    "verse": "2 Corinthians 13:12",
    "verseUrl": "https://www.bible.com/bible/59/2CO.13.12",
    "verseText": "Greet one another with a holy kiss.",
    "submissionText": ""
  },
  { //
    "word": "equal",
    "hint": "John 5",
    "hintUrl": "https://www.bible.com/bible/59/JHN.5",
    "verse": "John 5:18",
    "verseUrl": "https://www.bible.com/bible/59/JHN.5.18",
    "verseText": "This was why the Jews were seeking all the more to kill him, because not only was he breaking the Sabbath, but he was even calling God his own Father, making himself equal with God.",
    "submissionText": ""
  },
  { //
    "word": "awake",
    "hint": "Psalm 17",
    "hintUrl": "https://www.bible.com/bible/59/PSA.17",
    "verse": "Psalm 17:15",
    "verseUrl": "https://www.bible.com/bible/59/PSA.17.15",
    "verseText": "As for me, I shall behold your face in righteousness; when I awake, I shall be satisfied with your likeness.",
    "submissionText": ""
  },
  { //
    "word": "abhor",
    "hint": "Romans 12",
    "hintUrl": "https://www.bible.com/bible/59/ROM.12",
    "verse": "Romans 12:9",
    "verseUrl": "https://www.bible.com/bible/59/ROM.12.9",
    "verseText": "Let love be genuine. Abhor what is evil; hold fast to what is good.",
    "submissionText": ""
  },
  { //
    "word": "wrong",
    "hint": "Job 1",
    "hintUrl": "https://www.bible.com/bible/59/JOB.1",
    "verse": "Job 1:22",
    "verseUrl": "https://www.bible.com/bible/59/JOB.1.22",
    "verseText": "In all this Job did not sin or charge God with wrong.",
    "submissionText": ""
  },
  { //
    "word": "unity",
    "hint": "Psalm 133",
    "hintUrl": "https://www.bible.com/bible/59/PSA.133",
    "verse": "Psalm 133:1",
    "verseUrl": "https://www.bible.com/bible/59/PSA.133.1",
    "verseText": "A Song of Ascents. Of David. Behold, how good and pleasant it is when brothers dwell in unity!",
    "submissionText": ""
  },
  { //
    "word": "count",
    "hint": "James 1",
    "hintUrl": "https://www.bible.com/bible/59/JAS.1",
    "verse": "James 1:2",
    "verseUrl": "https://www.bible.com/bible/59/JAS.1.2",
    "verseText": "Count it all joy, my brothers, when you meet trials of various kinds,",
    "submissionText": ""
  },
  { //
    "word": "purse",
    "hint": "Proverbs 1",
    "hintUrl": "https://www.bible.com/bible/59/PRO.1",
    "verse": "Proverbs 1:14",
    "verseUrl": "https://www.bible.com/bible/59/PRO.1.14",
    "verseText": "throw in your lot among us; we will all have one purse”—",
    "submissionText": ""
  },
  { //
    "word": "hosts",
    "hint": "Psalm 46",
    "hintUrl": "https://www.bible.com/bible/59/PSA.46",
    "verse": "Psalm 46:7",
    "verseUrl": "https://www.bible.com/bible/59/PSA.46.7",
    "verseText": "The LORD of hosts is with us; the God of Jacob is our fortress. Selah",
    "submissionText": ""
  },
  { //
    "word": "yoked",
    "hint": "2 Corinthians 6",
    "hintUrl": "https://www.bible.com/bible/59/2CO.6",
    "verse": "2 Corinthians 6:14",
    "verseUrl": "https://www.bible.com/bible/59/2CO.6.14",
    "verseText": "Do not be unequally yoked with unbelievers. For what partnership has righteousness with lawlessness? Or what fellowship has light with darkness?",
    "submissionText": ""
  },
  { //
    "word": "lamps",
    "hint": "Luke 12",
    "hintUrl": "https://www.bible.com/bible/59/LUK.12",
    "verse": "Luke 12:35",
    "verseUrl": "https://www.bible.com/bible/59/LUK.12.35",
    "verseText": "“Stay dressed for action and keep your lamps burning,",
    "submissionText": ""
  },
  { //
    "word": "flesh",
    "hint": "John 1",
    "hintUrl": "https://www.bible.com/bible/59/JHN.1",
    "verse": "John 1:14",
    "verseUrl": "https://www.bible.com/bible/59/JHN.1.14",
    "verseText": "And the Word became flesh and dwelt among us, and we have seen his glory, glory as of the only Son from the Father, full of grace and truth.",
    "submissionText": ""
  },
  { //
    "word": "crept",
    "hint": "Jude 1",
    "hintUrl": "https://www.bible.com/bible/59/JUD.1",
    "verse": "Jude 1:4",
    "verseUrl": "https://www.bible.com/bible/59/JUD.1.4",
    "verseText": "For certain people have crept in unnoticed who long ago were designated for this condemnation, ungodly people, who pervert the grace of our God into sensuality and deny our only Master and Lord, Jesus Christ.",
    "submissionText": ""
  },
  { //
    "word": "happy",
    "hint": "Genesis 30",
    "hintUrl": "https://www.bible.com/bible/59/GEN.30",
    "verse": "Genesis 30:13",
    "verseUrl": "https://www.bible.com/bible/59/GEN.30.13",
    "verseText": "And Leah said, “Happy am I! For women have called me happy.” So she called his name Asher.",
    "submissionText": ""
  },
  { //
    "word": "great",
    "hint": "Psalm 145",
    "hintUrl": "https://www.bible.com/bible/59/PSA.145",
    "verse": "Psalm 145:3",
    "verseUrl": "https://www.bible.com/bible/59/PSA.145.3",
    "verseText": "Great is the LORD, and greatly to be praised, and his greatness is unsearchable.",
    "submissionText": ""
  },
  { //
    "word": "honey",
    "hint": "Matthew 3",
    "hintUrl": "https://www.bible.com/bible/59/MAT.3",
    "verse": "Matthew 3:4",
    "verseUrl": "https://www.bible.com/bible/59/MAT.3.4",
    "verseText": "Now John wore a garment of camel’s hair and a leather belt around his waist, and his food was locusts and wild honey.",
    "submissionText": ""
  },
  { //
    "word": "fresh",
    "hint": "Judges 15",
    "hintUrl": "https://www.bible.com/bible/59/JDG.15",
    "verse": "Judges 15:15",
    "verseUrl": "https://www.bible.com/bible/59/JDG.15.15",
    "verseText": "And he found a fresh jawbone of a donkey, and put out his hand and took it, and with it he struck 1,000 men.",
    "submissionText": ""
  },
  { //
    "word": "charm",
    "hint": "Proverbs 31",
    "hintUrl": "https://www.bible.com/bible/59/PRO.31",
    "verse": "Proverbs 31:30",
    "verseUrl": "https://www.bible.com/bible/59/PRO.31.30",
    "verseText": "Charm is deceitful, and beauty is vain, but a woman who fears the LORD is to be praised.",
    "submissionText": ""
  },
  { //
    "word": "wages",
    "hint": "Romans 6",
    "hintUrl": "https://www.bible.com/bible/59/ROM.6",
    "verse": "Romans 6:23",
    "verseUrl": "https://www.bible.com/bible/59/ROM.6.23",
    "verseText": "For the wages of sin is death, but the free gift of God is eternal life in Christ Jesus our Lord.",
    "submissionText": ""
  },
  { //
    "word": "isaac",
    "hint": "Genesis 17",
    "hintUrl": "https://www.bible.com/bible/59/GEN.17",
    "verse": "Genesis 17:19",
    "verseUrl": "https://www.bible.com/bible/59/GEN.17.19",
    "verseText": "God said, “No, but Sarah your wife shall bear you a son, and you shall call his name Isaac. I will establish my covenant with him as an everlasting covenant for his offspring after him.",
    "submissionText": ""
  },
  { //
    "word": "evils",
    "hint": "Jeremiah 2",
    "hintUrl": "https://www.bible.com/bible/59/JER.2",
    "verse": "Jeremiah 2:13",
    "verseUrl": "https://www.bible.com/bible/59/JER.2.13",
    "verseText": "for my people have committed two evils: they have forsaken me, the fountain of living waters, and hewed out cisterns for themselves, broken cisterns that can hold no water.",
    "submissionText": ""
  },
  { //
    "word": "flash",
    "hint": "Job 41",
    "hintUrl": "https://www.bible.com/bible/59/JOB.41",
    "verse": "Job 41:18",
    "verseUrl": "https://www.bible.com/bible/59/JOB.41.18",
    "verseText": "His sneezings flash forth light, and his eyes are like the eyelids of the dawn.",
    "submissionText": ""
  },
  { // 
    "word": "lambs",
    "hint": "Luke 10",
    "hintUrl": "https://www.bible.com/bible/59/LUK.10",
    "verse": "Luke 10:3",
    "verseUrl": "https://www.bible.com/bible/59/LUK.10.3",
    "verseText": "Go your way; behold, I am sending you out as lambs in the midst of wolves.",
    "submissionText": ""
  },
  { // 
    "word": "large",
    "hint": "Mark 16",
    "hintUrl": "https://www.bible.com/bible/59/MRK.16",
    "verse": "Mark 16:4",
    "verseUrl": "https://www.bible.com/bible/59/MRK.16.4",
    "verseText": "And looking up, they saw that the stone had been rolled back—it was very large.",
    "submissionText": ""
  },
  { // 
    "word": "awoke",
    "hint": "Luke 8",
    "hintUrl": "https://www.bible.com/bible/59/LUK.8",
    "verse": "Luke 8:24",
    "verseUrl": "https://www.bible.com/bible/59/LUK.8.24",
    "verseText": "And they went and woke him, saying, “Master, Master, we are perishing!” And he awoke and rebuked the wind and the raging waves, and they ceased, and there was a calm.",
    "submissionText": ""
  },
  { // 
    "word": "force",
    "hint": "John 6",
    "hintUrl": "https://www.bible.com/bible/59/JHN.6",
    "verse": "John 6:15",
    "verseUrl": "https://www.bible.com/bible/59/JHN.6.15",
    "verseText": "Perceiving then that they were about to come and take him by force to make him king, Jesus withdrew again to the mountain by himself.",
    "submissionText": ""
  },
  { // 
    "word": "fitly",
    "hint": "Proverbs 25",
    "hintUrl": "https://www.bible.com/bible/59/PRO.25",
    "verse": "Proverbs 25:11",
    "verseUrl": "https://www.bible.com/bible/59/PRO.25.11",
    "verseText": "A word fitly spoken is like apples of gold in a setting of silver.",
    "submissionText": ""
  },
  { // 
    "word": "arise",
    "hint": "Mark 5",
    "hintUrl": "https://www.bible.com/bible/59/MRK.4",
    "verse": "Mark 5:41",
    "verseUrl": "https://www.bible.com/bible/59/MRK.4.41",
    "verseText": "Taking her by the hand he said to her, “Talitha cumi,” which means, “Little girl, I say to you, arise.”",
    "submissionText": ""
  },
  { // 
    "word": "cords",
    "hint": "Proverbs 5",
    "hintUrl": "https://www.bible.com/bible/59/PRO.5",
    "verse": "Proverbs 5:22",
    "verseUrl": "https://www.bible.com/bible/59/PRO.5.22",
    "verseText": "The iniquities of the wicked ensnare him, and he is held fast in the cords of his sin.",
    "submissionText": ""
  },
  { // 
    "word": "alter",
    "hint": "Psalm 89",
    "hintUrl": "https://www.bible.com/bible/59/PSA.89",
    "verse": "Psalm 89:34",
    "verseUrl": "https://www.bible.com/bible/59/PSA.89.34",
    "verseText": "I will not violate my covenant or alter the word that went forth from my lips.",
    "submissionText": ""
  },
  { // 
    "word": "fears",
    "hint": "Psalm 34",
    "hintUrl": "https://www.bible.com/bible/59/PSA.34",
    "verse": "Psalm 34:4",
    "verseUrl": "https://www.bible.com/bible/59/PSA.34.4",
    "verseText": "I sought the LORD, and he answered me and delivered me from all my fears.",
    "submissionText": ""
  },
  { // 
    "word": "herbs",
    "hint": "Proverbs 15",
    "hintUrl": "https://www.bible.com/bible/59/PRO.15",
    "verse": "Proverbs 15:17",
    "verseUrl": "https://www.bible.com/bible/59/PRO.15.17",
    "verseText": "Better is a dinner of herbs where love is than a fattened ox and hatred with it.",
    "submissionText": ""
  },
  { // 
    "word": "being",
    "hint": "Hebrews 10",
    "hintUrl": "https://www.bible.com/bible/59/HEB.10",
    "verse": "Hebrews 10:14",
    "verseUrl": "https://www.bible.com/bible/59/HEB.10.14",
    "verseText": "For by a single offering he has perfected for all time those who are being sanctified.",
    "submissionText": ""
  },
  { // 
    "word": "belly",
    "hint": "Jonah 1",
    "hintUrl": "https://www.bible.com/bible/59/JON.1",
    "verse": "Jonah 1:17",
    "verseUrl": "https://www.bible.com/bible/59/JON.1.17",
    "verseText": "And the LORD appointed a great fish to swallow up Jonah. And Jonah was in the belly of the fish three days and three nights.",
    "submissionText": ""
  },
  { // 
    "word": "glean",
    "hint": "Ruth 2",
    "hintUrl": "https://www.bible.com/bible/59/RTH.2",
    "verse": "Ruth 2:2",
    "verseUrl": "https://www.bible.com/bible/59/RTH.2.2",
    "verseText": "And Ruth the Moabite said to Naomi, “Let me go to the field and glean among the ears of grain after him in whose sight I shall find favor.” And she said to her, “Go, my daughter.”",
    "submissionText": ""
  },
  { // 
    "word": "tails",
    "hint": "Judges 15",
    "hintUrl": "https://www.bible.com/bible/59/JDG.15",
    "verse": "Judges 15:4",
    "verseUrl": "https://www.bible.com/bible/59/JDG.15.4",
    "verseText": "So Samson went and caught 300 foxes and took torches. And he turned them tail to tail and put a torch between each pair of tails.",
    "submissionText": ""
  },
  { // 
    "word": "clear",
    "hint": "Revelation 21",
    "hintUrl": "https://www.bible.com/bible/59/REV.21",
    "verse": "Revelation 21:18",
    "verseUrl": "https://www.bible.com/bible/59/REV.21.18",
    "verseText": "The wall was built of jasper, while the city was pure gold, like clear glass.",
    "submissionText": ""
  },
  { // 
    "word": "jewel",
    "hint": "Proverbs 20",
    "hintUrl": "https://www.bible.com/bible/59/PRO.20",
    "verse": "Proverbs 20:15",
    "verseUrl": "https://www.bible.com/bible/59/PRO.20.15",
    "verseText": "There is gold and abundance of costly stones, but the lips of knowledge are a precious jewel.",
    "submissionText": ""
  },
  { // 
    "word": "hills",
    "hint": "Psalm 121",
    "hintUrl": "https://www.bible.com/bible/59/PSA.121",
    "verse": "Psalm 121:1-2",
    "verseUrl": "https://www.bible.com/bible/59/PSA.121.1-2",
    "verseText": "I lift up my eyes to the hills. From where does my help come? My help comes from the LORD, who made heaven and earth.",
    "submissionText": ""
  },
  { // 
    "word": "hymns",
    "hint": "Colossians 3",
    "hintUrl": "https://www.bible.com/bible/59/COL.3",
    "verse": "Colossians 3:16",
    "verseUrl": "https://www.bible.com/bible/59/COL.3.16",
    "verseText": "Let the word of Christ dwell in you richly, teaching and admonishing one another in all wisdom, singing psalms and hymns and spiritual songs, with thankfulness in your hearts to God.",
    "submissionText": ""
  },
  { // 
    "word": "wiped",
    "hint": "John 12",
    "hintUrl": "https://www.bible.com/bible/59/JHN.12",
    "verse": "John 12:3",
    "verseUrl": "https://www.bible.com/bible/59/JHN.12.3",
    "verseText": "Mary therefore took a pound of expensive ointment made from pure nard, and anointed the feet of Jesus and wiped his feet with her hair. The house was filled with the fragrance of the perfume.",
    "submissionText": ""
  },
  { // 
    "word": "caleb",
    "hint": "Numbers 13",
    "hintUrl": "https://www.bible.com/bible/59/NUM.13",
    "verse": "Numbers 13:30",
    "verseUrl": "https://www.bible.com/bible/59/NUM.13.30",
    "verseText": "But Caleb quieted the people before Moses and said, “Let us go up at once and occupy it, for we are well able to overcome it.”",
    "submissionText": ""
  },
  { // 
    "word": "apart",
    "hint": "Psalm 16",
    "hintUrl": "https://www.bible.com/bible/59/PSA.16",
    "verse": "Psalm 16:2",
    "verseUrl": "https://www.bible.com/bible/59/PSA.16.2",
    "verseText": "I say to the LORD, “You are my Lord; I have no good apart from you.”",
    "submissionText": ""
  },
  { // 
    "word": "chose",
    "hint": "Ephesians 1",
    "hintUrl": "https://www.bible.com/bible/59/EPH.1",
    "verse": "Ephesians 1:4",
    "verseUrl": "https://www.bible.com/bible/59/EPH.1.4",
    "verseText": "even as he chose us in him before the foundation of the world, that we should be holy and blameless before him. In love",
    "submissionText": ""
  },
  { // 
    "word": "brood",
    "hint": "Luke 3",
    "hintUrl": "https://www.bible.com/bible/59/LUK.3",
    "verse": "Luke 3:7",
    "verseUrl": "https://www.bible.com/bible/59/LUK.3.7",
    "verseText": "He said therefore to the crowds that came out to be baptized by him, “You brood of vipers! Who warned you to flee from the wrath to come?",
    "submissionText": ""
  },
  { // 
    "word": "waves",
    "hint": "Psalm 89",
    "hintUrl": "https://www.bible.com/bible/59/PSA.89",
    "verse": "Psalm 89:9",
    "verseUrl": "https://www.bible.com/bible/59/PSA.89.9",
    "verseText": "You rule the raging of the sea; when its waves rise, you still them.",
    "submissionText": ""
  },
  { // 
    "word": "faith",
    "hint": "Hebrews 11",
    "hintUrl": "https://www.bible.com/bible/59/HEB.11",
    "verse": "Hebrews 11:1",
    "verseUrl": "https://www.bible.com/bible/59/HEB.11.1",
    "verseText": "Now faith is the assurance of things hoped for, the conviction of things not seen.",
    "submissionText": ""
  },
  { // 
    "word": "cared",
    "hint": "John 12",
    "hintUrl": "https://www.bible.com/bible/59/JHN.12",
    "verse": "John 12:6",
    "verseUrl": "https://www.bible.com/bible/59/JHN.12.6",
    "verseText": "He said this, not because he cared about the poor, but because he was a thief, and having charge of the moneybag he used to help himself to what was put into it.",
    "submissionText": ""
  },
  { // 
    "word": "hired",
    "hint": "Luke 15",
    "hintUrl": "https://www.bible.com/bible/59/LUK.15",
    "verse": "Luke 15:17",
    "verseUrl": "https://www.bible.com/bible/59/LUK.15.17",
    "verseText": "“But when he came to himself, he said, ‘How many of my father’s hired servants have more than enough bread, but I perish here with hunger!",
    "submissionText": ""
  },
  { // 
    "word": "cleft",
    "hint": "Exodus 33",
    "hintUrl": "https://www.bible.com/bible/59/EXO.33",
    "verse": "Exodus 33:22",
    "verseUrl": "https://www.bible.com/bible/59/EXO.33.22",
    "verseText": "and while my glory passes by I will put you in a cleft of the rock, and I will cover you with my hand until I have passed by.",
    "submissionText": ""
  },
  { // 
    "word": "event",
    "hint": "Ecclesiastes 2",
    "hintUrl": "https://www.bible.com/bible/59/ECC.2",
    "verse": "Ecclesiastes 2:14",
    "verseUrl": "https://www.bible.com/bible/59/ECC.2.14",
    "verseText": "The wise person has his eyes in his head, but the fool walks in darkness. And yet I perceived that the same event happens to all of them.",
    "submissionText": ""
  },
  { // 
    "word": "jubal",
    "hint": "Genesis 4",
    "hintUrl": "https://www.bible.com/bible/59/GEN.4",
    "verse": "Genesis 4:21",
    "verseUrl": "https://www.bible.com/bible/59/GEN.4.21",
    "verseText": "His brother’s name was Jubal; he was the father of all those who play the lyre and pipe.",
    "submissionText": ""
  },
  { // 
    "word": "flame",
    "hint": "Exodus 3",
    "hintUrl": "https://www.bible.com/bible/59/EXO.3",
    "verse": "Exodus 3:2",
    "verseUrl": "https://www.bible.com/bible/59/EXO.3.2",
    "verseText": "And the angel of the LORD appeared to him in a flame of fire out of the midst of a bush. He looked, and behold, the bush was burning, yet it was not consumed.",
    "submissionText": ""
  },
  {
    word: "fruit",
    verse: "John 15:5",
    verseUrl: "https://www.bible.com/bible/1/JHN.15.5.KJV",
    verseText: "I am the vine, ye are the branches: He that abideth in me, and I in him, the same bringeth forth much fruit: for without me ye can do nothing.",
    hint: "John 15",
    hintUrl: "https://www.bible.com/bible/1/JHN.15.KJV",
    submissionText: "Thank you Chad Adler for submitting this verse!"
  }, // April 18
  {
    word: "wiles",
    verse: "Ephesians 6:11",
    verseUrl: "https://www.bible.com/bible/1/EPH.6.11.KJV",
    verseText: "Put on the whole armour of God, that ye may be able to stand against the wiles of the devil.",
    hint: "Ephesians 6",
    hintUrl: "https://www.bible.com/bible/1/EPH.6.KJV",
  }, // April 19
  {
    word: "depth",
    verse: "Romans 8:39",
    verseUrl: "https://www.bible.com/bible/1/ROM.8.39.KJV",
    verseText: "Nor height, nor depth, nor any other creature, shall be able to separate us from the love of God, which is in Christ Jesus our Lord.",
    hint: "Romans 8",
    hintUrl: "https://www.bible.com/bible/1/ROM.8.KJV",
  }, // April 20
  {
    word: "steal",
    verse: "Matthew 6:20",
    verseUrl: "https://www.bible.com/bible/1/MAT.6.20.KJV",
    verseText: "But lay up for yourselves treasures in heaven, where neither moth nor rust doth corrupt, and where thieves do not break through nor steal",
    hint: "Matthew 6",
    hintUrl: "https://www.bible.com/bible/1/MAT.6.KJV",
  }, // April 21
  {
    word: "voice",
    verse: "Revelations 3:20",
    verseUrl: "https://www.bible.com/bible/1/REV.3.20.KJV",
    verseText: "Behold, I stand at the door, and knock: if any man hear my voice, and open the door, I will come in to him, and will sup with him, and he with me.",
    hint: "Revelations 3",
    hintUrl: "https://www.bible.com/bible/1/REV.3.KJV",
  }, // April 22
  {
    word: "agony",
    verse: "Luke 22:44",
    verseUrl: "https://www.bible.com/bible/1/LUK.22.44.KJV",
    verseText: "And being in an agony he prayed more earnestly: and his sweat was as it were great drops of blood falling down to the ground.",
    hint: "Luke 22",
    hintUrl: "https://www.bible.com/bible/1/LUK.22.KJV",
  }, // April 23
  {
    word: "exalt",
    verse: "1 Peter 5:6",
    verseUrl: "https://www.bible.com/bible/1/1PE.5.6.KJV",
    verseText: "Humble yourselves therefore under the mighty hand of God, that he may exalt you in due time",
    hint: "1 Peter 5",
    hintUrl: "https://www.bible.com/bible/1/1PE.5.KJV",
  }, // April 24
    {
    word: "giver",
    verse: "2 Corinthians 9:7",
    verseUrl: "https://www.bible.com/bible/59/2CO.9.7.ESV",
    verseText: "Each one must give as he has decided in his heart, not reluctantly or under compulsion, for God loves a cheerful giver.",
    hint: "2 Corinthians 9",
    hintUrl: "https://www.bible.com/bible/59/2CO.9.ESV",
  }, // April 25
    {
    word: "godly",
    verse: "2 Timothy 3:12",
    verseUrl: "https://www.bible.com/bible/59/2ti.3.12.ESV",
    verseText: "Indeed, all who desire to live a godly life in Christ Jesus will be persecuted.",
    hint: "2 Timothy 3",
    hintUrl: "https://www.bible.com/bible/59/2ti.3.ESV",
  }, // April 26
    {
    word: "fully",
    verse: "1 Peter 1:13",
    verseUrl: "https://www.bible.com/bible/59/1pe.1.13.ESV",
    verseText: "Therefore, preparing your minds for action, and being sober-minded, set your hope fully on the grace that will be brought to you at the revelation of Jesus Christ.",
    hint: "1 Peter 1",
    hintUrl: "https://www.bible.com/bible/59/1pe.1.ESV",
  }, // April 27
  {
    word: "happy",
    verse: "Genesis 30:13",
    verseUrl: "https://www.bible.com/bible/59/gen.30.13.ESV",
    verseText: "And Leah said, “Happy am I! For women have called me happy.” So she called his name Asher.",
    hint: "Genesis 30",
    hintUrl: "https://www.bible.com/bible/59/gen.30.ESV",
  }, // April 28
    {
    word: "hills",
    verse: "Psalm 121:1",
    verseUrl: "https://www.bible.com/bible/59/psa.121.1.ESV",
    verseText: "I lift up my eyes to the hills. From where does my help come?",
    hint: "Psalm 121",
    hintUrl: "https://www.bible.com/bible/59/psa.121.ESV",
  }, // April 29
    {
    word: "learn",
    verse: "Matthew 9:13",
    verseUrl: "https://www.bible.com/bible/59/mat.9.13.ESV",
    verseText: "Go and learn what this means: ‘I desire mercy, and not sacrifice.’ For I came not to call the righteous, but sinners.",
    hint: "Matthew 9",
    hintUrl: "https://www.bible.com/bible/59/mat.9.ESV",
  }, // April 30
    {
    word: "knees",
    verse: "Daniel 6:10",
    verseUrl: "https://www.bible.com/bible/59/dan.6.10.ESV",
    verseText: "When Daniel knew that the document had been signed, he went to his house where he had windows in his upper chamber open toward Jerusalem. He got down on his knees three times a day and prayed and gave thanks before his God, as he had done previously.",
    hint: "Daniel 6",
    hintUrl: "https://www.bible.com/bible/59/dan.6.ESV",
  }, // May 1
  {
    word: "aloud",
    verse: "Psalm 59:16",
    verseUrl: "https://www.bible.com/bible/59/psa.59.16.ESV",
    verseText: "But I will sing of your strength; I will sing aloud of your steadfast love in the morning. For you have been to me a fortress and a refuge in the day of my distress.",
    hint: "Psalm 59",
    hintUrl: "https://www.bible.com/bible/59/PSA.59.ESV",
  }, // May 2
  {
    word: "above",
    verse: "Colossians 3:1",
    verseUrl: "https://www.bible.com/bible/59/col.3.1.ESV",
    verseText: "If then you have been raised with Christ, seek the things that are above, where Christ is, seated at the right hand of God.",
    hint: "Colossians 3",
    hintUrl: "https://www.bible.com/bible/59/COL.3.ESV",
  }, // May 3
  {
    word: "baker",
    verse: "Genesis 40:5",
    verseUrl: "https://www.bible.com/bible/59/gen.40.5.ESV",
    verseText: "And one night they both dreamed—the cupbearer and the baker of the king of Egypt, who were confined in the prison—each his own dream, and each dream with its own interpretation.",
    hint: "Genesis 40",
    hintUrl: "https://www.bible.com/bible/59/GEN.40.ESV",
  }, // May 4
  {
    word: "loved",
    verse: "John 3:16",
    verseUrl: "https://www.bible.com/bible/59/jhn.3.16.ESV",
    verseText: "For God so loved the world, that he gave his only Son, that whoever believes in him should not perish but have eternal life.",
    hint: "John 3",
    hintUrl: "https://www.bible.com/bible/59/JHN.3.ESV",
  }, // May 5
  {
    word: "lands",
    verse: "Matthew 19:29",
    verseUrl: "https://www.bible.com/bible/59/mat.19.29.ESV",
    verseText: "And everyone who has left houses or brothers or sisters or father or mother or children or lands, for my name’s sake, will receive a hundredfold and will inherit eternal life.",
    hint: "Matthew 19",
    hintUrl: "https://www.bible.com/bible/59/MAT.19.ESV",
  }, // May 6
  {
    word: "laden",
    verse: "Matthew 11:28",
    verseUrl: "https://www.bible.com/bible/59/mat.11.28.ESV",
    verseText: "Come to me, all who labor and are heavy laden, and I will give you rest.",
    hint: "Matthew 11",
    hintUrl: "https://www.bible.com/bible/59/MAT.11.ESV",
  }, // May 5
  {
    word: "Jesus",
    verse: "Matthew 1:21",
    verseUrl: "https://www.bible.com/bible/59/mat.1.21.ESV",
    verseText: "She will bear a son, and you shall call his name Jesus, for he will save his people from their sins.",
    hint: "Matthew 1",
    hintUrl: "https://www.bible.com/bible/59/MAT.1.ESV",
  }, // May 8
  {
    word: "honey",
    verse: "Matthew 3:4",
    verseUrl: "https://www.bible.com/bible/59/mat.3.4.ESV",
    verseText: "Now John wore a garment of camel’s hair and a leather belt around his waist, and his food was locusts and wild honey.",
    hint: "Matthew 3",
    hintUrl: "https://www.bible.com/bible/59/MAT.3.ESV",
  }, // May 9
  {
    word: "guest",
    verse: "Luke 19:7",
    verseUrl: "https://www.bible.com/bible/59/luk.19.7.ESV",
    verseText: "And when they saw it, they all grumbled, “He has gone in to be the guest of a man who is a sinner.”",
    hint: "Luke 19",
    hintUrl: "https://www.bible.com/bible/59/LUK.19.ESV",
  }, // May 10
  {
    word: "touch",
    hint: "Matthew 9",
    verse: "Matthew 9:21",
    hintUrl: "https://www.bible.com/bible/59/mat.9.ESV",
    verseUrl: "https://www.bible.com/bible/59/mat.9.21.ESV",
    verseText: "For she said to herself, If I only touch his garment, I will be made well.",
    submissionText: "Thanks to Chuck Schlie for submitting this word!"
  }, // May 11
  {
    word: "whole",
    hint: "1 Thessalonians 5",
    verse: "1 Thessalonians 5:23",
    hintUrl: "https://www.bible.com/bible/59/1TH.5.ESV",
    verseUrl: "https://www.bible.com/bible/59/1TH.5.23.ESV",
    verseText: "Now may the God of peace himself sanctify you completely, and may your whole spirit and soul and body be kept blameless at the coming of our Lord Jesus Christ.",
    submissionText: "Thanks to Nate Platto for submitting this word!"
  }, // May 12
  {
    word: "blind",
    hint: "John 9",
    verse: "John 9:25",
    hintUrl: "https://www.bible.com/bible/59/JHN.9.ESV",
    verseUrl: "https://www.bible.com/bible/59/JHN.9.25.ESV",
    verseText: "He answered, Whether he is a sinner I do not know. One thing I do know, that though I was blind, now I see.",
    submissionText: ""
  }, // May 13
  {
    word: "mourn",
    hint: "Matthew 5",
    verse: "Matthew 5:4",
    hintUrl: "https://www.bible.com/bible/59/MAT.5.ESV",
    verseUrl: "https://www.bible.com/bible/59/MAT.5.4.ESV",
    verseText: "Blessed are those who mourn, for they shall be comforted.",
    submissionText: "Thanks to Kylie M for submitting this word!"
  }, // May 14
  {
    word: "reign",
    hint: "Exodus 15",
    verse: "Exodus 15:18",
    hintUrl: "https://www.bible.com/bible/59/EXO.15.ESV",
    verseUrl: "https://www.bible.com/bible/59/EXO.15.18.ESV",
    verseText: "The LORD will reign forever and ever.",
    submissionText: ""
  }, // May 15
  {
    word: "moses",
    hint: "John 3",
    verse: "John 3:14",
    hintUrl: "https://www.bible.com/bible/59/JHN.3.ESV",
    verseUrl: "https://www.bible.com/bible/59/JHN.3.14.ESV",
    verseText: "And as Moses lifted up the serpent in the wilderness, so must the Son of Man be lifted up",
    submissionText: ""
  }, // May 16
  {
    word: "alpha",
    hint: "Revelation 22",
    verse: "Revelation 22:13",
    hintUrl: "https://www.bible.com/bible/59/REV.22.ESV",
    verseUrl: "https://www.bible.com/bible/59/REV.22.13.ESV",
    verseText: "I am the Alpha and the Omega, the first and the last, the beginning and the end.",
    submissionText: ""
  }, // May 17
  {
    word: "peter",
    hint: "Acts 3",
    verse: "Acts 3:6",
    hintUrl: "https://www.bible.com/bible/59/ACT.3.ESV",
    verseUrl: "https://www.bible.com/bible/59/ACT.3.6.ESV",
    verseText: "But Peter said, I have no silver and gold, but what I do have I give to you. In the name of Jesus Christ of Nazareth, rise up and walk!",
    submissionText: "Thanks to Kim Ragland for submitting this word!"
  }, // May 18
  {
    word: "right",
    hint: "1 Peter 3",
    verse: "1 Peter 3:22",
    hintUrl: "https://www.bible.com/bible/59/1PE.3.ESV",
    verseUrl: "https://www.bible.com/bible/59/1PE.3.22.ESV",
    verseText: "Who has gone into heaven and is at the right hand of God, with angels, authorities, and powers having been subjected to him.",
    submissionText: ""
  }, // May 19
  {
    word: "bless",
    hint: "Romans 12",
    verse: "Romans 12:14",
    hintUrl: "https://www.bible.com/bible/59/ROM.12.ESV",
    verseUrl: "https://www.bible.com/bible/59/ROM.12.14.ESV",
    verseText: "Bless those who persecute you; bless and do not curse them.",
    submissionText: ""
  }, // May 20
  {
    word: "laden",
    hint: "Matthew 11",
    verse: "Matthew 11:28",
    hintUrl: "https://www.bible.com/bible/59/MAT.11.ESV",
    verseUrl: "https://www.bible.com/bible/59/MAT.11.28.ESV",
    verseText: "Come to me, all who labor and are heavy laden, and I will give you rest.",
    submissionText: "Thanks to Lesley Squire for submitting this word!"
  }, // May 21
  {
    word: "voice",
    hint: "Exodus 19",
    verse: "Exodus 19:5",
    hintUrl: "https://www.bible.com/bible/59/EXO.19.ESV",
    verseUrl: "https://www.bible.com/bible/59/EXO.19.5.ESV",
    verseText: "Now therefore, if you will indeed obey my voice and keep my covenant, you shall be my treasured possession among all peoples, for all the earth is mine",
    submissionText: "Thanks to Blytdina for submitting this word!"
  }, // May 22
  {
    word: "dream",
    hint: "Acts 2",
    verse: "Acts 2:17",
    hintUrl: "https://www.bible.com/bible/59/ACT.2.ESV",
    verseUrl: "https://www.bible.com/bible/59/ACT.2.17.ESV",
    verseText: "And in the last days it shall be, God declares, that I will pour out my Spirit on all flesh, and your sons and your daughters shall prophesy, and your young men shall see visions, and your old men shall dream dreams",
    submissionText: ""
  }, // May 23
  {
    word: "sheep",
    hint: "Matthew 18",
    verse: "Matthew 18:12",
    hintUrl: "https://www.bible.com/bible/59/MAT.18.ESV",
    verseUrl: "https://www.bible.com/bible/59/MAT.18.12.ESV",
    verseText: "What do you think? If a man has a hundred sheep, and one of them has gone astray, does he not leave the ninety-nine on the mountains and go in search of the one that went astray?",
    submissionText: ""
  }, // May 24
  {
    word: "power",
    hint: "2 Timothy 1",
    verse: "2 Timothy 1:7",
    hintUrl: "https://www.bible.com/bible/59/2TI.1.ESV",
    verseUrl: "https://www.bible.com/bible/59/2TI.1.7.ESV",
    verseText: "For God gave us a spirit not of fear but of power and love and self-control.",
    submissionText: "Thanks to Leyton Curran for submitting this word!"
  }, // May 25
  {
    word: "begin",
    hint: "Luke 21",
    verse: "Luke 21:28",
    hintUrl: "https://www.bible.com/bible/59/LUK.21.ESV",
    verseUrl: "https://www.bible.com/bible/59/LUK.21.28.ESV",
    verseText: "Now when these things begin to take place, straighten up and raise your heads, because your redemption is drawing near.",
    submissionText: ""
  }, // May 26
  {
    word: "birds",
    hint: "Matthew 6",
    verse: "Matthew 6:26",
    hintUrl: "https://www.bible.com/bible/59/MAT.6.ESV",
    verseUrl: "https://www.bible.com/bible/59/MAT.6.26.ESV",
    verseText: "Look at the birds of the air: they neither sow nor reap nor gather into barns, and yet your heavenly Father feeds them. Are you not of more value than they?",
    submissionText: ""
  }, // May 27
  {
    word: "doubt",
    hint: "Matthew 14",
    verse: "Matthew 14:31",
    hintUrl: "https://www.bible.com/bible/59/MAT.14.ESV",
    verseUrl: "https://www.bible.com/bible/59/MAT.14.31.ESV",
    verseText: "Jesus immediately reached out his hand and took hold of him, saying to him, O you of little faith, why did you doubt?",
    submissionText: ""
  }, // May 28
  {
    word: "fight",
    hint: "2 Timothy 4",
    verse: "2 Timothy 4:7",
    hintUrl: "https://www.bible.com/bible/59/2TI.4.ESV",
    verseUrl: "https://www.bible.com/bible/59/2TI.4.7.ESV",
    verseText: "I have fought the good fight, I have finished the race, I have kept the faith.",
    submissionText: ""
  }, // May 29
  {
    word: "glory",
    hint: "Romans 11",
    verse: "Romans 11:36",
    hintUrl: "https://www.bible.com/bible/59/ROM.11.ESV",
    verseUrl: "https://www.bible.com/bible/59/rom.11.36.ESV",
    verseText: "For from him and through him and to him are all things. To him be glory forever. Amen.",
    submissionText: "Thanks to Nate Platto for submitting this word!"
  }, // May 30
  {
    word: "flock",
    hint: "Psalm 77",
    verse: "Psalm 77:20",
    hintUrl: "https://www.bible.com/bible/59/PSA.77.ESV",
    verseUrl: "https://www.bible.com/bible/59/psa.77.20.ESV",
    verseText: "You led your people like a flock by the hand of Moses and Aaron.",
    submissionText: "Thanks to Chris Ehrhart for submitting this word!"
  }, // May 31
  {
    word: "bless",
    hint: "Numbers 6",
    verse: "Numbers 6:24",
    hintUrl: "https://www.bible.com/bible/59/NUM.6.ESV",
    verseUrl: "https://www.bible.com/bible/59/num.6.24.ESV",
    verseText: "The LORD bless you and keep you",
    submissionText: "Thanks to Tyler Jackson for submitting this word!"
  }, // June 1
  {
    word: "pluck",
    hint: "Matthew 12",
    verse: "Matthew 12:1",
    hintUrl: "https://www.bible.com/bible/59/MAT.12.ESV",
    verseUrl: "https://www.bible.com/bible/59/mat.12.1.ESV",
    verseText: "At that time Jesus went through the grainfields on the Sabbath. His disciples were hungry, and they began to pluck heads of grain and to eat.",
    submissionText: "Thanks to Sheeba Nencil for submitting this word!"
  }, // June 2
  {
    word: "wages",
    hint: "Romans 6",
    verse: "Romans 6:23",
    hintUrl: "https://www.bible.com/bible/59/ROM.6.ESV",
    verseUrl: "https://www.bible.com/bible/59/rom.6.23.ESV",
    verseText: "For the wages of sin is death, but the free gift of God is eternal life in Christ Jesus our Lord.",
    submissionText: ""
  }, // June 3
  {
    word: "stone",
    hint: "Luke 24",
    verse: "Luke 24:2",
    hintUrl: "https://www.bible.com/bible/59/LUK.24.ESV",
    verseUrl: "https://www.bible.com/bible/59/luk.24.2.ESV",
    verseText: "And they found the stone rolled away from the tomb",
    submissionText: "Thanks to FAS Middle School for submitting this word!"
  }, // June 4
  {
    word: "leave",
    hint: "Matthew 18",
    verse: "Matthew 18:12",
    hintUrl: "https://www.bible.com/bible/59/MAT.18.ESV",
    verseUrl: "https://www.bible.com/bible/59/mat.18.12.ESV",
    verseText: "What do you think? If a man has a hundred sheep, and one of them has gone astray, does he not leave the ninety-nine on the mountains and go in search of the one that went astray?",
    submissionText: ""
  }, // June 5
  {
    word: "untie",
    hint: "John 1",
    verse: "John 1:27",
    hintUrl: "https://www.bible.com/bible/59/JHN.1.ESV",
    verseUrl: "https://www.bible.com/bible/59/jhn.1.27.ESV",
    verseText: "“I baptize with water, but among you stands one you do not know, even he who comes after me, the strap of whose sandal I am not worthy to untie.”",
    submissionText: ""
  }, // June 6
  {
    word: "guard",
    hint: "Psalm 91",
    verse: "Psalm 91:11",
    hintUrl: "https://www.bible.com/bible/59/psa.91",
    verseUrl: "https://www.bible.com/bible/59/psa.91.11",
    verseText: "For he will command his angels concerning you to guard you in all your ways.",
    submissionText: ""
  }, // June 7
  {
    word: "saves",
    hint: "Psalm 34",
    verse: "Psalm 34:18",
    hintUrl: "https://www.bible.com/bible/59/psa.34",
    verseUrl: "https://www.bible.com/bible/59/psa.34.18",
    verseText: "The LORD is near to the brokenhearted and saves the crushed in spirit.",
    submissionText: ""
  }, // June 8
  {
    word: "faint",
    hint: "Proverbs 24",
    verse: "Proverbs 24:10",
    hintUrl: "https://www.bible.com/bible/59/pro.24",
    verseUrl: "https://www.bible.com/bible/59/pro.24.10",
    verseText: "If you faint in the day of adversity, your strength is small.",
    submissionText: ""
  }, // June 9
  {
    word: "lowly",
    hint: "Matthew 11",
    verse: "Matthew 11:29",
    hintUrl: "https://www.bible.com/bible/59/mat.11",
    verseUrl: "https://www.bible.com/bible/59/mat.11.29",
    verseText: "Take my yoke upon you, and learn from me, for I am gentle and lowly in heart, and you will find rest for your souls.",
    submissionText: ""
  }, // June 10
  {
    word: "wrong",
    hint: "Job 1",
    verse: "Job 1:22",
    hintUrl: "https://www.bible.com/bible/59/job.1",
    verseUrl: "https://www.bible.com/bible/59/job.1.22",
    verseText: "In all this Job did not sin or charge God with wrong.",
    submissionText: ""
  }, // June 11
  {
    word: "utter",
    hint: "Psalm 106",
    verse: "Psalm 106:2",
    hintUrl: "https://www.bible.com/bible/59/psa.106",
    verseUrl: "https://www.bible.com/bible/59/psa.106.2",
    verseText: "Who can utter the mighty deeds of the LORD, or declare all his praise?",
    submissionText: ""
  }, // June 12
  {
    word: "short",
    hint: "Romans 3",
    verse: "Romans 3:23",
    hintUrl: "https://www.bible.com/bible/59/rom.3",
    verseUrl: "https://www.bible.com/bible/59/rom.3.23",
    verseText: "For all have sinned and fall short of the glory of God.",
    submissionText: ""
  }, // June 13
  {
    word: "renew",
    hint: "Isaiah 40",
    verse: "Isaiah 40:31",
    hintUrl: "https://www.bible.com/bible/59/isa.40",
    verseUrl: "https://www.bible.com/bible/59/isa.40.31",
    verseText: "But they who wait for the LORD shall renew their strength; they shall mount up with wings like eagles; they shall run and not be weary; they shall walk and not faint.",
    submissionText: ""
  }, // June 14
  { // June 15
    "word": "asked",
    "hint": "1 Kings 3",
    "verse": "1 Kings 3:11-12",
    "hintUrl": "https://www.bible.com/bible/59/1KI.3",
    "verseUrl": "https://www.bible.com/bible/59/1KI.3.11-12",
    "verseText": "And God said to him, “Because you have asked this, and have not asked for yourself long life or riches or the life of your enemies, but have asked for yourself understanding to discern what is right, behold, I now do according to your word. Behold, I give you a wise and discerning mind, so that none like you has been before you and none like you shall arise after you.",
    "submissionText": ""
  },
  { // June 16
    "word": "holds",
    "hint": "Proverbs 29",
    "verse": "Proverbs 29:11",
    "hintUrl": "https://www.bible.com/bible/59/PRO.29",
    "verseUrl": "https://www.bible.com/bible/59/PRO.29.11",
    "verseText": "A fool gives full vent to his spirit, but a wise man quietly holds it back.",
    "submissionText": ""
  },
  { // June 17
    "word": "whole",
    "hint": "Mark 8",
    "verse": "Mark 8:36",
    "hintUrl": "https://www.bible.com/bible/59/MRK.8",
    "verseUrl": "https://www.bible.com/bible/59/MRK.8.36",
    "verseText": "For what does it profit a man to gain the whole world and forfeit his soul?",
    "submissionText": ""
  },
  { // June 18
    "word": "folly",
    "hint": "Proverbs 26",
    "verse": "Proverbs 26:4-5",
    "hintUrl": "https://www.bible.com/bible/59/PRO.26",
    "verseUrl": "https://www.bible.com/bible/59/PRO.26.4-5",
    "verseText": "Answer not a fool according to his folly, lest you be like him yourself. Answer a fool according to his folly, lest he be wise in his own eyes.",
    "submissionText": ""
  },
  { // June 19
    "word": "cloud",
    "hint": "Hebrews 12",
    "verse": "Hebrews 12:1",
    "hintUrl": "https://www.bible.com/bible/59/HEB.12",
    "verseUrl": "https://www.bible.com/bible/59/HEB.12.1",
    "verseText": "Therefore, since we are surrounded by so great a cloud of witnesses, let us also lay aside every weight, and sin which clings so closely, and let us run with endurance the race that is set before us,",
    "submissionText": ""
  },
  { // June 20
    "word": "lowly",
    "hint": "Matthew 11",
    "verse": "Matthew 11:29",
    "hintUrl": "https://www.bible.com/bible/59/MAT.11",
    "verseUrl": "https://www.bible.com/bible/59/MAT.11.29",
    "verseText": "Take my yoke upon you, and learn from me, for I am gentle and lowly in heart, and you will find rest for your souls.",
    "submissionText": ""
  },
  { // June 21
    "word": "earth",
    "hint": "Genesis 1",
    "verse": "Genesis 1:1",
    "hintUrl": "https://www.bible.com/bible/59/GEN.1",
    "verseUrl": "https://www.bible.com/bible/59/GEN.1.1",
    "verseText": "In the beginning, God created the heavens and the earth.",
    "submissionText": ""
  },
  { // June 22
    "word": "carry",
    "hint": "Isaiah 46",
    "verse": "Isaiah 46:4",
    "hintUrl": "https://www.bible.com/bible/59/ISA.46",
    "verseUrl": "https://www.bible.com/bible/59/ISA.46.4",
    "verseText": "even to your old age I am he, and to gray hairs I will carry you. I have made, and I will bear; I will carry and will save.",
    "submissionText": ""
  },
  { // June 23
    "word": "laban",
    "hint": "Genesis 28",
    "verse": "Genesis 28:5",
    "hintUrl": "https://www.bible.com/bible/59/GEN.28",
    "verseUrl": "https://www.bible.com/bible/59/GEN.28.5",
    "verseText": "Thus Isaac sent Jacob away. And he went to Paddan-aram, to Laban, the son of Bethuel the Aramean, the brother of Rebekah, Jacob’s and Esau’s mother.",
    "submissionText": ""
  },
  { // June 24
    "word": "arrow",
    "hint": "Psalm 64",
    "verse": "Psalm 64:7",
    "hintUrl": "https://www.bible.com/bible/59/PSA.64",
    "verseUrl": "https://www.bible.com/bible/59/PSA.64.7",
    "verseText": "But God shoots his arrow at them; they are wounded suddenly.",
    "submissionText": ""
  },
  { // June 25
    "word": "utter",
    "hint": "Psalm 106",
    "verse": "Psalm 106:2",
    "hintUrl": "https://www.bible.com/bible/59/PSA.106",
    "verseUrl": "https://www.bible.com/bible/59/PSA.106.2",
    "verseText": "Who can utter the mighty deeds of the LORD, or declare all his praise?",
    "submissionText": ""
  },
  { // June 26
    "word": "borne",
    "hint": "Isaiah 53",
    "verse": "Isaiah 53:4",
    "hintUrl": "https://www.bible.com/bible/59/ISA.53",
    "verseUrl": "https://www.bible.com/bible/59/ISA.53.4",
    "verseText": "Surely he has borne our griefs and carried our sorrows; yet we esteemed him stricken, smitten by God, and afflicted.",
    "submissionText": ""
  },
  { // June 27
    "word": "break",
    "hint": "Matthew 6",
    "verse": "Matthew 6:19-20",
    "hintUrl": "https://www.bible.com/bible/59/MAT.6",
    "verseUrl": "https://www.bible.com/bible/59/MAT.6.19-20",
    "verseText": "“Do not lay up for yourselves treasures on earth, where moth and rust destroy and where thieves break in and steal, but lay up for yourselves treasures in heaven, where neither moth nor rust destroys and where thieves do not break in and steal.",
    "submissionText": ""
  },
  { // June 28
    "word": "unite",
    "hint": "Psalm 86",
    "verse": "Psalm 86:11",
    "hintUrl": "https://www.bible.com/bible/59/PSA.86",
    "verseUrl": "https://www.bible.com/bible/59/PSA.86.11",
    "verseText": "Teach me your way, O LORD, that I may walk in your truth; unite my heart to fear your name.",
    "submissionText": ""
  },
  { // June 29
    "word": "judge",
    "hint": "John 7",
    "verse": "John 7:24",
    "hintUrl": "https://www.bible.com/bible/59/JHN.7",
    "verseUrl": "https://www.bible.com/bible/59/JHN.7.24",
    "verseText": "Do not judge by appearances, but judge with right judgment.”",
    "submissionText": ""
  },
  { // June 30
    "word": "Words",
    "hint": "Psalm 19",
    "verse": "Psalm 19:14",
    "hintUrl": "https://www.bible.com/bible/59/PSA.19",
    "verseUrl": "https://www.bible.com/bible/59/PSA.19.14",
    "verseText": "Let the words of my mouth and the meditation of my heart be acceptable in your sight, O LORD, my rock and my redeemer.",
    "submissionText": ""
  },
  { // July 1
    "word": "green",
    "hint": "Psalm 23",
    "verse": "Psalm 23:2",
    "hintUrl": "https://www.bible.com/bible/59/PSA.23",
    "verseUrl": "https://www.bible.com/bible/59/PSA.23.2",
    "verseText": "He makes me lie down in green pastures. He leads me beside still waters.",
    "submissionText": ""
  },
  { // July 2
    "word": "water",
    "hint": "John 4",
    "verse": "John 4:14",
    "hintUrl": "https://www.bible.com/bible/59/JHN.4",
    "verseUrl": "https://www.bible.com/bible/59/JHN.4.14",
    "verseText": "but whoever drinks of the water that I will give him will never be thirsty again. The water that I will give him will become in him a spring of water welling up to eternal life.”",
    "submissionText": ""
  },
  { // July 3
    "word": "apply",
    "hint": "Proverbs 23",
    "verse": "Proverbs 23:12",
    "hintUrl": "https://www.bible.com/bible/59/PRO.23",
    "verseUrl": "https://www.bible.com/bible/59/PRO.23.12",
    "verseText": "Apply your heart to instruction and your ear to words of knowledge.",
    "submissionText": ""
  },
  { // July 4
    "word": "aloes",
    "hint": "John 19",
    "verse": "John 19:39",
    "hintUrl": "https://www.bible.com/bible/59/JHN.19",
    "verseUrl": "https://www.bible.com/bible/59/JHN.19.39",
    "verseText": "Nicodemus also, who earlier had come to Jesus by night, came bringing a mixture of myrrh and aloes, about seventy-five pounds in weight.",
    "submissionText": ""
  },
  { // July 5
    "word": "image",
    "hint": "Genesis 1",
    "verse": "Genesis 1:27",
    "hintUrl": "https://www.bible.com/bible/59/GEN.1",
    "verseUrl": "https://www.bible.com/bible/59/GEN.1.27",
    "verseText": "So God created man in his own image, in the image of God he created him; male and female he created them.",
    "submissionText": ""
  },
  { // July 6
    "word": "alone",
    "hint": "Genesis 2",
    "verse": "Genesis 2:18",
    "hintUrl": "https://www.bible.com/bible/59/GEN.2",
    "verseUrl": "https://www.bible.com/bible/59/GEN.2.18",
    "verseText": "Then the LORD God said, “It is not good that the man should be alone; I will make him a helper fit for him.”",
    "submissionText": ""
  },
  { // July 7
    "word": "widow",
    "hint": "Psalm 146",
    "verse": "Psalm 146:9",
    "hintUrl": "https://www.bible.com/bible/59/PSA.146",
    "verseUrl": "https://www.bible.com/bible/59/PSA.146.9",
    "verseText": "The LORD watches over the sojourners; he upholds the widow and the fatherless, but the way of the wicked he brings to ruin.",
    "submissionText": ""
  },
  { // July 8
    "word": "judah",
    "hint": "Revelation 5",
    "verse": "Revelation 5:5",
    "hintUrl": "https://www.bible.com/bible/59/REV.5",
    "verseUrl": "https://www.bible.com/bible/59/REV.5.5",
    "verseText": "And one of the elders said to me, “Weep no more; behold, the Lion of the tribe of Judah, the Root of David, has conquered, so that he can open the scroll and its seven seals.”",
    "submissionText": ""
  },
  { // July 9
    "word": "camel",
    "hint": "Mark 10",
    "verse": "Mark 10:25",
    "hintUrl": "https://www.bible.com/bible/59/MRK.10",
    "verseUrl": "https://www.bible.com/bible/59/MRK.10.25",
    "verseText": "It is easier for a camel to go through the eye of a needle than for a rich person to enter the kingdom of God.”",
    "submissionText": ""
  },
  { // July 10
    "word": "gates",
    "hint": "Matthew 16",
    "verse": "Matthew 16:18",
    "hintUrl": "https://www.bible.com/bible/59/MAT.16",
    "verseUrl": "https://www.bible.com/bible/59/MAT.16.18",
    "verseText": "And I tell you, you are Peter, and on this rock I will build my church, and the gates of hell shall not prevail against it.",
    "submissionText": ""
  },
  { // July 11
    "word": "brook",
    "hint": "1 Kings 17",
    "verse": "1 Kings 17:4",
    "hintUrl": "https://www.bible.com/bible/59/1KI.17",
    "verseUrl": "https://www.bible.com/bible/59/1KI.17.4",
    "verseText": "You shall drink from the brook, and I have commanded the ravens to feed you there.”",
    "submissionText": ""
  },
  { // July 12
    "word": "knife",
    "hint": "Genesis 22",
    "verse": "Genesis 22:6",
    "hintUrl": "https://www.bible.com/bible/59/GEN.22",
    "verseUrl": "https://www.bible.com/bible/59/GEN.22.6",
    "verseText": "And Abraham took the wood of the burnt offering and laid it on Isaac his son. And he took in his hand the fire and the knife. So they went both of them together.",
    "submissionText": ""
  },
  { // July 13
    "word": "bound",
    "hint": "Proverbs 22",
    "verse": "Proverbs 22:15",
    "hintUrl": "https://www.bible.com/bible/59/PRO.22",
    "verseUrl": "https://www.bible.com/bible/59/PRO.22.15",
    "verseText": "Folly is bound up in the heart of a child, but the rod of discipline drives it far from him.",
    "submissionText": ""
  },
  { // July 14
    "word": "flies",
    "hint": "Ecclesiastes 10",
    "verse": "Ecclesiastes 10:1",
    "hintUrl": "https://www.bible.com/bible/59/ECC.10",
    "verseUrl": "https://www.bible.com/bible/59/ECC.10.1",
    "verseText": "Dead flies make the perfumer’s ointment give off a stench; so a little folly outweighs wisdom and honor.",
    "submissionText": ""
  },
  { // July 15
    "word": "exalt",
    "hint": "1 Peter 5",
    "verse": "1 Peter 5:6",
    "hintUrl": "https://www.bible.com/bible/59/1PE.5",
    "verseUrl": "https://www.bible.com/bible/59/1PE.5.6",
    "verseText": "Humble yourselves, therefore, under the mighty hand of God so that at the proper time he may exalt you,",
    "submissionText": ""
  },
  { // July 16
    "word": "fever",
    "hint": "Luke 4",
    "verse": "Luke 4:38-39",
    "hintUrl": "https://www.bible.com/bible/59/LUK.4",
    "verseUrl": "https://www.bible.com/bible/59/LUK.4.38-39",
    "verseText": "And he arose and left the synagogue and entered Simon’s house. Now Simon’s mother-in-law was ill with a high fever, and they appealed to him on her behalf. And he stood over her and rebuked the fever, and it left her, and immediately she rose and began to serve them.",
    "submissionText": ""
  },
  { // July 17
    "word": "hairs",
    "hint": "Matthew 10",
    "verse": "Matthew 10:30",
    "hintUrl": "https://www.bible.com/bible/59/MAT.10",
    "verseUrl": "https://www.bible.com/bible/59/MAT.10.30",
    "verseText": "But even the hairs of your head are all numbered.",
    "submissionText": ""
  },
  { // July 18
    "word": "inner",
    "hint": "2 Corinthians 4",
    "verse": "2 Corinthians 4:16",
    "hintUrl": "https://www.bible.com/bible/59/2Co.4",
    "verseUrl": "https://www.bible.com/bible/59/2Co.4.16",
    "verseText": "So we do not lose heart. Though our outer self is wasting away, our inner self is being renewed day by day.",
    "submissionText": ""
  },
  { // July 19
    "word": "quiet",
    "hint": "1 Timothy 2",
    "verse": "1 Timothy 2:1-2",
    "hintUrl": "https://www.bible.com/bible/59/1TI.2",
    "verseUrl": "https://www.bible.com/bible/59/1TI.2.1-2",
    "verseText": "First of all, then, I urge that supplications, prayers, intercessions, and thanksgivings be made for all people, for kings and all who are in high positions, that we may lead a peaceful and quiet life, godly and dignified in every way.",
    "submissionText": ""
  },
  { // July 20
    "word": "bride",
    "hint": "Revelation 21",
    "verse": "Revelation 21:2",
    "hintUrl": "https://www.bible.com/bible/59/REV.21",
    "verseUrl": "https://www.bible.com/bible/59/REV.21.2",
    "verseText": "And I saw the holy city, new Jerusalem, coming down out of heaven from God, prepared as a bride adorned for her husband.",
    "submissionText": ""
  },
  { // July 21
    "word": "frost",
    "hint": "Exodus 16",
    "verse": "Exodus 16:14",
    "hintUrl": "https://www.bible.com/bible/59/EXO.16",
    "verseUrl": "https://www.bible.com/bible/59/EXO.16.14",
    "verseText": "And when the dew had gone up, there was on the face of the wilderness a fine, flake-like thing, fine as frost on the ground.",
    "submissionText": ""
  },
  { // July 22
    "word": "watch",
    "hint": "Proverbs 15",
    "verse": "Proverbs 15:3",
    "hintUrl": "https://www.bible.com/bible/59/PRO.15",
    "verseUrl": "https://www.bible.com/bible/59/PRO.15.3",
    "verseText": "The eyes of the LORD are in every place, keeping watch on the evil and the good.",
    "submissionText": ""
  },
  { // July 23
    "word": "hours",
    "hint": "Acts 19",
    "verse": "Acts 19:34",
    "hintUrl": "https://www.bible.com/bible/59/ACT.19",
    "verseUrl": "https://www.bible.com/bible/59/ACT.19.34",
    "verseText": "But when they recognized that he was a Jew, for about two hours they all cried out with one voice, “Great is Artemis of the Ephesians!”",
    "submissionText": ""
  },
  { // July 23
    "word": "hours",
    "hint": "Acts 19",
    "verse": "Acts 19:34",
    "hintUrl": "https://www.bible.com/bible/59/ACT.19",
    "verseUrl": "https://www.bible.com/bible/59/ACT.19.34",
    "verseText": "But when they recognized that he was a Jew, for about two hours they all cried out with one voice, “Great is Artemis of the Ephesians!”",
    "submissionText": ""
  },
  { // July 24
    "word": "delay",
    "hint": "Psalm 40",
    "verse": "Psalm 40:17",
    "hintUrl": "https://www.bible.com/bible/59/PSA.40",
    "verseUrl": "https://www.bible.com/bible/59/PSA.40.17",
    "verseText": "As for me, I am poor and needy, but the Lord takes thought for me. You are my help and my deliverer; do not delay, O my God!",
    "submissionText": ""
  },
  { // July
    "word": "chaff",
    "hint": "Luke 3",
    "hintUrl": "https://www.bible.com/bible/59/LUK.3",
    "verse": "Luke 3:17",
    "verseUrl": "https://www.bible.com/bible/59/LUK.3.17",
    "verseText": "His winnowing fork is in his hand, to clear his threshing floor and to gather the wheat into his barn, but the chaff he will burn with unquenchable fire.”",
    "submissionText": ""
  },
  { // July
    "word": "yours",
    "hint": "Luke 22",
    "hintUrl": "https://www.bible.com/bible/59/LUK.22",
    "verse": "Luke 22:42",
    "verseUrl": "https://www.bible.com/bible/59/LUK.22.42",
    "verseText": "saying, “Father, if you are willing, remove this cup from me. Nevertheless, not my will, but yours, be done.”",
    "submissionText": ""
  },
  { // July
    "word": "woman",
    "hint": "Genesis 2",
    "hintUrl": "https://www.bible.com/bible/59/GEN.2",
    "verse": "Genesis 2:23",
    "verseUrl": "https://www.bible.com/bible/59/GEN.2.23",
    "verseText": "Then the man said, “This at last is bone of my bones and flesh of my flesh; she shall be called Woman, because she was taken out of Man.”",
    "submissionText": ""
  },
  { // July
    "word": "black",
    "hint": "Matthew 5",
    "hintUrl": "https://www.bible.com/bible/59/MAT.5",
    "verse": "Matthew 5:36",
    "verseUrl": "https://www.bible.com/bible/59/MAT.5.36",
    "verseText": "And do not take an oath by your head, for you cannot make one hair white or black.",
    "submissionText": ""
  },
  { // July
    "word": "judas",
    "hint": "Luke 22",
    "hintUrl": "https://www.bible.com/bible/59/LUK.22",
    "verse": "Luke 22:48",
    "verseUrl": "https://www.bible.com/bible/59/LUK.22.48",
    "verseText": "but Jesus said to him, “Judas, would you betray the Son of Man with a kiss?”",
    "submissionText": ""
  },
  { // July
    "word": "chest",
    "hint": "Revelation 1",
    "hintUrl": "https://www.bible.com/bible/59/REV.1",
    "verse": "Revelation 1:13",
    "verseUrl": "https://www.bible.com/bible/59/REV.1.13",
    "verseText": "and in the midst of the lampstands one like a son of man, clothed with a long robe and with a golden sash around his chest.",
    "submissionText": ""
  },
  { // July
    "word": "Harsh",
    "hint": "Proverbs 15",
    "hintUrl": "https://www.bible.com/bible/59/PRO.15",
    "verse": "Proverbs 15:1",
    "verseUrl": "https://www.bible.com/bible/59/PRO.15.1",
    "verseText": "A soft answer turns away wrath, but a harsh word stirs up anger.",
    "submissionText": ""
  },
  { // August 1
    "word": "avoid",
    "hint": "Titus 3",
    "hintUrl": "https://www.bible.com/bible/59/TIT.3",
    "verse": "Titus 3:9",
    "verseUrl": "https://www.bible.com/bible/59/TIT.3.9",
    "verseText": "But avoid foolish controversies, genealogies, dissensions, and quarrels about the law, for they are unprofitable and worthless.",
    "submissionText": ""
  },
  { // August
    "word": "bribe",
    "hint": "Deuteronomy 10",
    "hintUrl": "https://www.bible.com/bible/59/DEU.10",
    "verse": "Deuteronomy 10:17",
    "verseUrl": "https://www.bible.com/bible/59/DEU.10.17",
    "verseText": "For the LORD your God is God of gods and Lord of lords, the great, the mighty, and the awesome God, who is not partial and takes no bribe.",
    "submissionText": ""
  },
  { // August
    "word": "empty",
    "hint": "Colossians 2",
    "hintUrl": "https://www.bible.com/bible/59/COL.2",
    "verse": "Colossians 2:8",
    "verseUrl": "https://www.bible.com/bible/59/COL.2.8",
    "verseText": "See to it that no one takes you captive by philosophy and empty deceit, according to human tradition, according to the elemental spirits of the world, and not according to Christ.",
    "submissionText": ""
  },
  { // August
    "word": "weary",
    "hint": "Galatians 6",
    "hintUrl": "https://www.bible.com/bible/59/GAL.6",
    "verse": "Galatians 6:9",
    "verseUrl": "https://www.bible.com/bible/59/GAL.6.9",
    "verseText": "And let us not grow weary of doing good, for in due season we will reap, if we do not give up.",
    "submissionText": ""
  },
  { // August
    "word": "giver",
    "hint": "2 Corinthians 9",
    "hintUrl": "https://www.bible.com/bible/59/2CO.9",
    "verse": "2 Corinthians 9:7",
    "verseUrl": "https://www.bible.com/bible/59/2CO.9.7",
    "verseText": "Each one must give as he has decided in his heart, not reluctantly or under compulsion, for God loves a cheerful giver.",
    "submissionText": ""
  },
  { // August
    "word": "worse",
    "hint": "1 Timothy 5",
    "hintUrl": "https://www.bible.com/bible/59/1TI.5",
    "verse": "1 Timothy 5:8",
    "verseUrl": "https://www.bible.com/bible/59/1TI.5.8",
    "verseText": "But if anyone does not provide for his relatives, and especially for members of his household, he has denied the faith and is worse than an unbeliever.",
    "submissionText": ""
  },
  { // August
    "word": "guide",
    "hint": "Psalm 73",
    "hintUrl": "https://www.bible.com/bible/59/PSA.73",
    "verse": "Psalm 73:24",
    "verseUrl": "https://www.bible.com/bible/59/PSA.73.24",
    "verseText": "You guide me with your counsel, and afterward you will receive me to glory.",
    "submissionText": ""
  },
  { // August
    "word": "excel",
    "hint": "1 Corinthians 14",
    "hintUrl": "https://www.bible.com/bible/59/1CO.14",
    "verse": "1 Corinthians 14:12",
    "verseUrl": "https://www.bible.com/bible/59/1CO.14.12",
    "verseText": "So with yourselves, since you are eager for manifestations of the Spirit, strive to excel in building up the church.",
    "submissionText": ""
  },
  { // August
    "word": "david",
    "hint": "Acts 13",
    "hintUrl": "https://www.bible.com/bible/59/ACT.13",
    "verse": "Acts 13:22",
    "verseUrl": "https://www.bible.com/bible/59/ACT.13.22",
    "verseText": "And when he had removed him, he raised up David to be their king, of whom he testified and said, ‘I have found in David the son of Jesse a man after my heart, who will do all my will.’",
    "submissionText": ""
  },
  { // August 10
    "word": "baker",
    "hint": "Genesis 40",
    "hintUrl": "https://www.bible.com/bible/59/GEN.40",
    "verse": "Genesis 40:5",
    "verseUrl": "https://www.bible.com/bible/59/GEN.40.5",
    "verseText": "And one night they both dreamed—the cupbearer and the baker of the king of Egypt, who were confined in the prison—each his own dream, and each dream with its own interpretation.",
    "submissionText": ""
  },
  { // August
    "word": "godly",
    "hint": "2 Timothy 3",
    "hintUrl": "https://www.bible.com/bible/59/2TI.3",
    "verse": "2 Timothy 3:12",
    "verseUrl": "https://www.bible.com/bible/59/2TI.3.12",
    "verseText": "Indeed, all who desire to live a godly life in Christ Jesus will be persecuted,",
    "submissionText": ""
  },
  { // August
    "word": "broad",
    "hint": "Psalm 119",
    "hintUrl": "https://www.bible.com/bible/59/PSA.119",
    "verse": "Psalm 119:96",
    "verseUrl": "https://www.bible.com/bible/59/PSA.119.96",
    "verseText": "I have seen a limit to all perfection, but your commandment is exceedingly broad.",
    "submissionText": ""
  },
  { // August
    "word": "kneel",
    "hint": "Psalm 95",
    "hintUrl": "https://www.bible.com/bible/59/PSA.95",
    "verse": "Psalm 95:6",
    "verseUrl": "https://www.bible.com/bible/59/PSA.95.6",
    "verseText": "Oh come, let us worship and bow down; let us kneel before the LORD, our Maker!",
    "submissionText": ""
  },
  { // August
    "word": "wheat",
    "hint": "Luke 22",
    "hintUrl": "https://www.bible.com/bible/59/LUK.22",
    "verse": "Luke 22:31-32",
    "verseUrl": "https://www.bible.com/bible/59/LUK.22.31-32",
    "verseText": "“Simon, Simon, behold, Satan demanded to have you, that he might sift you like wheat, but I have prayed for you that your faith may not fail. And when you have turned again, strengthen your brothers.”",
    "submissionText": ""
  },
  { // August
    "word": "early",
    "hint": "Psalm 127",
    "hintUrl": "https://www.bible.com/bible/59/PSA.127",
    "verse": "Psalm 127:2",
    "verseUrl": "https://www.bible.com/bible/59/PSA.127.2",
    "verseText": "It is in vain that you rise up early and go late to rest, eating the bread of anxious toil; for he gives to his beloved sleep.",
    "submissionText": ""
  },
  { // August
    "word": "yield",
    "hint": "Habakkuk 3",
    "hintUrl": "https://www.bible.com/bible/59/HAB.3",
    "verse": "Habakkuk 3:17-18",
    "verseUrl": "https://www.bible.com/bible/59/HAB.3.17-18",
    "verseText": "Though the fig tree should not blossom, nor fruit be on the vines, the produce of the olive fail and the fields yield no food, the flock be cut off from the fold and there be no herd in the stalls, yet I will rejoice in the LORD; I will take joy in the God of my salvation.",
    "submissionText": ""
  },
  { // August
    "word": "books",
    "hint": "Revelation 20",
    "hintUrl": "https://www.bible.com/bible/59/REV.20",
    "verse": "Revelation 20:12",
    "verseUrl": "https://www.bible.com/bible/59/REV.20.12",
    "verseText": "And I saw the dead, great and small, standing before the throne, and books were opened. Then another book was opened, which is the book of life. And the dead were judged by what was written in the books, according to what they had done.",
    "submissionText": ""
  },
  { // August
    "word": "haste",
    "hint": "Luke 2",
    "hintUrl": "https://www.bible.com/bible/59/LUK.2",
    "verse": "Luke 2:16",
    "verseUrl": "https://www.bible.com/bible/59/LUK.2.16",
    "verseText": "And they went with haste and found Mary and Joseph, and the baby lying in a manger.",
    "submissionText": ""
  },
  { // August
    "word": "quake",
    "hint": "Nahum 1",
    "hintUrl": "https://www.bible.com/bible/59/NAM.1",
    "verse": "Nahum 1:5",
    "verseUrl": "https://www.bible.com/bible/59/NAM.1.5",
    "verseText": "The mountains quake before him; the hills melt; the earth heaves before him, the world and all who dwell in it.",
    "submissionText": ""
  },
  { // August 20
    "word": "fully",
    "hint": "1 Peter 1",
    "hintUrl": "https://www.bible.com/bible/59/1PE.1",
    "verse": "1 Peter 1:13",
    "verseUrl": "https://www.bible.com/bible/59/1PE.1.13",
    "verseText": "Therefore, preparing your minds for action, and being sober-minded, set your hope fully on the grace that will be brought to you at the revelation of Jesus Christ.",
    "submissionText": ""
  },
  { // August
    "word": "alarm",
    "hint": "Joel 2",
    "hintUrl": "https://www.bible.com/bible/59/JOL.2",
    "verse": "Joel 2:1",
    "verseUrl": "https://www.bible.com/bible/59/JOL.2.1",
    "verseText": "Blow a trumpet in Zion; sound an alarm on my holy mountain! Let all the inhabitants of the land tremble, for the day of the LORD is coming; it is near,",
    "submissionText": ""
  },
  { // August
    "word": "barns",
    "hint": "Luke 12",
    "hintUrl": "https://www.bible.com/bible/59/LUK.12",
    "verse": "Luke 12:18",
    "verseUrl": "https://www.bible.com/bible/59/LUK.12.18",
    "verseText": "And he said, ‘I will do this: I will tear down my barns and build larger ones, and there I will store all my grain and my goods.",
    "submissionText": ""
  },
  { // August
    "word": "false",
    "hint": "Exodus 20",
    "hintUrl": "https://www.bible.com/bible/59/EXO.20",
    "verse": "Exodus 20:16",
    "verseUrl": "https://www.bible.com/bible/59/EXO.20.16",
    "verseText": "“You shall not bear false witness against your neighbor.",
    "submissionText": ""
  },
  { // August
    "word": "creep",
    "hint": "Psalm 104",
    "hintUrl": "https://www.bible.com/bible/59/PSA.104",
    "verse": "Psalm 104:20",
    "verseUrl": "https://www.bible.com/bible/59/PSA.104.20",
    "verseText": "You make darkness, and it is night, when all the beasts of the forest creep about.",
    "submissionText": ""
  },
  { // August
    "word": "blood",
    "hint": "Ephesians 1",
    "hintUrl": "https://www.bible.com/bible/59/EPH.1",
    "verse": "Ephesians 1:7",
    "verseUrl": "https://www.bible.com/bible/59/EPH.1.7",
    "verseText": "In him we have redemption through his blood, the forgiveness of our trespasses, according to the riches of his grace,",
    "submissionText": ""
  },
  { // August
    "word": "chain",
    "hint": "Revelation 20",
    "hintUrl": "https://www.bible.com/bible/59/REV.20",
    "verse": "Revelation 20:1",
    "verseUrl": "https://www.bible.com/bible/59/REV.20.1",
    "verseText": "Then I saw an angel coming down from heaven, holding in his hand the key to the bottomless pit and a great chain.",
    "submissionText": ""
  },
  { // August
    "word": "hairy",
    "hint": "Genesis 25",
    "hintUrl": "https://www.bible.com/bible/59/GEN.25",
    "verse": "Genesis 25:25",
    "verseUrl": "https://www.bible.com/bible/59/GEN.25.25",
    "verseText": "The first came out red, all his body like a hairy cloak, so they called his name Esau.",
    "submissionText": ""
  },
  { // August
    "word": "goats",
    "hint": "Matthew 25",
    "hintUrl": "https://www.bible.com/bible/59/MAT.25",
    "verse": "Matthew 25:32",
    "verseUrl": "https://www.bible.com/bible/59/MAT.25.32",
    "verseText": "Before him will be gathered all the nations, and he will separate people one from another as a shepherd separates the sheep from the goats.",
    "submissionText": ""
  },
  { // August
    "word": "grant",
    "hint": "Ephesians 3",
    "hintUrl": "https://www.bible.com/bible/59/EPH.3",
    "verse": "Ephesians 3:16",
    "verseUrl": "https://www.bible.com/bible/59/EPH.3.16",
    "verseText": "that according to the riches of his glory he may grant you to be strengthened with power through his Spirit in your inner being,",
    "submissionText": ""
  },
  { // August 30
    "word": "bowed",
    "hint": "Romans 11",
    "hintUrl": "https://www.bible.com/bible/59/ROM.11",
    "verse": "Romans 11:4",
    "verseUrl": "https://www.bible.com/bible/59/ROM.11.4",
    "verseText": "But what is God’s reply to him? “I have kept for myself seven thousand men who have not bowed the knee to Baal.”",
    "submissionText": ""
  },
  { // September 1
    "word": "laden",
    "hint": "Matthew 11",
    "hintUrl": "https://www.bible.com/bible/59/MAT.11",
    "verse": "Matthew 11:28",
    "verseUrl": "https://www.bible.com/bible/59/MAT.11.28",
    "verseText": "Come to me, all who labor and are heavy laden, and I will give you rest.",
    "submissionText": ""
  },
  { // September
    "word": "court",
    "hint": "1 Corinthians 4",
    "hintUrl": "https://www.bible.com/bible/59/1Co.4",
    "verse": "1 Corinthians 4:3",
    "verseUrl": "https://www.bible.com/bible/59/1Co.4.3",
    "verseText": "But with me it is a very small thing that I should be judged by you or by any human court. In fact, I do not even judge myself.",
    "submissionText": ""
  },
  { // September
    "word": "grain",
    "hint": "Genesis 41",
    "hintUrl": "https://www.bible.com/bible/59/GEN.41",
    "verse": "Genesis 41:49",
    "verseUrl": "https://www.bible.com/bible/59/GEN.41.49",
    "verseText": "And Joseph stored up grain in great abundance, like the sand of the sea, until he ceased to measure it, for it could not be measured.",
    "submissionText": ""
  },
  { // September
    "word": "known",
    "hint": "John 15",
    "hintUrl": "https://www.bible.com/bible/59/JHN.15",
    "verse": "John 15:15",
    "verseUrl": "https://www.bible.com/bible/59/JHN.15.15",
    "verseText": "No longer do I call you servants, for the servant does not know what his master is doing; but I have called you friends, for all that I have heard from my Father I have made known to you.",
    "submissionText": ""
  },
  { // September
    "word": "daily",
    "hint": "Luke 9",
    "hintUrl": "https://www.bible.com/bible/59/LUK.9",
    "verse": "Luke 9:23",
    "verseUrl": "https://www.bible.com/bible/59/LUK.9.23",
    "verseText": "And he said to all, “If anyone would come after me, let him deny himself and take up his cross daily and follow me.",
    "submissionText": ""
  },
  { // September
    "word": "elect",
    "hint": "Romans 8",
    "hintUrl": "https://www.bible.com/bible/59/ROM.8",
    "verse": "Romans 8:33",
    "verseUrl": "https://www.bible.com/bible/59/ROM.8.33",
    "verseText": "Who shall bring any charge against God’s elect? It is God who justifies.",
    "submissionText": ""
  },
  { // September
    "word": "boils",
    "hint": "Exodus 9",
    "hintUrl": "https://www.bible.com/bible/59/EXO.9",
    "verse": "Exodus 9:11",
    "verseUrl": "https://www.bible.com/bible/59/EXO.9.11",
    "verseText": "And the magicians could not stand before Moses because of the boils, for the boils came upon the magicians and upon all the Egyptians.",
    "submissionText": ""
  },
  { // September
    "word": "boast",
    "hint": "Ephesians 2",
    "hintUrl": "https://www.bible.com/bible/59/EPH.2",
    "verse": "Ephesians 2:8-9",
    "verseUrl": "https://www.bible.com/bible/59/EPH.2.8-9",
    "verseText": "For by grace you have been saved through faith. And this is not your own doing; it is the gift of God, not a result of works, so that no one may boast.",
    "submissionText": ""
  },
  { // September
    "word": "build",
    "hint": "Psalm 127",
    "hintUrl": "https://www.bible.com/bible/59/PSA.127",
    "verse": "Psalm 127:1",
    "verseUrl": "https://www.bible.com/bible/59/PSA.127.1",
    "verseText": "Unless the LORD builds the house, those who build it labor in vain. Unless the LORD watches over the city, the watchman stays awake in vain.",
    "submissionText": ""
  },
  { // September 10
    "word": "grace",
    "hint": "1 Corinthians 15",
    "hintUrl": "https://www.bible.com/bible/59/1CO.15",
    "verse": "1 Corinthians 15:10",
    "verseUrl": "https://www.bible.com/bible/59/1CO.15.10",
    "verseText": "But by the grace of God I am what I am, and his grace toward me was not in vain. On the contrary, I worked harder than any of them, though it was not I, but the grace of God that is with me.",
    "submissionText": ""
  },
  { // September
    "word": "wrath",
    "hint": "John 3",
    "hintUrl": "https://www.bible.com/bible/59/JHN.3",
    "verse": "John 3:36",
    "verseUrl": "https://www.bible.com/bible/59/JHN.3.36",
    "verseText": "Whoever believes in the Son has eternal life; whoever does not obey the Son shall not see life, but the wrath of God remains on him.",
    "submissionText": ""
  },
  { // September
    "word": "drunk",
    "hint": "Ephesians 5",
    "hintUrl": "https://www.bible.com/bible/59/EPH.5",
    "verse": "Ephesians 5:18",
    "verseUrl": "https://www.bible.com/bible/59/EPH.5.18",
    "verseText": "And do not get drunk with wine, for that is debauchery, but be filled with the Spirit,",
    "submissionText": ""
  },
  { // September
    "word": "ghost",
    "hint": "Matthew 14",
    "hintUrl": "https://www.bible.com/bible/59/MAT.14",
    "verse": "Matthew 14:26",
    "verseUrl": "https://www.bible.com/bible/59/MAT.14.26",
    "verseText": "But when the disciples saw him walking on the sea, they were terrified, and said, “It is a ghost!” and they cried out in fear.",
    "submissionText": ""
  },
  { // September
    "word": "doers",
    "hint": "James 1",
    "hintUrl": "https://www.bible.com/bible/59/JAS.1",
    "verse": "James 1:22",
    "verseUrl": "https://www.bible.com/bible/59/JAS.1.22",
    "verseText": "But be doers of the word, and not hearers only, deceiving yourselves.",
    "submissionText": ""
  },
  { // September
    "word": "worms",
    "hint": "Acts 12",
    "hintUrl": "https://www.bible.com/bible/59/ACT.12",
    "verse": "Acts 12:23",
    "verseUrl": "https://www.bible.com/bible/59/ACT.12.23",
    "verseText": "Immediately an angel of the Lord struck him down, because he did not give God the glory, and he was eaten by worms and breathed his last.",
    "submissionText": ""
  },
  { // September
    "word": "gains",
    "hint": "Matthew 16",
    "hintUrl": "https://www.bible.com/bible/59/MAT.16",
    "verse": "Matthew 16:26",
    "verseUrl": "https://www.bible.com/bible/59/MAT.16.26",
    "verseText": "For what will it profit a man if he gains the whole world and forfeits his soul? Or what shall a man give in return for his soul?",
    "submissionText": ""
  },
  { // September
    "word": "angel",
    "hint": "Luke 2",
    "hintUrl": "https://www.bible.com/bible/59/LUK.2",
    "verse": "Luke 2:10",
    "verseUrl": "https://www.bible.com/bible/59/LUK.2.10",
    "verseText": "And the angel said to them, “Fear not, for behold, I bring you good news of great joy that will be for all the people.",
    "submissionText": ""
  },
  { // September
    "word": "enjoy",
    "hint": "1 Timothy 6",
    "hintUrl": "https://www.bible.com/bible/59/1TI.6",
    "verse": "1 Timothy 6:17",
    "verseUrl": "https://www.bible.com/bible/59/1TI.6.17",
    "verseText": "As for the rich in this present age, charge them not to be haughty, nor to set their hopes on the uncertainty of riches, but on God, who richly provides us with everything to enjoy.",
    "submissionText": ""
  },
  { // September
    "word": "walls",
    "hint": "Hebrews 11",
    "hintUrl": "https://www.bible.com/bible/59/HEB.11",
    "verse": "Hebrews 11:30",
    "verseUrl": "https://www.bible.com/bible/59/HEB.11.30",
    "verseText": "By faith the walls of Jericho fell down after they had been encircled for seven days.",
    "submissionText": ""
  },
  { // September 20
    "word": "loves",
    "hint": "1 John 4",
    "hintUrl": "https://www.bible.com/bible/59/1JN.4",
    "verse": "1 John 4:7",
    "verseUrl": "https://www.bible.com/bible/59/1JN.4.7",
    "verseText": "Beloved, let us love one another, for love is from God, and whoever loves has been born of God and knows God.",
    "submissionText": ""
  },
  { // September
    "word": "ashes",
    "hint": "Jonah 3",
    "hintUrl": "https://www.bible.com/bible/59/JON.3",
    "verse": "Jonah 3:6",
    "verseUrl": "https://www.bible.com/bible/59/JON.3.6",
    "verseText": "The word reached the king of Nineveh, and he arose from his throne, removed his robe, covered himself with sackcloth, and sat in ashes.",
    "submissionText": ""
  },
  { // September 22
    "word": "enoch",
    "hint": "Genesis 5",
    "hintUrl": "https://www.bible.com/bible/59/GEN.5",
    "verse": "Genesis 5:24",
    "verseUrl": "https://www.bible.com/bible/59/GEN.5.24",
    "verseText": "Enoch walked with God, and he was not, for God took him.",
    "submissionText": ""
  },
  { //
    "word": "cease",
    "hint": "Genesis 8",
    "hintUrl": "https://www.bible.com/bible/59/GEN.8",
    "verse": "Genesis 8:22",
    "verseUrl": "https://www.bible.com/bible/59/GEN.8.22",
    "verseText": "While the earth remains, seedtime and harvest, cold and heat, summer and winter, day and night, shall not cease.”",
    "submissionText": ""
  },
  { //
    "word": "dealt",
    "hint": "Psalm 13",
    "hintUrl": "https://www.bible.com/bible/59/PSA.13",
    "verse": "Psalm 13:6",
    "verseUrl": "https://www.bible.com/bible/59/PSA.13.6",
    "verseText": "I will sing to the LORD, because he has dealt bountifully with me.",
    "submissionText": ""
  },
  { //
    "word": "foxes",
    "hint": "Luke 9",
    "hintUrl": "https://www.bible.com/bible/59/LUK.9",
    "verse": "Luke 9:58",
    "verseUrl": "https://www.bible.com/bible/59/LUK.9.58",
    "verseText": "And Jesus said to him, “Foxes have holes, and birds of the air have nests, but the Son of Man has nowhere to lay his head.”",
    "submissionText": ""
  },
  { //
    "word": "wound",
    "hint": "Deuteronomy 32",
    "hintUrl": "https://www.bible.com/bible/59/DEU.32",
    "verse": "Deuteronomy 32:39",
    "verseUrl": "https://www.bible.com/bible/59/DEU.32.39",
    "verseText": "“‘See now that I, even I, am he, and there is no god beside me; I kill and I make alive; I wound and I heal; and there is none that can deliver out of my hand.",
    "submissionText": ""
  },
  { //
    "word": "babel",
    "hint": "Genesis 11",
    "hintUrl": "https://www.bible.com/bible/59/GEN.11",
    "verse": "Genesis 11:9",
    "verseUrl": "https://www.bible.com/bible/59/GEN.11.9",
    "verseText": "Therefore its name was called Babel, because there the LORD confused the language of all the earth. And from there the LORD dispersed them over the face of all the earth.",
    "submissionText": ""
  },
  { //
    "word": "jesse",
    "hint": "Romans 15",
    "hintUrl": "https://www.bible.com/bible/59/ROM.15",
    "verse": "Romans 15:12",
    "verseUrl": "https://www.bible.com/bible/59/ROM.15.12",
    "verseText": "And again Isaiah says, “The root of Jesse will come, even he who arises to rule the Gentiles; in him will the Gentiles hope.”",
    "submissionText": ""
  },
  { //
    "word": "jonah",
    "hint": "Jonah 2",
    "hintUrl": "https://www.bible.com/bible/59/JON.2",
    "verse": "Jonah 2:10",
    "verseUrl": "https://www.bible.com/bible/59/JON.2.10",
    "verseText": "And the LORD spoke to the fish, and it vomited Jonah out upon the dry land.",
    "submissionText": ""
  },
  { //
    "word": "harps",
    "hint": "Revelation 14",
    "hintUrl": "https://www.bible.com/bible/59/REV.14",
    "verse": "Revelation 14:2",
    "verseUrl": "https://www.bible.com/bible/59/REV.14.2",
    "verseText": "And I heard a voice from heaven like the roar of many waters and like the sound of loud thunder. The voice I heard was like the sound of harpists playing on their harps,",
    "submissionText": ""
  },
  { //
    "word": "ivory",
    "hint": "1 Kings 10",
    "hintUrl": "https://www.bible.com/bible/59/1KI.10",
    "verse": "1 Kings 10:18",
    "verseUrl": "https://www.bible.com/bible/59/1KI.10.18",
    "verseText": "The king also made a great ivory throne and overlaid it with the finest gold.",
    "submissionText": ""
  },
  { //
    "word": "guard",
    "hint": "Philippians 4",
    "hintUrl": "https://www.bible.com/bible/59/PHP.4",
    "verse": "Philippians 4:7",
    "verseUrl": "https://www.bible.com/bible/59/PHP.4.7",
    "verseText": "And the peace of God, which surpasses all understanding, will guard your hearts and your minds in Christ Jesus.",
    "submissionText": ""
  },
  { //
    "word": "guest",
    "hint": "Luke 19",
    "hintUrl": "https://www.bible.com/bible/59/LUK.19",
    "verse": "Luke 19:7",
    "verseUrl": "https://www.bible.com/bible/59/LUK.19.7",
    "verseText": "And when they saw it, they all grumbled, “He has gone in to be the guest of a man who is a sinner.”",
    "submissionText": ""
  },
  { //
    "word": "world",
    "hint": "Romans 12",
    "hintUrl": "https://www.bible.com/bible/59/ROM.12",
    "verse": "Romans 12:2",
    "verseUrl": "https://www.bible.com/bible/59/ROM.12.2",
    "verseText": "Do not be conformed to this world, but be transformed by the renewal of your mind, that by testing you may discern what is the will of God, what is good and acceptable and perfect.",
    "submissionText": ""
  },
  { //
    "word": "found",
    "hint": "John 1",
    "hintUrl": "https://www.bible.com/bible/59/JHN.1",
    "verse": "John 1:41",
    "verseUrl": "https://www.bible.com/bible/59/JHN.1.41",
    "verseText": "He first found his own brother Simon and said to him, “We have found the Messiah” (which means Christ).",
    "submissionText": ""
  },
  { //
    "word": "blade",
    "hint": "Judges 3",
    "hintUrl": "https://www.bible.com/bible/59/JUD.3",
    "verse": "Judges 3:22",
    "verseUrl": "https://www.bible.com/bible/59/JUD.3.22",
    "verseText": "And the hilt also went in after the blade, and the fat closed over the blade, for he did not pull the sword out of his belly; and the dung came out.",
    "submissionText": ""
  },
  { //
    "word": "idols",
    "hint": "1 John 5",
    "hintUrl": "https://www.bible.com/bible/59/1JO.5",
    "verse": "1 John 5:21",
    "verseUrl": "https://www.bible.com/bible/59/1JO.5.21",
    "verseText": "Little children, keep yourselves from idols.",
    "submissionText": ""
  },
  { //
    "word": "agree",
    "hint": "Mark 14",
    "hintUrl": "https://www.bible.com/bible/59/MRK.14",
    "verse": "Mark 14:56",
    "verseUrl": "https://www.bible.com/bible/59/MRK.14.56",
    "verseText": "For many bore false witness against him, but their testimony did not agree.",
    "submissionText": ""
  }
];